import ContactBlock from "../../components/others/ContactBlock";
import NavBar from "../../components/common/NavBar";
import { Container, Image } from "react-bootstrap";

export default function KoreanDataSciencePython() {
  return (
    <>
      <Container style={{ height: "110px" }}>
        <NavBar navColor={"black"} />
      </Container>
      <Container className="my-5">
        <Image
          src="/img/datasciencepython.jpeg"
          alt="programming for data science with python"
          style={{ borderRadius:"50px", width: "100%" }}
        />
      </Container>
      {/* contact block */}
      <ContactBlock />
      {/* footer block */}
    </>
  );
}
