import client from "./client";

/* 뉴스 */

export const getNews = ({
  page,
  pageSize,
  searchTitle,
  searchContent,
  searchAuthorName,
  searchAll,
  ordering, // 'createdDate','updatedDate', 'viewsCount', 'recommendsCount'
}) =>
  client.get(`/information/news/`, {
    params: {
      page,
      pageSize,
      searchTitle,
      searchContent,
      searchAuthorName,
      searchAll,
      ordering,
    },
  });

export const getNewsDetail = ({ id }) => client.get(`/information/news/${id}/`);

export const recommendNews = ({ id }) =>
  client.post(`/information/news/${id}/recommand/`);

export const cancelRecommendNews = ({ id }) =>
  client.delete(`/information/news/${id}/recommand/`);

export const notRecommendNews = ({ id }) =>
  client.post(`/information/news/${id}/not-recommand/`);

export const cancelNotRecommendNews = ({ id }) =>
  client.delete(`/information/news/${id}/not-recommand/`);

/* 공지 */

export const getNotices = ({
  page,
  pageSize,
  searchTitle,
  searchContent,
  searchAuthorName,
  searchAll,
  ordering, // 'createdDate','updatedDate', 'viewsCount', 'recommendsCount'
}) =>
  client.get(`/information/notice/`, {
    params: {
      page,
      pageSize,
      searchTitle,
      searchContent,
      searchAuthorName,
      searchAll,
      ordering,
    },
  });

export const getNotice = ({ id }) => client.get(`/information/notice/${id}/`);

export const recommendNotice = ({ id }) =>
  client.post(`/information/notice/${id}/recommand/`);

export const cancelRecommendNotice = ({ id }) =>
  client.delete(`/information/notice/${id}/recommand/`);

export const notRecommendNotice = ({ id }) =>
  client.post(`/information/notice/${id}/not-recommand/`);

export const cancelNotRecommendNotice = ({ id }) =>
  client.delete(`/information/notice/${id}/not-recommand/`);

/* 성공 사례 */

export const getSuccessCases = ({
  page,
  pageSize,
  searchTitle,
  searchContent,
  searchAuthorName,
  searchAll,
  ordering,
}) =>
  client.get(`/information/success-cases/`, {
    params: {
      page,
      pageSize,
      searchTitle,
      searchContent,
      searchAuthorName,
      searchAll,
      ordering,
    },
  });

export const getSuccessCase = ({ id }) =>
  client.get(`/information/success-cases/${id}/`);

export const recommendSuccessCase = ({ id }) =>
  client.post(`/information/success-cases/${id}/recommand/`);

export const cancelRecommendSuccessCase = ({ id }) =>
  client.delete(`/information/success-cases/${id}/recommand/`);

export const notRecommendSuccessCase = ({ id }) =>
  client.post(`/information/success-cases/${id}/not-recommand/`);

export const cancelNotRecommendSuccessCase = ({ id }) =>
  client.delete(`/information/success-cases/${id}/not-recommand/`);

/* 비디오 */

export const getVideos = ({
  page,
  pageSize,
  searchTitle,
  searchAuthorName,
  searchAll,
  ordering,
}) =>
  client.get(`/information/videos/`, {
    params: {
      page,
      pageSize,
      searchTitle,
      searchAuthorName,
      searchAll,
      ordering, // 'createdDate','updatedDate'
    },
  });

export const getVideo = ({ id }) => client.get(`/information/videos/${id}/`);
