import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";

import * as informationAPI from "../../apis/information";
import { StyledNoticeListContainer, StyledTable } from "./Notice.styles";
import { toStringByFormatting } from "../../utils/dateFormatter";
import { FlexRowCenterContainer } from "../common/Container";
import { useInput } from "../../utils/useInput";
import { StyledInformationListUpperContainer } from "./Information.styles";
import { useTranslation } from "react-i18next";

export default function NoticeList() {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const orderings = [
    { label: t("newest"), name: "-createdDate" },
    { label: t("popular"), name: "recommendCount" },
    { label: t("look-up"), name: "-viewsCount" },
    { label: t("update-time"), name: "-updatedDate" },
  ];

  const keywords = [
    { label: t("all"), name: "searchAll" },
    { label: t("title"), name: "searchTitle" },
    { label: t("contents"), name: "searchContent" },
    { label: t("writer"), name: "searchAuthorName" },
  ];

  const navigate = useNavigate();

  const [notices, setNotices] = useState([]);
  const [current, setCurrent] = useState(
    Number(localStorage.getItem("success-case-page")) &&
      Number(localStorage.getItem("success-case-page")) > 0
      ? Number(localStorage.getItem("success-case-page"))
      : 1
  );
  const [total, setTotal] = useState(0);
  const [showSearchSortDropdown, setShowSearchSortDropdown] = useState(false);
  const [searchSort, setSearchSort] = useState(orderings[0]);
  const [showSearchKeywordDropdown, setShowSearchKeywordDropdown] =
    useState(false);
  const [searchKeyword, setSearchKeyword] = useState(keywords[0]);
  const [keyword, setKeyword] = useInput("");

  const onChangePage = (page) => {
    localStorage.setItem("notice-page", page);
    setCurrent(page);
  };

  useEffect(() => {
    setSearchSort(orderings[0]);
    setSearchKeyword(keywords[0]);
  }, [i18n.language]);

  useEffect(() => {
    localStorage.setItem("notice-page", 1);
  }, []);

  useEffect(() => {
    informationAPI
      .getNotices({ page: current, pageSize: 10, ordering: searchSort.name })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setTotal(res.data.page.count);
          setNotices(res.data.data);
        }
      });
  }, []);

  useEffect(() => {
    informationAPI
      .getNotices({ page: current, pageSize: 10, ordering: searchSort.name })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setTotal(res.data.page.count);
          setNotices(res.data.data);
        }
      });
  }, [current, searchSort.name, location.pathname]);

  const onSearch = (e) => {
    e.preventDefault();

    if (!keyword || keyword.trim().length === 0) {
      alert("검색어를 입력해주세요.");
      return;
    }

    const request = { page: current, pageSize: 10, ordering: searchSort.name };
    request[searchKeyword.name] = keyword;
    informationAPI.getNotices(request).then((res) => {
      if (res.data.linusCode === 1) {
        setTotal(res.data.page.count);
        setNotices(res.data.data);
      }
    });
  };

  return (
    <StyledNoticeListContainer>
      <StyledInformationListUpperContainer
        showSearchSortDropdown={showSearchSortDropdown}
        showSearchKeywordDropdown={showSearchKeywordDropdown}
      >
        <div className="bottom-container">
          <div className="total-count">
            {t("all")} <span>{total}</span>
          </div>
          <div className="search-sort-container-mobile">
            <div
              className="search-sort"
              onClick={() => {
                setShowSearchSortDropdown(!showSearchSortDropdown);
              }}
            >
              <p>{searchSort.label}</p>
              <div className="search-sort-button">
                <img src="/img/arrow-down.svg" alt="arrow-down" />
              </div>
            </div>
            <div className="search-sort-dropdown">
              {orderings.map((i) => (
                <p
                  key={i.name}
                  onClick={() => {
                    setSearchSort(i);
                    setShowSearchSortDropdown(false);
                  }}
                >
                  {i.label}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="search-container">
          <div className="search-sort-container">
            <div
              className="search-sort"
              onClick={() => {
                setShowSearchSortDropdown(!showSearchSortDropdown);
              }}
            >
              <p>{searchSort.label}</p>
              <div className="search-sort-button">
                <img src="/img/arrow-down.svg" alt="arrow-down" />
              </div>
            </div>
            <div className="search-sort-dropdown">
              {orderings.map((i) => (
                <p
                  key={i.name}
                  onClick={() => {
                    setSearchSort(i);
                    setShowSearchSortDropdown(false);
                  }}
                >
                  {i.label}
                </p>
              ))}
            </div>
          </div>
          <div className="search-form">
            <div className="search-keyword-type-container">
              <div
                className="search-keyword-type"
                onClick={() => {
                  setShowSearchKeywordDropdown(!showSearchKeywordDropdown);
                }}
              >
                <p>{searchKeyword.label}</p>
                <div className="search-sort-button">
                  <img src="/img/arrow-down.svg" alt="arrow-down" />
                </div>
              </div>
              <div className="search-keyword-type-dropdown">
                {keywords.map((i) => (
                  <p
                    key={i.name}
                    onClick={() => {
                      setSearchKeyword(i);
                      setShowSearchKeywordDropdown(false);
                    }}
                  >
                    {i.label}
                  </p>
                ))}
              </div>
            </div>
            <form className="form" onSubmit={onSearch}>
              <input
                className="search-input"
                type={"text"}
                onChange={setKeyword}
              />
              <button type="submit">
                <img src="/img/search@3x.png" alt="search" />
              </button>
            </form>
          </div>
        </div>
      </StyledInformationListUpperContainer>
      <div>
        <StyledTable>
          <thead>
            <tr>
              <th className="head head-number">No.</th>
              <th className="head head-title">{t("title")}</th>
              <th className="head head-author">{t("writer")}</th>
              <th className="head head-date">{t("date")}</th>
              <th className="head head-recommend">{t("recommend")}</th>
              <th className="head head-view">{t("views")}</th>
            </tr>
          </thead>
          <tbody>
            {notices?.map((i) => (
              <tr
                key={i.id}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/information/notice/${i.id}`);
                }}
              >
                <td className="row row-number">
                  <div>{i.id}</div>
                </td>
                <td className="row row-title">
                  <div to={``}>{i.title}</div>
                </td>
                <td className="row-mobile">
                  <div className="row row-author-mobile">
                    <div>{i.authorName}</div>
                  </div>
                  <div className="row-divider" />
                  <div className="row row-date-mobile">
                    <div>{toStringByFormatting(new Date(i.createdDate))}</div>
                  </div>
                  <div className="row-divider" />
                  <div className="row row-recommend-mobile">
                    <div>
                      추천 <span>{i.recommendsCount}</span>
                    </div>
                  </div>
                  <div className="row-divider" />
                  <div className="row row-view-mobile">
                    <div>
                      조회 <span>{i.viewsCount}</span>
                    </div>
                  </div>
                </td>
                <td className="row row-author">
                  <div>{i.authorName}</div>
                </td>
                <td className="row row-date">
                  <div>{toStringByFormatting(new Date(i.createdDate))}</div>
                </td>
                <td className="row row-recommend">
                  <div>{i.recommendsCount}</div>
                </td>
                <td className="row row-view">
                  <div>{i.viewsCount}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </div>
      <FlexRowCenterContainer style={{ width: "100vw" }}>
        <Pagination
          activePage={current}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={window.innerWidth <= 768 ? 5 : 10}
          onChange={onChangePage}
          firstPageText={<img src="/img/first.svg" alt="first" />}
          lastPageText={<img src="/img/icon-page-last.svg" alt="last" />}
          prevPageText={<img src="/img/prev.svg" alt="prev" />}
          nextPageText={<img src="/img/next.svg" alt="next" />}
          itemClass="pagination-item"
          itemClassFirst="pagination-first"
          itemClassPrev="pagination-prev"
          itemClassNext="pagination-next"
          itemClassLast="pagination-last"
          activeClass="pagination-active"
          activeLinkClass="pagination-active-link"
          linkClass="pagination-link"
          linkClassFirst="pagination-link-first"
          linkClassPrev="pagination-link-prev"
          linkClassNext="pagination-link-next"
          linkClassLast="pagination-link-last"
        />
      </FlexRowCenterContainer>
    </StyledNoticeListContainer>
  );
}
