import ContactBlock from "../components/others/ContactBlock";
import NavBar from "../components/common/NavBar";
import { Badge, Button, Col, Container, Image, Row } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export default function About() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  });

  return (
    <>
      <HeroBlock t={t} />
      {/* mooc block */}
      <Container className="d-flex align-items-center" style={{ height: "900px" }}>
        <Row className="d-flex align-items-center" data-aos="fade-up">
          <Col lg={6} className="d-none d-lg-block text-center">
            <Image src="/img/udacity-mooc.png" style={{ width: "350px" }} />
          </Col>
          <Col lg={6}>
            <div className="display-6 fw-bold" style={{ lineHeight: "0.8" }}>
              <p>UDACITY is</p>
              <p className="mb-5" style={{ fontWeight: "300" }}>
                Trusted market leader
              </p>
            </div>
            <h4 className="fs-4" style={{ color: "#3D6E8D"  }}>
              전 세계적으로 접근 가능한 온라인 교육 플랫폼
            </h4>
            <p className="" style={{ fontSize: "15px" }}>
              UDACITY는 MOOC의 대표적인 사례 중 하나로 실무지향적인 커리큘럼, 유연한 학습환경,
              퀄리티 높은 강의자료, 취업 지원 서비스 등의 특징이 있습니다.
            </p>
            <h4 className="fs-4" style={{ color: "#3D6E8D", lineHeight: "0.8" }}>
              독보적이고 우수한 콘텐츠
            </h4>
            <p className="" style={{ fontSize: "15px" }}>
              MS, Google, IBM, AWS, Intel, BMW 등 세계적인 기술을 보유하고 있는 기업들과 제휴하여
              각 기업의 실무진으로 구성된 전문가들이 직접 강의를 개발하고 진행하는 것은 물론
              수강생들에게 1:1 멘토링을 제공합니다.
            </p>
            <Button
              className="d-none d-md-inline px-4"
              style={{ borderRadius: "120px" }}
              href="/programs"
            >
              UDACITY 과정 살펴보기
            </Button>
          </Col>
          <Col lg={6} className="d-lg-none text-center">
            <Image src="/img/udacity-mooc.png" style={{ width: "350px" }} />
            <Button className="d-md-none mt-3" href="/programs">
              UDACITY 과정 살펴보기
            </Button>
          </Col>
        </Row>
      </Container>
      {/* onschedule block */}
      <Container fluid className="d-flex align-items-center bg-light" style={{ height: "800px" }}>
        <Container>
          <Row className="d-flex align-items-center" data-aos="fade-up">
            <Col>
              <div className="display-6 fw-bold" style={{ lineHeight: "0.8" }}>
                <p>학습자가 원하는 만큼</p>
                <p className="mb-5" style={{ fontWeight: "300" }}>
                  Learn On Your Schedule
                </p>
              </div>
              <p className="" style={{ fontSize: "15px" }}>
                UDACITY의 교육 프로그램은 언제 어디서나 원하는 때에 맞춤 학습이 가능하게 설계되어
                일주일에 약 10시간 정도의 학습수준으로 과정을 수료할 수 있습니다. 또한 학습자 맞춤
                콘텐츠인 UDACITY Nanodegree는 세련된 프레젠테이션과 사용자의 수준에 따른 초급,
                중급, 고급의 단계별 설계,그리고 합리적인 학습 분량까지 골고루 갖춰 학습자들이
                의욕적으로 학습을 진행 할 수 있도록 설계되었습니다.
              </p>
            </Col>
            <Col className="text-center">
              <StyledImage
                src="/img/udacity-onschedule.png"
                className="rounded-circle"
              />
            </Col>
          </Row>
        </Container>
      </Container>
      {/* nanodegree block */}
      <Container className="d-flex align-items-center" style={{ height: "900px" }}>
        <Row className="d-flex align-items-center" data-aos="fade-up">
          <Col lg={6}>
            <Badge bg="primary" className="mb-2 me-2 px-3" style={{ borderRadius: "120px" }}>
              2018 USD 1조 가치 기업
            </Badge>
            <Badge bg="success" className="mb-2 me-2 px-3" style={{ borderRadius: "120px" }}>
              2018 NBC 50대 기술혁신 기업: 8위
            </Badge>
            <Badge
              bg="warning"
              className="mb-2 px-3"
              style={{ borderRadius: "120px" }}
              text="dark"
            >
              2017 LinkedIn 최고 기술 기업: 19위
            </Badge>
            <div className="display-6 fw-bold">
              <p className="mb-0">UDACITY</p>
              <p className="mb-4">Nanodegree Programs</p>
            </div>
            <div className="text-secondary">
              <p className="" style={{ fontSize: "15px" }}>
                Udacity Nanodegree Program은 실제 프로젝트와 사례 연구를 통해 실무 역량을 길러주고
                실제 경험을 쌓도록 디자인 된 IT 교육 프로그램이며, 인공지능・자율주행 등 미래
                기술을 주도하는 Google, Benz 등의 산업 전문가들과 함께 설계된 검증된
                프로그램입니다.
              </p>
              <p className="" style={{ fontSize: "15px" }}>
                프로그램 별 7~8개의 프로젝트를 수행하며 이미 미국, UAE 등 국가와 NASA, Boeing사
                등으로부터 신뢰받는 IT 프로그램이기에 Nanodegree 취득 시 해외 기업 취업에
                유리합니다.
              </p>
            </div>
          </Col>
          <Col lg={6} className="text-center">
            <Image
              fluid
              src="/img/nanodegree.png"
              alt="nanodegree image"
              style={{ maxHeight: "450px" }}
            />
          </Col>
        </Row>
      </Container>
      {/* success case block */}
      <Container fluid className=" mb-5">
        <Container className="text-center" style={{ marginBottom: "120px" }} data-aos="zoom-in-up">
          <div className="display-6 fw-bold">
            <p>글로벌 기업들의 혁신과 성장</p>
            <p>그 뒤에 유다시티가 있습니다.</p>
          </div>
        </Container>
        <Row className="d-flex align-items-center">
          <Col md={6} className="d-none d-md-block text-center" data-aos="flip-left">
            <Image
              src="/img/udacitylogo.svg"
              style={{ objectFit: "cover", width: "50%", height: "100%" }}
            />
          </Col>
          <Col
            md={6}
            className="p-0 d-flex align-items-center shadow"
            style={{
              backgroundImage: `url(/img/box-shell.png)`,
              backgroundSize: "cover",
              borderTopLeftRadius: "120px",
              height: "480px",
            }}
          >
            <Container className="px-5">
              <Container className="text-center">
                <Image
                  src="/img/shell.svg"
                  alt="shell logo"
                  style={{ height: "55px" }}
                  className="mb-3"
                />
                <p className="mb-3">
                  세계적인 정유기업 Shell은 머신러닝과 AI기술을 전사적 차원에서 전략적으로
                  구현하고자 유다시티와 협력하여 직원 145명의 기술을 향상시키는 다년간의 투자를
                  통해 운영을 개선하였습니다.
                </p>
              </Container>
              <Row className="text-center">
                <Col>
                  <p className="fs-2 fw-bold">$2M</p>
                  <p>AI 시스템을 활용하여 2백만 달러의 비용을 절감하였습니다.</p>
                </Col>
                <Col>
                  <p className="fs-2 fw-bold">86%</p>
                  <p>
                    UDACITY를 수강한 Shell 임직원 86%가 본인의 업무로부터 가치를 느끼고
                    업무만족도가 높아졌다고 응답했습니다.
                  </p>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            md={6}
            className="p-0 d-flex align-items-center shadow"
            style={{
              backgroundImage: `url(/img/box-bnp.png)`,
              backgroundSize: "cover",
              height: "480px",
            }}
          >
            <Container className="px-5">
              <Container className="text-center mb-5">
                <Image
                  src="/img/bnp.svg"
                  alt="BNP PARIBAS logo"
                  style={{ height: "50px" }}
                  className="mb-3"
                />
                <p className="mb-3">
                  프랑스 최대 은행인 BNP PARIBAS는 UDACITY와의 파트너십을 통해 비즈니스 분석,
                  데이터 시각화, 데이터과학 프로그래밍 및 파이썬에서 직원의 기술을 향상시켜 데이터
                  분석 과정을 간소화하고 오류의 위험을 낮췄습니다.
                </p>
              </Container>
              <Row className="text-center">
                <Col>
                  <p className="fs-2 fw-bold">$1M</p>
                  <p>
                    직원 기술의 향상으로 100만 달러에 달하는 데이터 처리 비용을 절감할 수
                    있었습니다.
                  </p>
                </Col>
                <Col>
                  <p className="fs-2 fw-bold">$1M</p>
                  <p>효율성 향상에 따라 100만 달러의 추가 비용을 절감하였습니다.</p>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            md={6}
            className="p-0 d-flex align-items-center shadow"
            style={{
              backgroundImage: `url(/img/box-cognizant.png)`,
              borderBottomRightRadius: "120px",
              backgroundSize: "cover",
              height: "480px",
            }}
          >
            <Container className="px-5">
              <Container className="text-center mb-5">
                <Image
                  src="/img/cognizant.svg"
                  alt="Cognizant logo"
                  style={{ height: "40px" }}
                  className="mb-3"
                />
                <p className="mb-3">
                  "UDACITY와 파트너 관계를 맺은 글로벌 IT 서비스 업체 Cognizant는 임직원 기술
                  향상을 통해 주요 고객사인 구글의 요구사항을 충족하는 것은 물론, 다른 우량 고객사
                  유치 및 관련 프로젝트 수행을 통한 수익과 전반적인 생산성을 향상시킬 수
                  있었습니다."
                </p>
              </Container>
              <Row className="text-center">
                <Col>
                  <p className="fs-2 fw-bold">$603K</p>
                  <p>내부 고용을 통해 생산성 향상과 동시에 비용을 절감하였습니다.</p>
                </Col>
                <Col>
                  <p className="fs-2 fw-bold">715%</p>
                  <p>
                    전직원의 숙련도가 향상되어 약 715%의 투자 수익률(ROI) 향상이 기대되고 있습니다.
                  </p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container className="mb-5">
        <Container className="text-center mb-3">
          <h4>Content co-created with</h4>
        </Container>
        <Container fluid>
          <Image fluid src="/img/cocreated.png" style={{ objectFit: "cover" }} />
        </Container>
      </Container>
      {/* contact block */}
      <ContactBlock />
    </>
  );
}

const HeroBlock = ({ t }) => {
  const sizes = {
    mobile: "768px",
  };
  const device = {
    mobile: `(max-width: ${sizes.mobile})`,
  };

  const HeroStyled = styled(Container)`
    position: relative;
    background-image: url("/img/udacity-hero.png");
    background-size: cover;
    height: 800px;
    width: 100%;
    background-position: center;

    @media ${device.mobile} {
      height: 600px;
    }
  `;

  const TextBlockStyled = styled.div`
    padding-top: 17rem;
    padding-left: 11rem;
    color: white;

    @media ${device.mobile} {
      padding-top: 12rem;
      padding-left: 2rem;
    }
  `;

  return (
    <HeroStyled fluid className="p-0">
      <NavBar/>
      <TextBlockStyled>
        <div className="display-5 fw-bold">
          <p>디지털 전환시대</p>
          <p>대표 온라인 교육 플랫폼</p>
          <p className="" style={{ fontWeight: "200" }}>
            UDACITY
          </p>
        </div>
        <p>인공지능, 자율주행차, 데이터과학, 프로그래밍, 디지털마케팅 등</p>
      </TextBlockStyled>
    </HeroStyled>
  );
};


const StyledImage = styled(Image)`
  width: 400px;
  height: 400px;
  border-radius: 50%;

  @media (max-width: 576px) {
    width: 300px;
    height: 300px;
  }
`;
