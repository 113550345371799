import styled from "styled-components";

export const StyledVideoListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  & > div {
    padding: 10px;
    width: 100%;
    min-height: 50px;
  }

  & > .blocks {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding-top: 10px;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
      padding: 20px;
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      align-items: center;
    }
  }

  .video-modal {
    height: 100vh;
    overflow-y: hidden;
    z-index: 1000;
    position: fixed;
    top: 0;
    bottom: -10000;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;

    .video {
      display: flex;
      align-items: flex-start;
      width: 50%;
      min-height: 560px;

      @media (max-width: 768px) {
        width: 100vw;
        flex-direction: column-reverse;
        min-height: auto;
        align-items: center;
        justify-content: center;
      }
    }

    iframe {
      border: none;
      width: 100%;
      min-height: 560px;
      @media (max-width: 768px) {
        width: 100vw;
        height: auto;
        min-height: 300px;
        flex-direction: column;
        position: fixed;
        top: 35%;
      }
    }

    .close {
      cursor: pointer;
      @media (max-width: 768px) {
        position: fixed;
        top: 20px;
        right: 20px;
        width: 32px;
        height: 32px;
      }
    }
  }
`;

export const StyledVideoItem = styled.div`
  cursor: pointer;
  height: 306px;
  position: relative;

  &:hover > .video-info {
    opacity: 0.5;
    transition: 0.25s ease-in;
  }

  & > .thumb-hover {
    height: 216px;
    width: 100%;
    border-radius: 5px;
    opacity: 0;
    transition: 0.25s ease-in;
    z-index: 10;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover > .thumb-hover {
    opacity: 1;
    transition: 0.25s ease-in;
  }

  .divider {
    width: 3px;
    height: 3px;
    background-color: #ddd;
    margin: 0 10px;
  }

  & > .thumbnail {
    width: 100%;
    height: 216px;
    border-radius: 5px;
    object-fit: cover;
  }

  & > .video-info {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0;

    @media (max-width: 768px) {
      width: calc(100vw - 40px);
    }
  }

  & > .video-info > p,
  & > .video-info > .tirivials > p {
    margin: 0;
    padding: 0;
  }

  & > .video-info > .tirivials {
    margin: 10px 0 0;
    display: flex;
    align-items: center;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    text-align: left;
    color: #777;

    @media (max-width: 768px) {
      font-size: 14px;
      margin-top: 5px;
    }
  }

  .title {
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -0.64px;
    text-align: left;
    color: #222;
    height: 52px;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;
