import styled from "styled-components";

export const StyledOrderPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start; 
  justify-content: space-between;
  margin-bottom: 140px;
  width: 100%; /* 전체 너비 사용 */
  background-color: #f6f6f6;
  padding: 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  .left-container,
  .right-container {
    @media (max-width: 768px) {
      width: calc(100vw - 40px);
      flex: 0 0 100%; 
      margin: 10px;
    }
  }

  .left-container {
    flex: 0 0 50%;
    max-width: 50%;
    margin: 10px 70px 20px;
    padding: 60px 0;
    background-color: #f6f6f6;

    @media (max-width: 768px) {
      flex-direction: column;
      flex-basis: 100%;
      flex: 0 0 100%;
      max-width: 100%;
      width: calc(100vw - 40px);
      margin-bottom: 20px;
      padding-bottom: 0;
    } 

    & > .form-container {
      background-color: #fff;
      padding: 2rem;
    }
  }

  .modal {
    height: 100vh;
    overflow-y: hidden;
    z-index: 1000;
    position: fixed;
    top: 0;
    bottom: -10000;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;

    .contents-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: auto;
      max-width: calc(100vw - 80px);
      border-radius: 5px;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;

      @media (max-width: 768px) {
        width: calc(100vw - 160px);
      }

      .title {
        display: flex;
        justify-content: start;
        align-items: center;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: -0.88px;
        text-align: left;
        color: #333;
        width: 100%;

        .close-container {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-top: 20px;
        }

        img {
          width: 30px;
          margin-right: 30px;
          cursor: pointer;
        }
      }

      .contents {
        padding: 0 40px 40px 40px;
        margin: 0 30px;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: -0.64px;
        text-align: left;
        color: #555;
        max-height: 700px;
        overflow: scroll;
        text-align: center;
        word-break: keep-all;

        span {
          font-family: Montserrat;
          font-weight: bold;
          color: #2015ff;
          cursor: pointer;
        }

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }

    .close {
      cursor: pointer;
    }
  }

  .title-container {
    width: 100%;
    margin-bottom: 28px;

    @media (max-width: 768px) {
      width: calc(100vw - 40px);
      margin-top: 40px;
    }

    & > h3 {
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 34px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: -1.36px;
      text-align: left;
      color: #222;

      @media (max-width: 768px) {
        margin-bottom: 40px;
      }
    }
  }

  .orderer-info-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    @media (max-width: 768px) {
    }

    label {
      width: 180px;
      margin: 0px 12px 8px 0;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: 0.5px;
      text-align: left;
      color: #0b0b0b;

      & > span {
        color: #f55e4b;
      }
    }

    input {
      width: 99%;
      height: 48px;
      margin: 0 0 30px 0;
      border-radius: 5px;
      border: solid 1px #808080;
      background-color: #fff;
      padding: 12px 16px;
      outline: 0;

      @media (max-width: 768px) {
        width: calc(100vw - 60px);
      }
    }

    input::placeholder {
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: -0.64px;
      text-align: left;
      color: #aaa;
    }

    & > .orderer-info-title {
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: -0.96px;
      text-align: left;
      color: #222;
      border-bottom: 1px solid #222;
      margin-bottom: 28px;
    }

    .orderer-form {
      padding-bottom: 28px;
      border-bottom: 1px solid #ddd;

      @media (max-width: 768px) {
        margin:0;
        padding:0;
        width: 100%;
      }

      & > .orderer-form-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        input {
          width: 98%;
          height: 48px;
          margin: 0 100px 0 0;
          border-radius: 5px;
          border: solid 1px #808080;
          background-color: #fff;
          padding: 12px 16px;
          outline: 0;

          @media (max-width: 768px) {
            width: 100%;
            margin: 0;
          }
        }
      }

      & > .orderer-form-input {
        display: flex;
        flex-direction: column;

        & > .name {
          display: flex;
          flex-direction: column;
          & > input {
            margin-bottom: 10px;
          }
        }
      }

      & > .email-desc-container {
        & > p {
          padding: 0;
          margin: 10px 0px 0 0;
          font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.64px;
          text-align: left;
          color: #777;
        }
        & > .red-text {
          font-weight: 500;
          color: #f55e4b;
        }
      }
    }
  }

  .additional-info-container {
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      margin-top: 28px;
    }

    .additional-info-title {
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: -0.8px;
      text-align: left;
      color: #222;
      border-bottom: 1px solid #222;
      margin-bottom: 28px;

    }


    .additional-info-form {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #ddd;
      margin-bottom: 15px;


      & > label {
        margin: 0px 0px 14px 0;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: -0.72px;
        text-align: left;
        color: #222;

        @media (max-width: 768px) {
          font-size: 1rem;
        }

        & > span {
          color: #aaa;
        }
      }

      & > textarea {
        width: calc(100% - 40px);
        height: 70px;
        border-radius: 5px;
        border: solid 1px #ddd;
        background-color: #fff;
        padding: 15px 20px;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: -0.64px;
        text-align: left;
        outline: 0;
        margin-bottom: 28px;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      & > textarea::placeholder {
        color: #aaa;
      }
    }
  }

  .right-container {
    flex: 0 0 40%;
    max-width: 40%;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      max-width: 100%;
      margin: 0;
      padding: 0;
    }

    & > .lecture-container {
      margin: 100px 100px 0 0;
      padding: 20px;
      background-color: #fff;

      @media (max-width: 768px) {
        max-width: 100%;
        margin: 0;
        padding: 10px;
      }

      .plan-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .lecture-title,
      .plan-details p,
      .subscription-options label,
      .total-section p,
      .total-section div {
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        text-align: left;
        color: #222;
      }
      & > .lecture-title {
        margin: 0 0px 14px 0;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: -0.96px;
        text-align: left;
        color: #222;
      }

      .plan-details p {
        font-size: 18px;
      }

      .subscription-options label {
        display: block;
        margin-top: 10px;
      }

      .radio-option {
        label {
          span {
            color: #198754;
          }
        }
      }

      .radio-option input[type="radio"] {
        margin-right: 10px;
      }

      .radio-option span {
        font-weight: bold;
        color: #5e97vn; /* Adjust the color to match the "Save 15%" style */
      }

      .radio-option small {
        display: block;
        font-size: 14px;
        margin-top: 5px;
      }

      .discount-code-section {
        margin-top: 20px;
        border-bottom: 1px solid #222;
        padding-bottom: 20px;
      }

      .total-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
      }

      .total-section p,
      .total-section div {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .udacity-business-container {
      margin: 20px 100px 0 0;
      padding: 20px;
      background-color: #f0f5ff;
      border: 1px solid #2015FF;
      border-radius: 5px;
      padding: 40px;

      @media (max-width: 768px) {
        width: calc(100vw - 40px);
        padding-bottom: 10px;
      } 

      .title {
        font-size: 1.25rem;
        font-weight: 500;
      }

      .plan-include {
        list-style-type: none;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        padding: 0;
        font-size: 1rem;
      }

      .plan-include li {
        margin-top: 15px;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .button-container {
    width: 40vw;

    @media (max-width: 768px) {
      width: 100%;
    }

    & > button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 40%;
      max-width: 100%;
      height: 48px;
      padding: 10px 45px;
      border-radius: 5px;
      background-color: #2015ff;
      border: none;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 22px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: 1px;
      text-align: center;
      color: #fff;
      cursor: pointer;

      @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
      }

    }
  }
`;
