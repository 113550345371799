import styled from "styled-components";
import { Container } from "react-bootstrap";

export const StyledLectureDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 140px;

  hr {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    border: none;
    margin-top: 80px;

    @media (max-width: 768px) {
      margin-top: 40px;
    }
  }

  .summary-container {
    display: flex;
    width: 100%;
    max-width: 1200px;

    @media (max-width: 768px) {
      flex-direction: column;
      margin: 0;
      width: calc(100vw - 40px);
    }

    & > .thumbnail > img {
      width: 500px;
      height: 500px;
      margin: 0 60px 28px 0;
      border-radius: 5px;
      object-fit: cover;

      @media (max-width: 768px) {
        width: calc(100vw - 40px);
        height: auto;
        margin: 0px;
      }
    }

    & > .summary-text-container {
      display: flex;
      flex-direction: column;

      & > .title {
        margin: 0 0 28px 0px;
        font-family: Montserrat;
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: -1.2px;
        text-align: left;
        color: #142580;

        @media (max-width: 768px) {
          width: calc(100vw - 40px);
          margin-bottom: 20px;
        }
      }

      & > .info {
        display: flex;
        flex-direction: column;

        & > .info-item {
          margin-bottom: 14px;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-stretch: normal;

          line-height: 1.63;
          letter-spacing: -0.64px;
          text-align: left;

          @media (max-width: 768px) {
            margin-bottom: 10px;
          }
        }

        & > .info-item.us {
          font-weight: 500;
          color: #777;
        }

        & > .info-item.purum {
          font-weight: 600;
          color: #2015ff;
        }

        & > .info-item.period {
          color: #777;
        }

        & > .info-item.difficulty {
          font-weight: 600;
          color: #333;
        }

        & > .info-item > span {
          width: 88px;
          margin-right: 20px;
          font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: -0.64px;
          text-align: left;
          color: #aaa;
        }
      }
    }

    .desc {
      margin-top: 28px;
      font-family: "Noto Sans KR", "Montserrat", Sans-serif !important;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: -0.64px;
      text-align: left;
      color: #777;

      & > pre {
        font-family: "Noto Sans KR", "Montserrat", Sans-serif !important;
      }

      @media (max-width: 768px) {
        height: auto;
        margin-top: 20px;
        font-size: 14px;
      }
    }

    .buttons {
      display: flex;

      @media (max-width: 768px) {
        flex-direction: column;
        margin: 0;
      }

      & > .cart {
        display: flex;
        align-items: center;
        width: 306px;
        height: 60px;
        margin: 28px 28px 0px 0px;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(32, 21, 255, 0.05);
        border: solid 1px #2015ff;
        background-color: #fff;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: -0.72px;
        text-align: left;
        color: #2015ff;
        cursor: pointer;

        @media (max-width: 768px) {
          width: calc(100vw - 40px);
        }

        & > div {
          flex: 1;
          margin: 5px 126px 5px 40px;
        }
      }

      & > .order,
      & > .download {
        display: flex;
        align-items: center;
        width: 306px;
        height: 60px;
        margin: 28px 28px 0px 0px;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(32, 21, 255, 0.05);
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: -0.72px;
        text-align: left;
        cursor: pointer;

        @media (max-width: 768px) {
          width: 100%;
          margin-top: 10px;
        }

        & > div {
          flex: 1;
          margin: 5px 100px 5px 40px;
        }
      }

      & > .order {
        border: solid 1px #bdea09;
        background-color: #bdea09;
        color: #0b0b0b;
      }

      & > .download {
        border: solid 3px #02c5a1;
        background-color: #172459;
        color: #02c5a1;

        & > div {
          margin: 5px 80px 5px 40px;
        }
      }
    }
  }

  .desc-container {
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: -0.72px;
    text-align: left;
    color: #555;
    width: 100%;
    max-width: 1200px;
    margin: 0px 0px 20px 20px;

    pre {
      white-space: pre-wrap; /* Since CSS 2.1 */
      white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
      white-space: -pre-wrap; /* Opera 4-6 */
      white-space: -o-pre-wrap; /* Opera 7 */
      word-wrap: break-word; /* Internet Explorer 5.5+ */
    }

    @media (max-width: 768px) {
      flex-direction: column;
      margin: 40px 20px;
      font-size: 14px;
      width: calc(100vw - 40px);
    }
  }

  .price-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 1200px;
    background-image: url(/img/price_block_bg.png);
    background-color: #171a53;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 768px) {
      flex-direction: column;
      height: auto;
      padding: 30px 0 50px;
    }

    & > .title {
      margin: 80px 0px 40px;
      font-family: Montserrat;
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: -1.36px;
      text-align: center;
      color: #fff;

      @media (max-width: 768px) {
        width: calc(100vw - 40px);
        font-size: 28px;
        margin-bottom: 55px;
      }

      & > .lecture-name {
        color: #bdea09;
      }
    }

    .contents {
      display: flex;
      align-items: flex-center;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      height: max-content;
      max-width: 1200px;
      padding: 0 100px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      & > .us-container,
      & > .price-contents {
        display: flex;
        flex-direction: column;
        width: 452px;
        height: 720px;
        margin: 0 0 0 0;
        border-radius: 5px;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        position: relative;
        padding: 20px;

        @media (max-width: 768px) {
          flex-direction: column;
          width: 100%;
          margin-top: 10px;
          height: auto;
          padding: 0;
          padding-bottom: 20px;
          width: calc(100vw - 40px);
        }

        .title {
          margin: 40px 0px 28px 0px;
          font-family: Montserrat;
          font-size: 1.75rem;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.1875rem;
          letter-spacing: -1.2px;
          text-align: left;
          color: #0b0b0b;

          @media (max-width: 768px) {
            margin: 20px;
            font-size: 24px;
            line-height: 2.08;
            letter-spacing: -0.96px;
          }

          & > .desc {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            & > .discount {
              padding: 0 5px;
              font-weight: 500;
              font-size: 1rem;
              letter-spacing: 0.5px;
              border-radius: 5px;
              cursor: pointer;
              background-color: #bdea09;
              border: 1px solid #808080;
              line-height: 1.7;
              display: inline-block;
            }
          }

          & > .sub {
            font-size: 1rem;
          }
        }

        .price {
          margin: 0px 0px 10px 0px;
          font-family: Montserrat;
          font-size: 3rem;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: 3.25rem;
          letter-spacing: -1.92px;
          text-align: left;
          color: #2015ff;
          display: flex;
          justify-content: center;
          flex-direction: column;

          @media (max-width: 768px) {
            margin: 0px 0px 30px 20px;
            font-size: 34px;
            line-height: 0.76;
            letter-spacing: -1.36px;
          }
        }

        .button-container {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          button {
            width: 390px;
            height: 52px;
            margin: 20px;
            border: 2px solid;
            border-radius: 4px;
            cursor: pointer;
            background-color: #2015ff;
            color: #ffffff;
            font-size: 1rem;
            font-weight: 400;
            font-style: normal;
            line-height: 1.08;
            letter-spacing: 0.5px;
            font-family: Montserrat;
            &:hover {
              background-color: rgb(23, 26, 83);
              transition: all 0.2s ease 0s;
            }
          }
        }

        hr {
          margin: 10px 0;
          opacity: 0.6;
          border-image: initial;
          border-style: solid;
          width: 100%;
          border-color: #808080;
          border-width: 0.5px;
        }

        .benefits-list {
          list-style-type: none; /* 기본 리스트 스타일 제거 */
          font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
          padding: 10px;

          @media (max-width: 768px) {
            padding-bottom: 70px;
          }
        }

        .benefits-list li {
          margin-top: 15px;
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .note {
          @media (max-width: 768px) {
            margin-bottom: 70px;
            padding: 10px;
          }
        }
      }
    }

    .contents-bottom {
      display: flex;
      align-items: flex-center;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      max-width: 1200px;
      padding: 0 100px;
      margin-bottom: 40px;
      padding-top; 0;

      @media (max-width:768px) {
        width: calc(100vw - 40px);
        padding:0;
        margin-top: 10px;
      }

      & > .business-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100px;
        border-radius: 5px;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        position: relative;
        margin-top: 30px;

        @media (max-width: 768px) {
          flex-direction: column;
          height: 180px;
          padding: 5px;
        }

        & > .business-container-sub {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            margin-left: 20px;
          }

          & > .desc {
            padding-left: 20px;
            letter-spacing: -0.64px;
          }
        }

        & > .learn-more-button {
          color: #2015ff;
          padding: 8px 20px;
          border: 2px solid #2015ff;
          border-radius: 5px;
          box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
          text-decoration: none;
          display: inline-flex;
          align-items: center;
          gap: 8px;
          margin-right: 20px;
          &:hover {
          color: white;
          border-color: rgb(23, 26, 83);
          background-color: rgb(23, 26, 83);
          transition: all 0.1s ease 0s;
        }
        }
      }
    }
  }

  .related-container {
    width: 100%;
    max-width: 1200px;


    & > .title {
      margin: 80px 0px 80px;
      font-family: Montserrat;
      font-size: 34px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: -1.36px;
      text-align: center;
      color: #222;

      @media (max-width: 768px) {
        margin: 60px 0px 40px;
        
      }
    }

    & > .contents {
      width: 100%;
      max-width: 1200px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 0;
      }

    }
  }
`;

export const StyledRelatedProgram = styled.div`
  width: 570px;
  height: auto;
  margin: 0 30px 30px 0;
  padding: 0;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  @media (max-width: 768px) {
    width: calc(100vw - 40px);
    margin-bottom: 30px;
    margin: 20px;
  }

  .title {
    margin: 40px 0px 14px;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: -0.96px;
    text-align: center;
    color: #142580;

    @media (max-width: 768px) {
      margin: 40px 30px 14px;
    }
  }

  .desc {
    margin-bottom: 14px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.72px;
    text-align: center;
    color: #555;
  }

  .summary {
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -0.64px;
    text-align: center;
    color: #777;
    margin: 0 20px 40px;
  }

  .difficulty-container {
    display: flex;
    align-items: flex-end;
    flex: 1;
    width: 100%;

    .difficulty {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 39px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: #2015ff;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: -0.64px;
      text-align: center;
      color: #fff;
    }
  }
`;

export const LectureDescContainer = styled(Container)`
  padding: 30px;
  margin-top: 40px;
  background-color: #f0f2f5;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #33475b;
`;

export const LectureParagraph = styled.div`
  font-size: 18px;
  line-height: 1.6;
  color: inherit;
  margin-bottom: 20px;
`;

export const LectureList = styled.ul`
  list-style: inside disc;
  padding-left: 1rem;
  margin-top: 15px;
  margin-bottom: 25px;
  color: #33475b;

  li {
    margin-bottom: 8px;
    font-size: 1.2rem;
    @media (max-width: 768px) {
      font-size: 15px;
    }
  }

  img {
    border-radius: 8px;
    margin-left: 20px;
    margin-bottom: 20px;
    height: 300px;
  }
`;

export const LectureImportantNote = styled.div`
  background-color: #f9fcff;
  border-left: 5px solid #2015ff;
  padding: 20px;
  margin-top: 30px;
  font-style: normal;
  border-radius: 8px;
  color: #0c0f45;
  font-size: 16px;
`;
