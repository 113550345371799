import ContactBlock from "../components/others/ContactBlock";
import NavBar from "../components/common/NavBar";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { discountCourses } from "../data/pageConext";

import "swiper/css";
import "swiper/css/scrollbar";

import { Autoplay, FreeMode, Scrollbar } from "swiper";

import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";
import Footer from "../components/common/Footer";

export default function About() {
  const [questionHover, setQuestionHover] = useState({});

  function handleMouseOver(e, inout) {
    if (!e.currentTarget.id) return;
    setQuestionHover({ [e.currentTarget.id]: inout });
  }

  useEffect(() => {
    AOS.init();
  });

  return (
    <>
      {/* hero block */}
      <HeroBlock />
      {/* discount block */}
      <Container style={{ marginTop: "100px", marginBottom: "100px" }}>
        <Container className="text-center" style={{ marginBottom: "50px" }}>
          <div className="display-6 fw-bold mb-4" style={{ lineHeight: "1" }}>
            <p>UDACITY 한국 현지화 기념</p>
            <p className="d-inline d-md-block fs-3" style={{ color: "#3D6E8D" }}>
              오직 푸름인재개발원에서만 제공되는{" "}
            </p>
            <p className="d-inline d-md-block fs-3" style={{ color: "#3D6E8D" }}>
              특별 할인을 받으세요!
            </p>
          </div>
          <p className="mb-1" style={{ fontSize: "15px" }}>
            한국 현지화 과정 이외 모든 과정에 대한 추가 할인도 제공하며
          </p>
          <p className="" style={{ fontSize: "15px" }}>
            기업 및 단체에 대한 추가 할인은 별도 문의 바랍니다.
          </p>
        </Container>
      </Container>
      {/* how block */}
      <Container fluid className="p-0" style={{ backgroundColor: "#E1EEEE", height: "475px" }}>
        <Container>
          <Container>
            <div className=" display-6 fw-bold text-center">
              <div className="" style={{ position: "relative", top: "150px", color: "#BF730A" }}>
                <p className="d-inline d-md-block mb-2 ">기술의 성장을 위해서는</p>
                <p className="d-inline d-md-block mb-2 ">
                  디지털 전환시대에 맞는 교육이 필요합니다.
                </p>
                <div
                  className="d-flex align-items-center"
                  style={{ position: "relative", top: "100px" }}
                >
                  <Image
                    fluid
                    src="/img/howbg.png"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      position: "relative",
                      zIndex: 1,
                    }}
                  />

                  <div
                    style={{
                      color: "white",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                      fontWeight: "300",
                      zIndex: 10,
                    }}
                  >
                    <div className="display-5 text-center" data-aos="flip-left">
                      HOW?
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Container>
      </Container>
      <Container fluid className="p-0" style={{}}>
        <Container>
          <Container>
            <Container></Container>
          </Container>
          <Container style={{ marginTop: "10rem", marginBottom: "150px" }}>
            <div className="display-6 fw-bold text-center">
              <p className="" style={{ color: "#BF730A" }}>
                푸름인재개발원은
              </p>
              <p className="d-inline d-xl-block" style={{ fontSize: "25px", fontWeight: "300" }}>
                세계 최고의 IT교육인 UDACITY 프로그램의 한국화를 진행하여{" "}
              </p>
              <p className="d-inline d-xl-block" style={{ fontSize: "25px", fontWeight: "300" }}>
                누구나 쉽게 접근할 수 있도록 프로젝트를 진행하고 있습니다.
              </p>
            </div>
            <Container className="text-center" style={{ marginTop: "100px" }}>
              <Image src="/img/downarrow.png" data-aos="fade-down" style={{ width: "40px" }} />
            </Container>
          </Container>
          <Container style={{ marginBottom: "200px" }}>
            <Row className="justify-content-center">
              <Col lg={5} className="d-flex justify-content-center ">
                <Card
                  className="shadow"
                  style={{ width: "450px" }}
                  id="questioncard1"
                  onMouseOver={(e) => handleMouseOver(e, true)}
                  onMouseOut={(e) => handleMouseOver(e, false)}
                >
                  {questionHover.questioncard1 ? (
                    <>
                      <Card.Img src="/img/qna1.png" alt="qna image" style={{ opacity: "30%" }} />
                      <Card.ImgOverlay className="d-flex align-items-center">
                        <Card.Body>
                          <h3 className="fw-bold">미국 UDACITY와</h3>
                          <h3 className="fw-bold">무엇이 다른가요?</h3>
                          <p className="mb-4">
                            UDACITY는 우리나라에서도 수강이 가능하나, 한국 현지화 과정(한국어)과
                            특별 할인 과정은 공식 파트너인 푸름인재개발원을 통해서만 수강
                            가능합니다.
                          </p>
                        </Card.Body>
                      </Card.ImgOverlay>
                    </>
                  ) : (
                    <>
                      <Card.Img src="/img/qna1.png" alt="qna image" />
                      <Card.ImgOverlay className="d-flex align-items-center">
                        <Card.Body className="text-white">
                          <h2 className="fw-bold">미국 UDACITY와</h2>
                          <h2 className="fw-bold mb-4">무엇이 다른가요?</h2>
                        </Card.Body>
                      </Card.ImgOverlay>
                    </>
                  )}
                </Card>
              </Col>
              <Col lg={5} className="d-flex justify-content-center">
                <Card
                  className="shadow"
                  style={{ width: "450px" }}
                  id="questioncard2"
                  onMouseOver={(e) => handleMouseOver(e, true)}
                  onMouseOut={(e) => handleMouseOver(e, false)}
                >
                  {questionHover.questioncard2 ? (
                    <>
                      <Card.Img src="/img/qna2.png" alt="qna image" style={{ opacity: "30%" }} />
                      <Card.ImgOverlay className="d-flex align-items-center">
                        <Card.Body>
                          <h3 className="fw-bold">그러면 별도의 과정으로</h3>
                          <h3 className="fw-bold">운영이 되나요?</h3>
                          <p className="mb-4">
                            푸름인재개발원에서 제공하는 과정은 UDACITY 본사에서 직접 관리되는 100%
                            동일한 Nanodegree 취득이 가능합니다.
                          </p>
                        </Card.Body>
                      </Card.ImgOverlay>
                    </>
                  ) : (
                    <>
                      <Card.Img src="/img/qna2.png" alt="qna image" />
                      <Card.ImgOverlay className="d-flex align-items-center">
                        <Card.Body className="text-white ">
                          <h2 className="fw-bold">그러면 별도의</h2>
                          <h2 className="fw-bold">과정으로</h2>
                          <h2 className="fw-bold mb-4">운영이 되나요?</h2>
                        </Card.Body>
                      </Card.ImgOverlay>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>

      {/*
      <Carousel>
        {discountCourses
          .filter((discountCourse) => discountCourse.hasOwnProperty("originprice"))
          .map((discountCourse) => (
            <Carousel.Item key={discountCourse.title}>
              <Image
                className="d-block"
                style={{
                  objectFit: "cover",
                  height: "450px",
                  width: "100%",
                  opacity: "",
                }}
                src={discountCourse.img}
                alt="discount cousrse slide"
              />
              <Carousel.Caption style={{ marginBottom: "50px" }}>
                <Container className="text-white text-center">
                  <p className="fs-2 fw-bold">{discountCourse.title}</p>
                  <strike className="display-6 fw-bold text-warning">
                    {discountCourse.originprice}
                  </strike>
                  <p className="display-5 fw-bold">{discountCourse.discountprice}</p>
                  <a href={discountCourse.detail}>
                    <Button>자세히 보기</Button>
                  </a>
                </Container>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
      </Carousel>
              */}
      {/* gpt block */}
      <Container
        className="d-flex align-items-center d-none d-lg-block"
        style={{ marginBottom: "200px", marginTop: "120px" }}
      >
        <Container style={{ padding: "40px 0 50px 0" }}>
          <p className="display-6 fw-bold text-center">CHAT GPT</p>
          <p
            className="text-muted text-center"
            style={{ fontSize: "15px", marginBottom: "100px" }}
          >
            Chat Gpt를 활용한 프로젝트 수업
          </p>

          <Row className="d-flex align-items-center">
            <Col lg={6} className="d-none d-lg-block shadow">
              <Image fluid src="/img/koreangpt.jpg" />
            </Col>
            <Col lg={6}>
              <Container
                style={{
                  marginLeft: "30px",
                  color: "#1D15FF",
                }}
              >
                <div
                  className="display-6 fw-bold "
                  style={{ color: "#BF730A", lineHeight: "0.9" }}
                >
                  <p data-aos="fade-left" data-aos-delay="300">
                    Chat GPT
                  </p>
                  <p data-aos="fade-left" data-aos-delay="400">
                    IN
                  </p>
                  <p data-aos="fade-left" data-aos-delay="500">
                    UDACITY
                  </p>
                </div>
              </Container>
              <Container>
                <h5
                  className=""
                  style={{ marginLeft: "30px" }}
                  data-aos="fade-left"
                  data-aos-delay="600"
                >
                  프로그램 진행 도중 간단한 질문은 GPT에게 물어보세요!
                </h5>
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* program slide block */}
      <Container fluid className="bg-dark py-5 overflow-hidden">
        <Container>
          <Row>
            <Col lg={6} className="text-white">
              <p className="display-5 fw-bold">NANODEGREE</p>
              <p className="display-6 " style={{ fontWeight: "200" }}>
                KOREAN PROGRAMS
              </p>
              <p className="mb-1" style={{ fontSize: "15px" }}>
                푸름인재개발원을 통해
              </p>
              <p className="" style={{ fontSize: "15px" }}>
                한국만의 Nanodegree를 취득해보세요.
              </p>
            </Col>
            <Col lg={6}>
              <Swiper
                scrollbar={{}}
                slidesPerView={4}
                spaceBetween={10}
                freeMode={true}
                modules={[Autoplay, Scrollbar, FreeMode]}
                className="mySwiper"
                loop={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                cssMode={true}
                mousewheel={true}
                style={{ width: "1280px" }}
              >
                {discountCourses.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <MyCard>
                      <Card.Img
                        variant="top"
                        src={slide.img}
                        style={{
                          objectFit: "cover",
                          height: "200px",
                        }}
                      />
                      <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted">
                          {slide.difficulty}
                        </Card.Subtitle>
                        <Card.Title style={{ height: "50px" }}>{slide.title}</Card.Title>
                        <Card.Text className="mb-2" style={{ height: "100px" }}>
                          {slide.content}
                        </Card.Text>
                        <a href={slide.link} target="_blank" rel="noreferrer">
                          강의 계획서 다운로드
                        </a>
                      </Card.Body>
                    </MyCard>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* contact block */}
      <ContactBlock />
      {/* footer block */}
      <Footer />
    </>
  );
}

const MyCard = styled(Card)`
  width: 300px;
  border: none;
`;

export function HeroBlock() {
  const HeroContainer = styled(Container)`
    position: relative;
    padding: 0;
    margin: 0;

    video {
      object-fit: cover;
      height: 800px;
      width: 100%;

      @media (max-width: 576px) {
        height: auto;
      }
    }

    @media (max-width: 576px) {
      background: black;
      padding-top: 6rem;
      padding-bottom: 4rem;
    }
  `;

  return (
    <HeroContainer fluid>
      <NavBar />
      <video muted autoPlay playsInline>
        <source src="/videos/koreanvideo1.mp4" type="video/mp4" />
      </video>
      <Container
        fluid
        className="position-absolute start-50 translate-middle mt-4 pt-5"
        style={{ top: -48 }}
      ></Container>
      <Container className="position-absolute top-50 start-50 translate-middle"></Container>
    </HeroContainer>
  );
}
