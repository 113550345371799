import styled from "styled-components";

export const StyledShoppingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0 140px;

  @media (max-width: 768px) {
    padding-top: 40px;
  }

  .cart-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 200px 0 0 0px;
    font-family: NotoSansKR;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: -0.8px;
    text-align: center;
    color: #777;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 1;
      letter-spacing: -0.64px;
    }
  }

  .container {
    width: 100%;
    max-width: 1200px;

    @media (max-width: 768px) {
      width: calc(100vw - 40px);
    }

    & > .title-container {
      & > hr {
        display: none;

        @media (max-width: 768px) {
          display: block;
          margin-bottom: 20px;
        }
      }
    }

    h3 {
      margin: 0 0px 80px 0;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 34px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: -1.36px;
      text-align: left;
      color: #222;

      @media (max-width: 768px) {
        margin-bottom: 14px;
        font-size: 28px;
      }
    }

    .upper-container {
      display: flex;
      margin-bottom: 28px;

      .check-container {
        display: flex;
        align-items: center;
        cursor: pointer;

        & > img {
          cursor: pointer;

          @media (max-width: 768px) {
            width: 26px;
            height: 26px;
          }
        }

        & > .select-all {
          font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
          font-size: 26px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: -1.04px;
          text-align: left;
          color: #222;
          margin-left: 14px;
          cursor: pointer;

          @media (max-width: 768px) {
            font-size: 18px;
          }
        }

        & > .count {
          margin: 0px 0 0px 14px;
          font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.3;
          letter-spacing: -0.8px;
          text-align: left;
          color: #aaa;
          cursor: pointer;

          & > span {
            color: #222;
          }

          @media (max-width: 768px) {
            font-size: 16px;
          }
        }
      }

      .delete-container {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        & > button {
          width: 148px;
          height: 54px;
          border-radius: 5px;
          border: solid 1px #ddd;
          background-color: #fff;
          font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.44;
          letter-spacing: -0.72px;
          text-align: center;
          color: #555;
          cursor: pointer;

          @media (max-width: 768px) {
            border: none;
            padding: 0;
            width: auto;
            color: #aaa;
          }
        }
      }
    }

    .contents-container {
      .total-price {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 28px 0;
        padding: 0px;
        border-radius: 5px;
        width: 100%;
        height: 85px;
        background-color: #f4f4f4;

        @media (max-width: 768px) {
          margin-top: 20px;
          margin-bottom: 0px;
        }

        & > div {
          margin-right: 28px;
          font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.3;
          letter-spacing: -0.8px;
          text-align: right;
          color: #555;

          & > span {
            font-family: Montserrat;
            font-weight: bold;
            color: #222;
            margin-left: 5px;
          }
        }
      }
    }

    .order-container {
      margin-top: 80px;

      @media (max-width: 768px) {
        margin-top: 40px;
      }

      & > button {
        width: 100%;
        height: 84px;
        margin: 0;
        padding: 25px 557px 24px;
        border-radius: 5px;
        background-color: #2015ff;
        border: none;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: -0.96px;
        text-align: center;
        color: #fff;
        cursor: pointer;

        @media (max-width: 768px) {
          width: calc(100vw - 40px);
          padding: 0;
        }
      }
    }
  }
`;

export const StyledCartItemContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  padding: 20px 0;
  width: 100%;
  margin: 0 0 28px 0px;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;

  .quantity-container-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 80px;
  }

  .quantity-container {
    display: flex;
    width: 84px;
    align-items: center;
    border: solid 1px #ddd;
    border-radius: 2px;
    margin: 0 28px;
    display: none;

    @media (max-width: 768px) {
      display: flex;
      flex: 1;
      background-color: pink;
      width: 84px;
      margin: 0;
      margin-right: 20px;
      padding: 0;
    }

    & > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px;
      border: none;
      cursor: pointer;
      background-color: white;

      @media (max-width: 768px) {
        width: 22px;
        height: 22px;
      }
    }

    & > .quantity {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.64px;
      color: #222;
      background-color: #f8f8f8;
      height: 38px;
      border-left: solid 1px #ddd;
      border-right: solid 1px #ddd;
      cursor: default;

      @media (max-width: 768px) {
        width: 40px;
        height: 22px;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: calc(100vw - 40px);
    height: auto;
  }

  & > .upper {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      width: 100%;
      margin: 40px 20px 0 20px;
    }

    & > .checkbox-container {
      margin-left: 28px;

      @media (max-width: 768px) {
        margin: 0;
        margin-left: 20px;
      }

      .checkbox {
        cursor: pointer;
      }
    }

    & > .img-container {
      cursor: pointer;

      .thumbnail {
        width: 135px;
        height: auto;
        margin: 0 28px;
        border-radius: 5px;

        @media (max-width: 768px) {
          width: 80px;
          height: auto;
        }
      }
    }
  }

  & > .text-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    cursor: pointer;

    @media (max-width: 768px) {
      width: calc(100% - 40px);
      margin: 20px 0 0 0;
    }

    & > .name {
      margin: 0 0px 14px 0;
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: -0.96px;
      text-align: left;
      color: #142580;
    }

    & > .desc {
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: -0.64px;
      text-align: left;
      color: #777;

      @media (max-width: 768px) {
        flex: 2;
      }
    }
  }

  & > .quantity-container-hide {
    display: flex;
    width: 136px;
    align-items: center;
    border: solid 1px #ddd;
    border-radius: 2px;
    margin: 0 28px;

    @media (max-width: 768px) {
      display: none;
    }

    & > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px;
      border: none;
      cursor: pointer;
      background-color: white;
    }

    & > div {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.64px;
      color: #222;
      background-color: #f8f8f8;
      height: 38px;
      border-left: solid 1px #ddd;
      border-right: solid 1px #ddd;
      cursor: default;
    }
  }

  & > .price-container {
    display: flex;
    margin-right: 80px;
    margin-left: 40px;

    @media (max-width: 768px) {
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 20px;
      margin-right: 20px;
      margin-left: 0px;
    }

    & > .price {
      margin-right: 30px;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: -0.64px;
      text-align: left;
      color: #2015ff;

      @media (max-width: 768px) {
        display: flex;
        align-items: center;
        margin: 20px 20px 0 0px;
      }
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    margin-right: 28px;

    @media (max-width: 768px) {
      flex-direction: row-reverse;
      margin-bottom: 40px;
      margin-right: 0;
    }

    & > button {
      width: 152px;
      height: 54.7px;
      margin: 14px 0 0;
      border-radius: 5px;
      cursor: pointer;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: -0.64px;
      text-align: center;

      @media (max-width: 768px) {
        margin: 20px 10px 10px;
        width: calc(50% - 30px);
        height: 46px;
      }
    }

    & > .order {
      background-color: #222;
      border: solid 1px #222;
      color: #fff;
    }

    & > .delete {
      border: solid 1px #ddd;
      background-color: #fff;
      color: #555;
    }

    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
`;
