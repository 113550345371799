import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledNoticeListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  & > div {
    width: 100%;
    min-height: 50px;
  }
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: auto;

  @media (max-width: 768px) {
    width: 100vw;
    border-top: 1px solid #222;
  }

  & > thead {
    display: table-header-group;
    @media (max-width: 768px) {
      display: none;
    }
  }

  & > thead > tr {
    border: none;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #222;
    width: 100%;
  }

  & > thead > tr > .head {
    margin: 0;
    padding: 18px 0;
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -0.64px;
    text-align: center;
    color: #222;
  }

  & > tbody {
    width: 100%;
  }

  & > tbody > tr {
    display: table-row; 
    border: none;
    border-bottom: 1px solid #ddd;
    width: 100%;
    cursor: pointer;
    transition: 0.2s ease-in;

    .row-number, .row-title, .row-author, .row-date, .row-recommend, .row-view {
      display: table-cell;
      text-align: center;
      padding: 18px 0;
      vertical-align: middle;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }

    .row-title {
      word-break: keep-all;
      @media (max-width: 768px) {
        width: calc(100vw - 40px);
        font-size: 16px;
        font-weight: 500;
        margin: 0 20px;
        padding: 0;
      }
    }

    .row-author,
    .row-date,
    .row-recommend,
    .row-view {
      @media (max-width: 768px) {
        display: none;
      }
    }

    .row-mobile {
      margin: 0 20px;
      display: none;
      align-items: center;
      width: calc(100vw - 40px);
      margin-bottom: 10px;

      @media (max-width: 768px) {
        display: flex;
      }

      .row-divider {
        width: 2px;
        height: 2px;
        background-color: #ddd;
        margin: 0 4px;
      }

      .row-author-mobile {
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #777;
      }

      .row-date-mobile {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #777;
        padding-bottom: 1px;
      }

      .row-recommend-mobile,
      .row-view-mobile {
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.17;
        letter-spacing: normal;
        text-align: left;
        color: #777;

        span {
          font-weight: 500;
          color: #2015ff;
        }
      }
    }
  }

  & > tbody > tr:hover {
    background-color: #f8f8f8;
    transition: 0.2s ease-in;
  }

  & > tbody > tr > .row {
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.64px;
    text-align: left;
    color: #222;
    padding: 18px 0;

    @media (max-width: 768px) {
      padding-bottom: 10px;
    }
  }

  .head-number,
  .row-number {
    width: 6%;
  }

  .row-number {
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: -0.56px;
      text-align: center;
      color: #777;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .head-title,
  .row-title {
    width: 50%;
  }

  .row-title {
    & > div {
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.64px;
      text-align: left;
      color: #222;
    }
  }

  .head-author,
  .row-author {
    width: 20%;
  }

  .row-author {
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: -0.56px;
      text-align: center;
      color: #555;
    }
  }

  .head-date,
  .row-date {
    width: 10%;
  }

  .row-date {
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: -0.56px;
      text-align: center;
      color: #777;
    }
  }

  .head-recommend,
  .row-recommend {
    width: 7%;
  }

  .row-recommend,
  .row-view {
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: -0.56px;
      text-align: center;
      color: #777;
    }
  }

  .head-view,
  .row-view {
    width: 7%;
  }
`;
