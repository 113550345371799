import client from "./client";

export const resetPassword = ({ email }) =>
  client.post(`/accounts/email/password/reset/`, {
    email,
  });

export const confirmPasswordReset = ({
  newPassword1,
  newPassword2,
  uid,
  key,
}) =>
  client.post(`/accounts/email/password/reset/confirm/`, {
    newPassword1,
    newPassword2,
    uid,
    key,
  });

export const registByEmail = ({
  email,
  password1,
  password2,
  username,
  phoneNumber,
  joinPath,
}) =>
  client.post(`/accounts/email/registration/`, {
    email,
    password1,
    password2,
    username,
    phoneNumber,
    joinPath,
  });

export const resendRegistByEmail = ({ email }) =>
  client.post(`/accounts/email/registration/resend/`, {
    email,
  });

export const confirmEmail = ({ key }) =>
  client.post(`/accounts/email/registration/account-confirm`, {
    key,
  });

export const authenticateWithURL = ({ key }) =>
  client.get(`/accounts/email/registration/account-confirm/${key}/`);

export const authenticateEmail = ({ key }) =>
  client.post(`/accounts/email/registration/verify/`, { key });

export const loginWithEmail = ({ email, password }) =>
  client.post(`/accounts/email/token/`, { email, password });

export const loginWithGoogle = ({ accessToken }) =>
  client.post(`/accounts/google/token/`, { accessToken });

export const loginWithKakao = ({ accessToken }) =>
  client.post(`/accounts/kakao/token/`, { accessToken });

export const loginWithNaver = ({ accessToken }) =>
  client.post(`/accounts/naver/token/`, { accessToken });

export const refreshToken = ({ refreshToken }) =>
  client.post(`/accounts/token/refresh/`, { refreshToken });

export const verifyToken = ({ accessToken }) =>
  client.post(`/accounts/token/verify/`, { accessToken });

export const getUserInfo = ({ id }) => client.get(`/accounts/users/${id}/`);

export const editUserInfo = ({ id, username, phoneNumber, joinPath }) =>
  client.put(`/accounts/users/${id}/`, { username, phoneNumber, joinPath });

export const deleteUser = ({ id }) => client.delete(`/accounts/users/${id}/`);

export const resetPasswordByUser = ({
  id,
  newPassword1,
  newPassword2,
  password,
}) =>
  client.post(`/accounts/users/${id}/password/`, {
    newPassword1,
    newPassword2,
    password,
  });
