import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";

import "@toast-ui/editor/dist/toastui-editor-viewer.css";

import * as informationAPI from "../../apis/information";
import { StyledPostContainer } from "./Post.styles";
import { toStringByFormatting } from "../../utils/dateFormatter";
import { FE_BASE_URL_HOST } from "../../constants/url";

import { useTranslation } from "react-i18next";

export default function PostDetail({ type }) {
  const componentRef = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [item, setItem] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (type === "notice") {
      informationAPI.getNotice({ id: params.id }).then((res) => {
        if (res.data.linusCode === 1) {
          setItem(res.data.data);
        }
      });
    }

    if (type === "news") {
      informationAPI.getNewsDetail({ id: params.id }).then((res) => {
        if (res.data.linusCode === 1) {
          setItem(res.data.data);
        }
      });
    }

    if (type === "successCase") {
      informationAPI.getSuccessCase({ id: params.id }).then((res) => {
        if (res.data.linusCode === 1) {
          setItem(res.data.data);
        }
      });
    }
  }, [params.id, type]);

  const onRecommend = (e) => {
    e.preventDefault();

    let result = null;

    if (item?.isUserRecommended === false) {
      if (type === "notice") {
        result = informationAPI.recommendNotice({ id: params.id });
      }

      if (type === "news") {
        result = informationAPI.recommendNews({ id: params.id });
      }

      if (type === "successCase") {
        result = informationAPI.recommendSuccessCase({ id: params.id });
      }

      if (result) {
        result
          .then((res) => {
            if (res.data.linusCode === 1) {
              setItem({
                ...item,
                recommendsCount: item.recommendsCount + 1,
                isUserRecommended: true,
              });
            }
          })
          .catch((err) => {
            if (window.confirm("로그인 후 이용해주세요.")) {
              navigate("/auth/sign-in");
            }
          });
      }
    } else if (item?.isUserRecommended === true) {
      if (type === "notice") {
        result = informationAPI.cancelRecommendNotice({ id: params.id });
      }

      if (type === "news") {
        result = informationAPI.cancelRecommendNews({ id: params.id });
      }

      if (type === "successCase") {
        result = informationAPI.cancelRecommendSuccessCase({ id: params.id });
      }

      if (result) {
        result
          .then((res) => {
            if (res.data.linusCode === 1) {
              setItem({
                ...item,
                recommendsCount: item.recommendsCount - 1,
                isUserRecommended: false,
              });
            }
          })
          .catch((err) => {
            if (window.confirm("로그인 후 이용해주세요.")) {
              navigate("/auth/sign-in");
            }
          });
      }
    }
  };

  const onNotRecommend = (e) => {
    e.preventDefault();

    let result = null;

    if (item?.isUserNotRecommended === false) {
      if (type === "notice") {
        result = informationAPI.notRecommendNotice({ id: params.id });
      }

      if (type === "news") {
        result = informationAPI.notRecommendNews({ id: params.id });
      }

      if (type === "successCase") {
        result = informationAPI.notRecommendSuccessCase({ id: params.id });
      }

      if (result) {
        result
          .then((res) => {
            if (res.data.linusCode === 1) {
              setItem({
                ...item,
                notRecommendsCount: item.notRecommendsCount + 1,
                isUserNotRecommended: true,
              });
            }
          })
          .catch((err) => {
            if (window.confirm("로그인 후 이용해주세요.")) {
              navigate("/auth/sign-in");
            }
          });
      }
    } else if (item?.isUserNotRecommended === true) {
      if (type === "notice") {
        result = informationAPI.cancelNotRecommendNotice({ id: params.id });
      }

      if (type === "news") {
        result = informationAPI.cancelNotRecommendNews({ id: params.id });
      }

      if (type === "successCase") {
        result = informationAPI.cancelNotRecommendSuccessCase({
          id: params.id,
        });
      }

      if (result) {
        result
          .then((res) => {
            if (res.data.linusCode === 1) {
              setItem({
                ...item,
                notRecommendsCount: item.notRecommendsCount - 1,
                isUserNotRecommended: false,
              });
            }
          })
          .catch((err) => {
            if (window.confirm("로그인 후 이용해주세요.")) {
              navigate("/auth/sign-in");
            }
          });
      }
    }
  };

  const onShare = (e) => {
    e.preventDefault();

    navigator.clipboard
      .writeText(`${FE_BASE_URL_HOST}${location.pathname}`)
      .then(function (x) {
        alert("URL 복사가 완료되었습니다.");
      });
  };

  const onMoveToList = (e) => {
    e.preventDefault();

    let innerType = type === "successCase" ? "success-case" : type;

    navigate(`/information/${innerType}`);
  };

  const onMoveToPrev = (e) => {
    e.preventDefault();

    let innerType = type === "successCase" ? "success-case" : type;

    if (item.prevId) {
      navigate(`/information/${innerType}/${item.prevId}`);
    } else {
      alert("이전 글이 없습니다.");
    }
  };

  const onMoveToNext = (e) => {
    e.preventDefault();

    let innerType = type === "successCase" ? "success-case" : type;

    if (item.nextId) {
      navigate(`/information/${innerType}/${item.nextId}`);
    } else {
      alert("다음 글이 없습니다.");
    }
  };

  return (
    <StyledPostContainer
      isLiked={item?.isUserRecommended}
      isDisliked={item?.isUserNotRecommended}
      ref={componentRef}
    >
      <div className="contents">
        <div className="title">{item.title}</div>
        <div className="info">
          <div className="author">
            {t("writer")} <div className="divider" />{" "}
            <span>{item.authorName}</span>
          </div>
          <div className="right-info">
            <div className="date">
              {t("date")}
              <div className="divider" />
              <span>{toStringByFormatting(new Date(item.createdDate))}</span>
            </div>
            <div className="view">
              {t("views")}
              <div className="divider" />
              <span>{item.viewsCount}</span>
            </div>
          </div>
        </div>
        <div className="text">
          <div dangerouslySetInnerHTML={{ __html: item.content }} />
        </div>
        <div className="contents-buttons">
          <div className="like recommend-text">
            <button className="recommend" onClick={onRecommend}>
              <div className="icon" />
            </button>
            {item.recommendsCount}
          </div>
          <div className="like not-recommend-text">
            <button className="not-recommend" onClick={onNotRecommend}>
              <div className="icon" />
            </button>
            {item.notRecommendsCount}
          </div>
          <div className="action">
            <button className="share" onClick={onShare}>
              <div className="icon" />
            </button>
            {t("share")}
          </div>
          <ReactToPrint
            trigger={() => {
              return (
                <div className="action">
                  <button className="print">
                    <div className="icon" />
                  </button>
                  {t("print")}
                </div>
              );
            }}
            content={() => componentRef.current}
          />
        </div>
      </div>
      <div className="buttons">
        <div className="prev">
          <button className="prev-button" onClick={onMoveToPrev}>
            {t("prev")}
          </button>
        </div>
        <div className="list">
          <button className="list-button" onClick={onMoveToList}>
            {t("list")}
          </button>
        </div>
        <div className="next">
          <button className="next-button" onClick={onMoveToNext}>
            {t("next")}
          </button>
        </div>
      </div>
    </StyledPostContainer>
  );
}
