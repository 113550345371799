export const newsData = [
  {
    img: "/img/news1.jpeg",
    title: "가톨릭대학교 글로벌경영대학, 한국회계학회 동계학술대회 ‘평생교육위원회’ 세션 운영",
    content:
      "가톨릭대학교 글로벌경영대학(학장 이세용)은 지난 17일(토) 고려대학교 LG-Posco관 LP-107호에서 진행된 ‘2022년 한국회계학회 동계학술대회’에서 ‘평생교육위원회’ 세션을 운영했다고 밝혔다.",
    link: "https://www.asiatoday.co.kr/view.php?key=20221228001547388",
  },
  {
    img: "/img/news2.jpeg",
    title: "‘AI KOREA 2022’ UDACITY Partner 푸름인재개발원 한국형 나노디그리 과정 출시",
    content:
      "28일부터 3일간 진행되는 국내 AI관련 최대 종합 전시회인 ‘AI 코리아 2022’에서 유다시티 한국형 나노디그리 과정이 출시돼 눈길을 끌었다. ‘유다시티 한국형 나노디그리’는 유다시티 공식 파트너인 ‘푸름인재개발원’이 세계 최로로 유다시티 최고의 교육프로그램을 자국 상황에 맞게 최적화하여 개발한 교육 프로그램이다.",
    link: "https://n.news.naver.com/mnews/article/016/0002047044?sid=103",
  },
  {
    img: "/img/news3.jpeg",
    title: "영산대, 유다시티 파트너 푸름인재개발원, 유다시티 본사와 실무협의 개최",
    content:
      "와이즈유 영산대는 최근 해운대캠퍼스에서 유다시티(Udacity) 파트너인 ‘푸름인재개발원’과 유다시티 미국 본사 관계자가 참석한 가운데 나노디그리(Nanodegree) 취득을 통한 업무 능력 향상을 위한 실무 간담회를 가졌다고 7일 밝혔다.",
    link: "https://www.sedaily.com/NewsView/29MX7CUJF8",
  },
];

export const medias = [
  {
    id: "media1",
    src: "/img/media1.png",
    title: "YTN 방영",
    link: "https://youtu.be/sCO2q5e3yh4",
  },
  {
    id: "media2",
    src: "/img/media2.png",
    title: "UDACITY란?",
    link: "https://youtu.be/PCWDnEk2rA0",
  },
  {
    id: "media3",
    src: "/img/media3.png",
    title: "UDACITY 창시자 국내 연설",
    link: "https://youtu.be/Hb5BArrCrjg",
  },
];

export const discountCourses = [
  {
    title: "자율주행 자동차 입문",
    content:
      "Python 기술의 연마, C++의 적용, 코드 내 행렬 및 미적분학의 적용, 그리고 컴퓨터 비전 및 기계학습에 대해 언급할 것입니다.",
    img: "/img/koreancard1.jpg",
    detail: "",
    originprice: "1,900,000원",
    discountprice: "1,710,000원",
    difficulty: "Intermediate",
    link: "/syllabus/Udacity+Enterprise+Syllabus+Intro+to+Self-Driving+Cars+nd113+Custom.pdf",
  },
  {
    title: "Python으로 하는 데이터과학 프로그래밍",
    content:
      "필수적인 데이터 프로그래밍 도구를 배워서 데이터 과학 커리어를 준비하십시오: Python, SQL, 명령줄 그리고 git.",
    img: "/img/koreancard2.jpg",
    detail: "/koreanDataSciencePython",
    originprice: "1,400,000원",
    discountprice: "1,260,000원",
    difficulty: "Beginner",
    link: "/syllabus/Udacity+Enterprise+Syllabus+Programming+for+Data+Science+with+Python+nd104+Custom.pdf",
  },
  {
    title: "자율주행 자동차 엔지니어",
    content:
      "세계에서 가장 발전된 기술을 가진 회사의 자율주행 자동차 팀에서 사용되는 기술 및 테크닉을 배우십시오.",
    img: "/img/koreancard3.jpg",
    detail: "",
    originprice: "2,400,000원",
    discountprice: "2,160,000원",
    difficulty: "Advanced",
    link: "/syllabus/Udacity+Enterprise+Syllabus+Self-Driving+Car+nd013+Custom.pdf",
  },
  {
    title: "인공지능 프로그래밍 with Python",
    content:
      "Python, NumPy, pandas, Matplotlib, PyTorch, 미적분학, 그리고 선형대수학을 배우십시오.",
    img: "/img/koreancard4.jpg",
    detail: "",
    originprice: "1,400,000원",
    discountprice: "1,260,000원",
    difficulty: "Beginner",
    link: "/syllabus/Udacity+Enterprise+Syllabus+AI+Programming+with+Python+nd089+Custom.pdf",
  },
  {
    title: "딥 러닝",
    content: "",
    img: "/img/koreancard5.jpg",
    detail: "",
    originprice: "1,900,000원",
    discountprice: "1,710,000원",
    difficulty: "Intermediate",
    link: "",
  },
  {
    title: "Power BI를 통한 데이터 분석 및 시각화",
    content: "",
    img: "/img/koreancard6.jpg",
    detail: "",
    originprice: "1,400,000원",
    discountprice: "1,260,000원",
    difficulty: "Beginner",
    link: "",
  },
  {
    title: "AWS를 사용한 데이터 엔지니어링",
    content: "",
    img: "/img/koreancard7.jpg",
    detail: "",
    originprice: "2,400,000원",
    discountprice: "2,160,000원",
    difficulty: "Intermediate",
    link: "",
  },
  {
    title: "더 많은 프로그램을 준비중입니다.",
    content:
      "푸름인재개발원에서는 Udacity와 협업하여 한국화 프로그램을 더 많이 설계하고 있습니다. 계속 지켜봐 주세요!",
    img: "/img/koreancard-ready.jpg",
    detail: "/contact",
    difficulty: "-",
    link: "/contact",
  },
];

export const categoryData = [
  {
    title: "Artificial Intelligence",
    description:
      "ProgramListCategoriesSection_category2_desc",
    icon: "/img/categories/ai.svg",
    link: "/programs/1/lectures",
  },
  {
    title: "Autonomous Systems",
    description:
      "ProgramListCategoriesSection_category3_desc",
    icon: "/img/categories/autonomous-systems.svg",
    link: "/programs/2/lectures",
  },
  {
    title: "Business",
    description:
      "ProgramListCategoriesSection_category4_desc",
    icon: "/img/categories/business.svg",
    link: "/programs/3/lectures",
  },
  {
    title: "Cloud Computing",
    description:
      "ProgramListCategoriesSection_category5_desc",
    icon: "/img/categories/cloud.svg",
    link: "/programs/4/lectures",
  },
  {
    title: "Cybersecurity",
    description:
      "ProgramListCategoriesSection_category6_desc",
    icon: "/img/categories/security.svg",
    link: "/programs/5/lectures",
  },
  {
    title: "Data Science",
    description:
      "ProgramListCategoriesSection_category7_desc",
    icon: "/img/categories/datasci.svg",
    link: "/programs/6/lectures",
  },
  {
    title: "Product Management",
    description:
      "ProgramListCategoriesSection_category8_desc",
    icon: "/img/categories/pm.svg",
    link: "/programs/8/lectures",
  },
  {
    title: "Programming & Development",
    description:
      "ProgramListCategoriesSection_category9_desc",
    icon: "/img/categories/pd.svg",
    link: "/programs/7/lectures",
  },
];

export const additionalBenefits = [
  {
    icon: "/img/additional_benefits_lime.svg",
    color: "#bdea09",
    title: "aboutPage_additional_benefits_feature1",
    description: "aboutPage_additional_benefits_description1",
  },
    {
    icon: "/img/additional_benefits_purple.svg",
    color: "#b17cef",
    title: "aboutPage_additional_benefits_feature2",
    description: "aboutPage_additional_benefits_description2",
    description2: "aboutPage_additional_benefits_description2_1",
    description3: "aboutPage_additional_benefits_description2_2",
    description4: "aboutPage_additional_benefits_description2_3",
  },
  {
    icon: "/img/additional_benefits_seafoam.svg",
    color: "#00c5a1",
    title: "aboutPage_additional_benefits_feature3",
    description: "aboutPage_additional_benefits_description3",
    description2: "aboutPage_additional_benefits_description3_1",
  },
  {
    icon: "/img/additional_benefits_blue.svg",
    color: "#6491fc",
    title: "aboutPage_additional_benefits_feature4",
    description: "aboutPage_additional_benefits_description4",
  },
]

export const infiniteOpportunities = [
  {
    title: "Nanodegree Programs",
    description: "aboutPage_price_subscription_feature1",
  },
  {
    title: "Certifications",
    description: "aboutPage_price_subscription_feature2",
  },
  {
    title: "Expert Guidance",
    description: "aboutPage_price_subscription_feature3",
  },
  {
    title: "Courses",
    description: "aboutPage_price_subscription_feature4",
    extra: "※ 현재 22개 과정 한글화 완료, 지속적인 추가 예정",
  },
  {
    title: "Community",
    description: "aboutPage_price_subscription_feature5",
  },
  {
    title: "Projects",
    description: "aboutPage_price_subscription_feature6",
  },
]
