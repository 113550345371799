import React from "react";
import { Button, Container, Col, Row } from "react-bootstrap";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";

import ArrowRight from "../../icon/arrow-right-short.svg";

export default function UdacityConnectSection() {
  const { t } = useTranslation();
  return (
    <AnimatedBackgroundContainer fluid className="my-auto">
      <Row>
        <Col
          md={12}
          className="text-center"
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {" "}
          <p>{t("start-free-subtitle")}</p>
          <h2>{t("start-free-title")}</h2>
          <Button
            variant="success"
            href="https://udacitypartner.com/auth/sign-in"
            className="start-free-button"
            style={{
              color: "black",
              backgroundColor: "#02C5A1",
              marginTop: "1rem",
              fontSize: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "200px",
            }}
            size="lg"
          >
            {t("start-free")}
            <span className="button-icon">
              <img src={ArrowRight} alt="Right arrow" />
            </span>
          </Button>
        </Col>
      </Row>
    </AnimatedBackgroundContainer>
  );
}

export const moveBackground = keyframes`
  from {
    background-position: 0 -70%;
  }
  to {
    background-position: 0 150%;
  }
`;

export const moveBackgroundSmall = keyframes`
  from {
    background-position: 0 -2000%;
  }
  to {
    background-position: 0 2000%;
  }
`;

export const AnimatedBackgroundContainer = styled(Container)`
  background-color: #171954;
  background-image: url("/img/main/contact.webp");
  background-size: cover;
  background-repeat: no-repeat;
  animation: ${moveBackground} 20s linear infinite;
  height: 340px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
  @media (max-width: 600px) {
    height: 280px;
    animation: ${moveBackgroundSmall} 20s linear infinite;
    width: 100%;
  }
`;
