import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import * as orderAPI from "../../apis/order";
import * as contactAPI from "../../apis/serviceCenter";
import { useNavigate } from "react-router-dom";
import * as accountAPI from "../../apis/accounts";
import isLangKo from "../../utils/isLangKo";
import ArrowRight from "../../icon/arrow-right-short.svg";

export function getParameterByName(name, url = "") {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default function Payment({
  amount = 1000,
  name,
  phone,
  udacityEmail = "",
  orderDetail = [],
  btnText = "결제",
  orderMessage = "",
  onComplete,
}) {
  const navigate = useNavigate();

  const [count, setCount] = useState(0);
  const [user, setUser] = useState({});
  const [orderId, setOrderId] = useState(0);
  const [status, setStatus] = useState("WA");
  const [response, setResponse] = useState({});
  // const [isLangKo, setIsLangKo] = useState(isLangKo());

  useEffect(() => {}, [amount, udacityEmail, orderDetail, orderMessage]);

  const getPg = (language) => {
    return isLangKo() ? "html5_inicis" : "paypal";
    // switch (language) {
    //   case "kr":
    //     return "html5_inicis";
    //   case "en":
    //     return "paypal";
    //   default:
    //     return "html5_inicis";
    // }
  };

  const [pg, setPg] = useState(getPg("en"));

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      setCount(1);
      return;
    }
  });

  useEffect(() => {
    if (!user.id) {
      accountAPI.getUserInfo({ id: localStorage.getItem("id") }).then((res) => {
        if (res.data.linusCode === 1) {
          setUser(res.data.data);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (count) {
      startPayment(orderId);
    }
  }, [orderId]);

  useEffect(() => {
    if (count && status !== "WA" && response?.merchant_uid) {
      editOrder(response);
    }
  }, [status, response]);

  const getPaymentMethod = (pg) => {
    switch (pg) {
      case "html5_inicis":
        return "CA";
      case "paypal":
        return "PA";
      default:
        return "CA";
    }
  };

  const cvtResToOrder = (response) => {
    //   imp_uid,        // TODO: Order 데이터베이스에 저장 되어야 함
    //   merchant_uid,   // TODO: Order 데이터베이스에 저장 되어야 함
    //   name,           // TODO: 주문명 => orderMessage 에 저장(?)
    //   buyer_name,     // 구매자 이름
    //   buyer_tel,      // 구매자 전화번호
    //   buyer_email,    // 구매자 이메일
    //   receipt_url,    // 영수증 https 주소

    return {
      name:
        response.buyer_name && response.buyer_name?.length > 0
          ? response.buyer_name
          : name && name?.length > 0
          ? name
          : user?.userName && user?.userName?.length > 0
          ? user?.userName
          : "로그인 유저",
      phone:
        response.buyer_tel && response.buyer_tel?.length > 0
          ? response.buyer_tel
          : phone && phone.length > 0
          ? phone
          : user?.userPhone && user?.userPhone?.length > 0
          ? user?.userPhone
          : "010-",
      udacityEmail,
      orderMessage,
      paymentMethod: getPaymentMethod(pg),
      orderDetail,
      impUid: response.imp_uid,
      merchantUid: response.merchant_uid,
    };
  };

  const editOrder = async (res) => {
    if (!res?.success) {
      alert("결제에 실패하였습니다.");
      return;
    }

    const order = {
      ...cvtResToOrder(res),
      id: res?.orderId ? res?.orderId : orderId,
      status,
    };
    // alert('on edit ', JSON.stringify(order))
    orderAPI
      .editOrder(order)
      .then((res) => {
        if (res.data.linusCode === 1) {
          //alert("주문이 정상 등록 되었습니다. 감사합니다.");
          onComplete();
        } else {
          console.log(res?.data?.data?.msg);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.linusCode === -2) {
          if (window.confirm("로그인 후 이용해주세요.")) {
            navigate("/auth/sign-in");
          }
        } else {
          alert(err?.response?.data?.data?.msg ?? "오류가 발생하였습니다.");
        }
      });
  };

  const sendOrderConfirmMail = async () => {
    const email = udacityEmail;
    let wishProgram = "";
    orderDetail.map((item) => {
      wishProgram += item.lecture_name;
    });
    const affiliation = "구독강의 주문";
    const userOrderMessage = orderMessage;
    contactAPI.createMessage({
      name,
      email,
      phone,
      wishProgram,
      affiliation,
      userOrderMessage
    })
    .then((res) => {
      if (res.data.linusCode === 1) {}
    });
  }

  const saveOrder = async (onSuccess = null) => {
    const paymentMethod = getPaymentMethod(pg);

    const order = {
      status,
      name:
        name && name?.length > 0
          ? name
          : user?.userName && user?.userName?.length > 0 && user?.userName != "undefined"
          ? user?.userName
          : "로그인 유저",
      phone:
        phone && phone?.length > 0
          ? phone
          : user?.phoneNumber && user?.phoneNumber?.length > 0
          ? user?.phoneNumber
          : "010-",
      udacityEmail,
      orderMessage,
      paymentMethod,
      orderDetail,
    };

    orderAPI
      .createOrder(order)
      .then((res) => {
        if (res.data.linusCode === 1) {
          const orderId = res.data.data.id;
          setOrderId(orderId);
          onSuccess && onSuccess(orderId);
        } else {
          alert("필수값들을 입력해 주세요.");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.linusCode === -2) {
          if (window.confirm("로그인 후 이용해주세요.")) {
            navigate("/auth/sign-in");
          }
        } else {
          alert(err?.response?.data?.data?.msg ?? "오류가 발생하였습니다.");
        }
      });
  };

  async function onClickPayment() {
    saveOrder();
  }


  const href = window.location.href;

  useEffect(() => {
    if (status != "WA") return;
    const impSuccess = getParameterByName("imp_success", href);
    const orderId = getParameterByName("orderId", href);
    const imp_uid = getParameterByName("imp_uid", href);
    const merchant_uid = getParameterByName("merchant_uid", href);
    if (impSuccess == "true") {
      setStatus("FI");
      setResponse({
        imp_uid,
        merchant_uid,
        orderId,
      });
    } else if (impSuccess == "false") {
      setStatus("CA");
      setResponse({
        imp_uid,
        merchant_uid,
        orderId,
      });
    }
  }, [href]);

  async function startPayment(orderId) {
    /* 1. 가맹점 식별하기 */
    const { IMP } = window;
    IMP.init("imp64263048");
    /* 2. 결제 데이터 정의하기 */
    const data = {
      pg, // PG사 -> 다국어에 따른 paypal or html5_inicis
      pay_method: "card", // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      amount, // pg === "paypal" ? 1 : 1000, // 결제금액  TODO: 한국어일때 원화 단위, 1000 = 1000원, 영어일때 달러($) 단위
      name: "Udacity 강의구매", // 주문명
      buyer_name:
        name && name?.length > 0
          ? name
          : user.userName && user.userName?.length > 0 && user.userName != "undefined"
          ? user.userName
          : "", // 구매자 이름
      buyer_tel:
        phone && phone?.length > 0
          ? phone
          : user.userPhone && user.userPhone?.length > 0 && user.userPhone != "undefined"
          ? user.userPhone
          : "", // 구매자 전화번호
      buyer_email:
        udacityEmail && udacityEmail?.length > 0
          ? udacityEmail
          : user.email && user.email != "undefined"
          ? user.email
          : "", // 구매자 이메일
      // buyer_addr: '신사동 661-16',            // 구매자 주소  TODO: 필수 아님
      // buyer_postcode: '06018',              // 구매자 우편번호   TODO: 필수 아님
      m_redirect_url: `${href?.split("?")[0]}?orderId=${orderId}`, // TODO: 도메인 주소로, GET 으로 완료페이지 바꾸어야 함 -> 페이팔을 위한, 결제 완료 페이지 호출시 Order 상태 바꾸는 구조로.

      // 취소리턴: http://localhost:3000/?imp_uid=imp_388320643802&merchant_uid=mid_1660734320392&imp_success=false&error_msg=User+cancelled+payment+process.
      // ${orderId}/?status=FI
    };

    const callback = (response) => {
      const { success } = response;

      setResponse(response);

      if (success) {
        setStatus("FI");
        sendOrderConfirmMail();
      } else {
        setStatus("CA");
      }
    };

    /* 4. 결제 창 호출하기 */
    IMP.request_pay(data, callback);
  }

  return (
    <button className="order-button" onClick={onClickPayment}>
      {btnText}
      <img src={ArrowRight} alt="Arrow Right" style={{ filter: "brightness(0) invert(1)" }} />
    </button>
  );
}
