import styled from "styled-components";
import colors from "../../constants/colors";

export const MenuBlock = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    z-index: 10000;
    position: absolute;
    height: 100vh;
    left: 0;
    right: 0;
    top: -1000px;
    padding-bottom: 1.2rem;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
    overflow-y: scroll;
  }
`;

export const LeftHeaderMenu = styled.div`
  display: flex;
  flex: 3;
  align-items: center;
  justify-content: flex-start;
  & > .sign-in,
  & > .sign-out {
    margin-top: 0.45rem;
  }
  & > .right-menu {
    @media (min-width: 769px) {
      display: none;
    }
    @media (max-width: 768px) {
      display: flex;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
  }

  .lang-menu-hidden {
    display: none;
    @media (max-width: 768px) {
      display: flex;
    }
  }

  .lang-menu-hidden-drawal {
    @media (max-width: 768px) {
      margin-bottom: 200px;
    }
  }
`;

export const StyledHiddenBottom = styled.div`
  display: none;
  width: calc(100vw);
  padding: 0 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 100;
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    display: ${(props) => {
      if (props.isVisible) {
        return "flex";
      }
      return "none";
    }};
  }

  & > .right-menu {
    display: flex;
  }

  .cart-bottom {
    width: 50%;
    justify-content: center;
    margin: 0;
  }

  .auth-bottom {
    margin: 0;
  }
`;

export const RightHeaderMenu = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  @media (max-width: 768px) {
    display: none;
    margin: 0;
  }
`;

export const SelectedLinkButton = styled.p`
  color: ${colors.gray[1]};
`;

export const StyledInformationDropDown = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  position: absolute;
  top: 80px;
  z-index: 10;
  color: #222;
  padding: 10px 0;
`;

export const StyledInformationDropDownItem = styled.p`
  width: 160px;
  height: 50px;
  margin: 0 10px;
  padding: 0 0 0 24px;
  display: flex;
  align-items: center;
  transition: 0.25s ease-in;

  &:hover {
    background-color: #f6f5fd;
    color: #2015ff;
    transition: 0.25s ease-in;
  }
`;

export const StyledLanguageDropDown = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  position: absolute;
  top: 80px;
  z-index: 10;
  color: #222;
  padding: 10px 0;

  & > p {
    width: 58px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.25s ease-in;
    margin: 0 10px;
  }
`;

export const StyledLanguageDropDownItem = styled.p`
  &:hover {
    background-color: #f6f5fd;
    color: #2015ff;
    transition: 0.25s ease-in;
  }
`;

export const StyledKoreaVerDropDown = styled.div`
  &.koreaVer-dropdown {
    display: flex;
    width: 894px;
    max-width: calc(100vw);
    flex-direction: row;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    position: absolute;
    top: 80px;
    left: 0px;
    z-index: 10;
    color: #222;
    padding: 0;

    & > .programs-container {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 10px;
    }

    & > .lectures-container {
      width: 50%;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: #f8f8f8;
      padding: 20px;
    }
  }
`;

export const StyledKoreaVerDropDownItem = styled.p`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  height: 50px;
  padding-left: 24px;
  padding-right: 24px;

  .arrow-container {
    flex: 1;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    img {
      position: absolute;
      right: 0;
    }
  }

  background-color: ${(props) => {
    if (props.isHoverProgram) {
      return "#f6f5fd";
    }
    return "none";
  }};
  color: ${(props) => {
    if (props.isHoverProgram) {
      return "#2015ff";
    }
    return "#222";
  }};

  &:hover {
    background-color: ${(props) => {
      if (props.isHoverProgram) {
        return "#f6f5fd";
      }
      return "none";
    }};
    color: ${(props) => {
      if (props.isHoverProgram) {
        return "#2015ff";
      }
      return "#222";
    }};
    transition: 0.25s ease-in;
  }
`;

export const StyledProgramDropDown = styled.div`
  &.program-dropdown {
    display: flex;
    width: 894px;
    max-width: calc(100vw);
    flex-direction: row;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    position: absolute;
    z-index: 100;
    top: 80%;
    left: -10%;
    color: #222;
    margin-top: 8px;

    & > .programs-container {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 10px;
    }

    & > .lectures-container {
      width: 50%;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: #f8f8f8;
      padding: 20px;
    }
  }
`;

export const StyledProgramDropDownItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  height: 50px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  z-index: 20;

  .arrow-container {
    flex: 1;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    img {
      position: absolute;
      right: 0;
    }
  }

  background-color: ${(props) => {
    if (props.isHoverProgram) {
      return "#f6f5fd";
    }
    return "none";
  }};
  color: ${(props) => {
    if (props.isHoverProgram) {
      return "#2015ff";
    }
    return "#222";
  }};

  &:hover {
    background-color: ${(props) => {
      if (props.isHoverProgram) {
        return "#f6f5fd";
      }
      return "none";
    }};
    color: ${(props) => {
      if (props.isHoverProgram) {
        return "#2015ff";
      }
      return "#222";
    }};
    transition: 0.25s ease-in;
  }
`;

export const StyledLectureDropDown = styled.div`
  &.lecture-dropdown {
    display: flex;
    width: 800px;
    max-width: calc(100vw);
    flex-direction: row;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    position: absolute;
    z-index: 100;
    top: 80%;
    left: 50%;
    color: #222;
    margin-top: 8px;

    & > .lectures-container {
      width: 100%;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: #f8f8f8;
      padding: 20px;
    }
  }
`
export const StyledLectureDropDownItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  height: 50px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  z-index: 20;

  background-color: ${(props) => {
    if (props.isHoverProgram) {
      return "#f6f5fd";
    }
    return "none";
  }};
  color: ${(props) => {
    if (props.isHoverProgram) {
      return "#2015ff";
    }
    return "#222";
  }};

  &:hover {
    background-color: ${(props) => {
      if (props.isHoverProgram) {
        return "#f6f5fd";
      }
      return "none";
    }};
    color: ${(props) => {
      if (props.isHoverProgram) {
        return "#2015ff";
      }
      return "#222";
    }};
    transition: 0.25s ease-in;
  }
`;

export const StyledLectureItem = styled.p`
  cursor: pointer;
  margin: 0;
  height: 40px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.56px;
  text-align: left;
  color: #222;
  margin-bottom: 10px;

  &:hover {
    color: #2015ff;
    transition: 0.25s ease-in;
  }
`;

export const Spacer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    height: 300px;
  }
`;

export const StyledInformationDrawal = styled.div`
  display: none;
  flex-direction: column;
  width: calc(100vw - 40px);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;

  @media (max-width: 768px) {
    display: ${(props) => {
      if (props.showInformation) {
        return "flex";
      }
      return "none";
    }};
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
`;

export const StyledInformationDrawalItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0 20px;
  height: 40px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: -0.48px;
  text-align: left;
  color: ${(props) => {
    if (props.selected) {
      return "#2015ff";
    }

    return "#222";
  }};
  background-color: #f4f4f4;
  width: calc(100vw - 80px);
  cursor: pointer;
  padding: 0 20px;

  &:hover {
    color: #2015ff;
    transition: 0.25s ease-in;
  }
`;

export const StyledCartIcon = styled.div`
  width: 24px;
  height: 24px;

  background: url(/img/icon-header-cart.svg);
  background: url(${(props) =>
    props.navColor === "black" ? "/img/icon-header-cart-black.svg" : "/img/icon-header-cart.svg"});
  transition: 0.25s ease-in;

  &:hover {
    background: url(/img/icon-header-cart-colored.svg);
    transition: 0.25s ease-in;
  }
`;
