import ContactBlock from "../../components/others/ContactBlock";
import NavBar from "../../components/common/NavBar";
import { Col, Container, Image, Row } from "react-bootstrap";
import styled from "styled-components";
import { categoryData } from "../../data/pageConext";
import Footer from "../../components/common/Footer";
import { useTranslation } from "react-i18next";

export default function Programs() {
  const { t } = useTranslation();

  return (
    <>
      {/* hero block */}
      <NavBar />
      <BannerBox>
        <Container
          fluid
          className="flex multi-bg-box"
          style={{
            backgroundColor: "#171a53",
            color: "#fff",
          }}
        >
          <Container className="text-center" style={{ marginBottom: "50px" }}>
            <p
              style={{
                fontSize: "15px",
                textAlign: "left",
                color: "#6491fc",
              }}
            >
              {t("ProgramList_Header_SubTitle")}
            </p>
            <div className="display-6 fw-bold mb-4" style={{ lineHeight: 1, textAlign: "left" }}>
              <p>{t("ProgramList_Header_Title1")}</p>
              <p>{t("ProgramList_Header_Title2")}</p>
            </div>
            <div className="pl-header-desc-wrapper">
              <p className="mb-1" style={{ fontSize: "15px", textAlign: "left" }}>
                {t("ProgramList_Header_Desc1")}
              </p>
              <p className="mb-1" style={{ fontSize: "15px", textAlign: "left" }}>
                {t("ProgramList_Header_Desc2")}
              </p>
              <p className="mb-1" style={{ fontSize: "15px", textAlign: "left" }}>
                {t("ProgramList_Header_Desc3")}
              </p>
              <MainImage>
                <Image
                  src="/img/programs-banner.webp"
                  style={{
                    height: "500px",
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    objectFit: "cover",
                  }}
                />
              </MainImage>
            </div>
          </Container>
        </Container>
        {/* categories block */}
        <Container fluid className="categories-block-wrapper">
          <Container className="mb-5 text-center">
            <p className="display-4 fw-bold">Explore All Programs</p>
            <p>{t("ProgramListCategoriesSection_SubTitle")}</p>
          </Container>
          <Container>
            <Row className="g-3 mb-2">
              <KoreaCourseBox className="shadow-sm rounded ">
                <Row className="justify-content-center">
                  <Container>
                    <p className="Banner-title">
                      {t("ProgramListCategoriesSection_category1_title1")}
                    </p>
                    <p className="Banner-title">
                      {t("ProgramListCategoriesSection_category1_title2")}
                    </p>
                    <p className="Banner-content">
                      {t("ProgramListCategoriesSection_category1_desc")}
                    </p>
                  </Container>
                  <a
                    href="/programs/25/lectures"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <p className="text-end" style={{ color: "white" }}>
                      {t("ProgramListCategoriesSection_category1_link")}
                      <span style={{ marginLeft: "8px" }}>
                        <svg
                          viewBox="0 0 32 32"
                          focusable="false"
                          aria-hidden="true"
                          style={{ width: "20px", height: "20px", marginBottom: "3px" }}
                        >
                          <path
                            d="M16.293 7.293a1 1 0 011.414 0l8 8a1 1 0 010 1.414l-8 8a1 1 0 01-1.414-1.414L22.585 17H7a1 1 0 01-.993-.883L6 16a1 1 0 011-1h15.585l-6.292-6.293a1 1 0 01-.083-1.32z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </p>
                  </a>
                </Row>
              </KoreaCourseBox>
            </Row>
            <Row className="justify-content-center g-3">
              {categoryData.map((category) => (
                <HoverCol
                  xs={6}
                  lg={4}
                  xl={3}
                  key={category.title}
                  className="shadow-sm rounded d-flex align-items-center"
                  style={{ height: "280px", maxWidth: "350px" }}
                >
                  <Container>
                    <Image src={category.icon} />
                    <p className="fs-5 fw-bold ">{category.title}</p>
                    <p
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "3",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {t(category.description)}
                    </p>
                    <a
                      href={category.link}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <p className="text-end">
                        {t("ProgramListCategoriesSection_category_more_link")}
                      </p>
                    </a>
                  </Container>
                </HoverCol>
              ))}
            </Row>
          </Container>
        </Container>
      </BannerBox>
      {/* contact block */}
      <ContactBlock />
      <Footer />
    </>
  );
}

const HoverCol = styled(Col)`
  &:hover {
    background-color: #1d15ff;
    transition: all ease 0.5s;
    .container {
      color: white;
      a {
        color: white;
      }
    }
  }
`;

const KoreaCourseBox = styled.div`
  background-image: url(/img/about5.png);
  background-size: 100%;
  color: #fff;
  line-height: 1.7;
  margin-bottom: 20x;
  padding: 40px 40px 0 40px;
  height: auto;
  @media (max-width: 768px) {
    padding: 28px 28px 0 28px;
  }

  .Banner-title {
    font-size: 1.7rem;
    letter-spacing: -0.03rem;
    &:first-child {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      display: inline;
      font-size: 1.1rem;
    }
  }

  .Banner-content {
    font-size: 0.9rem;
    font-weight: 600;
    color: rgb(100, 145, 252);
    @media (max-width: 768px) {
      font-size: 0.8rem;
      font-weight: 400;
      margin-top: 1rem;
    }
  }
  .text-end {
    &:hover {
      color: rgb(100, 145, 252) !important;
    }
  }
`;

const BannerBox = styled.div`
  .multi-bg-box {
    background-image: url("https://www.udacity.com/_next/image?url=%2Fimages%2Fbackgrounds%2Fblue-lines-wide.png&w=3840&q=100");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    position: relative;
    overflow: hidden;
    height: 610px;
    padding-top: 180px;
    @media (max-width: 768px) {
      height: 400px;
      padding-top: 115px;
    }
  }
  .container {
    @media (min-width: 1400px) {
      max-width: 1140px;
    }
  }
  .pl-header-desc-wrapper {
    width: 55%;
    @media (min-width: 769px) and (max-width: 991px) {
      width: 80%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .categories-block-wrapper {
    margin: 150px 0px;
    @media (max-width: 768px) {
      margin: 50px 0px;
    }
  }
`;

const MainImage = styled.div`
  img {
    left: 75%;
  }
  @media (max-width: 991px) {
    display: none;
  }
  @media (min-width: 2200px) {
    img {
      left: 67%;
    }
  }
`;

const HeroBlock = () => {
  const HeroStyled = styled.div`
    position: relative;
    background-image: url(/img/programs-hero1.png);
    background-size: cover;
    background-position: center;
    height: 800px;
    width: 100%;

    @media (max-width: 576px) {
      height: 400px;
    }
  `;

  const TextBlockStyled = styled.div`
    padding-top: 21rem;
    padding-left: 12rem;
    color: white;

    @media (max-width: 576px) {
      padding-top: 12rem;
      padding-left: 2rem;
    }
  `;

  return (
    <HeroStyled>
      <NavBar />
      <TextBlockStyled>
        <p className="display-4 fw-bold">PROGRAMS</p>
        <p>여러분에게 적합한 프로그램을 찾아보세요.</p>
      </TextBlockStyled>
    </HeroStyled>
  );
};
