import styled from "styled-components";

export const FlexRowCenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  .pagination {
    background-color: transparent;
    color: black;
    text-decoration: none;
  }
  .pagination-item {
    line-height: 1.8;
  }
  a {
    text-decoration: none;
    color: black;
    padding: 5px;
    &.pagination-active-link {
      color: #2016ff;
      font-weight: 500;
    }
  }
  img[alt="next"] {
    height: 26px;
    padding-top: 2px;
  }
`;
