import React, { useState, useEffect, useCallback } from "react";
import { StyledCartItemContainer, StyledShoppingPageContainer } from "./ShoppingPage.styles";
import { useNavigate } from "react-router-dom";

import * as orderAPI from "../apis/order";

import { useRecoilState } from "recoil";
import { orderItemState, shoppingItemState } from "../states/atoms";

import { useTranslation } from "react-i18next";
import isLangKo from "../utils/isLangKo";
import NavBar from "../components/common/NavBar";

const CartItem = ({ id, lecture, selectedItems, onToggleSelect, onClickDelete }) => {
  const navigate = useNavigate();

  const [orderItem, setOrderItem] = useRecoilState(orderItemState);

  const [isSelected, setIsSelected] = useState(
    selectedItems.map((i) => i?.lecture?.id).includes(lecture?.id),
  );

  const onClickBuyNow = useCallback(() => {
    if (!localStorage.getItem("accessToken")) {
      if (window.confirm("로그인 후 이용해주세요.")) {
        navigate("/auth/sign-in");
        return;
      }
    }

    setOrderItem([lecture]);
    navigate("/order");
  }, [lecture, navigate, setOrderItem]);

  useEffect(() => {
    setIsSelected(selectedItems.map((i) => i?.id).includes(lecture?.id));
  }, [selectedItems]);

  const { t } = useTranslation();

  return (
    <StyledCartItemContainer>
      <div className="upper">
        <div className="checkbox-container">
          <img
            className="checkbox"
            src={isSelected ? "/img/on.svg" : "/img/off.svg"}
            alt="off"
            onClick={() => {
              onToggleSelect(lecture);
            }}
          />
        </div>
        <div
          className="img-container"
          onClick={() => {
            navigate(`/programs/lecture/${lecture?.id}`);
          }}
        >
          <img className="thumbnail" src={lecture?.thumbnail} alt="thumbnail" />
        </div>
      </div>
      <div
        className="text-container"
        onClick={() => {
          navigate(`/programs/lecture/${lecture?.id}`);
        }}
      >
        <div className="name">{lecture?.name}</div>
        <div className="desc">
          {isLangKo() ? lecture?.descriptionSummary : lecture?.descriptionSummaryEnglish}
        </div>
      </div>
      <div className="price-container">
        <div className="price">
          {isLangKo() ? "₩" : "$"}{" "}
          {isLangKo() ? lecture?.purunetPriceKRW : lecture?.purunetPriceUSD}
        </div>
      </div>
      <div className="button-container">
        <button
          className="order"
          onClick={(e) => {
            e.preventDefault();
            onClickBuyNow();
          }}
        >
          {t("buy-now")}
        </button>
        <button
          className="delete"
          onClick={(e) => {
            e.preventDefault();
            onClickDelete(lecture);
          }}
        >
          {t("delete")}
        </button>
      </div>
    </StyledCartItemContainer>
  );
};

export default function ShoppingPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [orderItem, setOrderItem] = useRecoilState(orderItemState);
  const [shoppingItem, setShoppingItem] = useRecoilState(shoppingItemState);

  useEffect(() => {
    orderAPI.getShoppingCarts().then((res) => {
      console.log(res);
      if (res.data.linusCode === 1) {
        setItems(res.data.data);
        setSelectedItems(res.data.data.map((i) => i.lecture));
      }
    });
  }, []);

  const onToggleSelectAll = () => {
    if (selectedItems.length >= items.length) {
      setSelectedItems([]);
      return;
    }
    setSelectedItems(items.map((i) => i.lecture));
  };

  const onToggleSelect = (item) => {
    const selectedItemIds = selectedItems.map((i) => i?.id);
    const itemId = item.id;

    if (selectedItemIds.includes(itemId)) {
      setSelectedItems(selectedItems.filter((i) => i?.id !== itemId));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const onClickDelete = (item) => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      orderAPI
        .deleteShoppingCart({
          id: items?.filter((i) => i?.lecture?.id === item?.id)[0]?.id,
        })
        .then((res) => {
          if (res.data.linusCode === 1) {
            setItems(items.filter((i) => i.lecture?.id !== item.id));
            setSelectedItems(selectedItems.filter((i) => i.id !== item.id));
            setOrderItem(orderItem.filter((i) => i.id !== item.id));
          }
        });
    }
  };

  const onClickDeleteSelected = () => {
    if (selectedItems.length === 0) {
      alert("선택된 강의가 없습니다.");
      return;
    }

    if (window.confirm("정말로 삭제하시겠습니까?")) {
      const targetIds = items
        .filter((i) => selectedItems.map((i) => i.id).includes(i.lecture?.id))
        .map((i) => i.id);

      Promise.all(
        targetIds.map((id) => {
          return orderAPI.deleteShoppingCart({
            id,
          });
        }),
      ).then((values) => {
        if (values.map((i) => i.data.linusCode).filter((i) => i !== 1).length === 0) {
          setItems(items.filter((i) => !targetIds.includes(i.id)));
          setSelectedItems(selectedItems.filter((i) => !targetIds.includes(i.id)));
          setOrderItem(orderItem.filter((i) => !targetIds.includes(i.id)));
          setShoppingItem(shoppingItem.filter((i) => !targetIds.includes(i.id)));
        }
      });
    }
  };

  const onClickBuy = useCallback(() => {
    if (selectedItems.length === 0) {
      alert("선택된 강의가 없습니다.");
      return;
    }
    setOrderItem(selectedItems);
    navigate("/order");
  }, [navigate, selectedItems, setOrderItem]);

  return (
    <>
      <NavBar navColor={"black"}/>
      <StyledShoppingPageContainer>
        <div className="container">
          <div className="title-container">
            <h3>{t("cart")}</h3>
            <hr />
          </div>
          {items.length === 0 && <div className="cart-empty">{t("no-cart")}</div>}
          {items.length > 0 && (
            <>
              <div className="upper-container">
                <div
                  className="check-container"
                  onClick={() => {
                    onToggleSelectAll();
                  }}
                >
                  <img
                    src={
                      items.length > 0 && selectedItems.length === items.length
                        ? "/img/on.svg"
                        : "/img/off.svg"
                    }
                    alt="off"
                  />
                  <div className="select-all">{t("select-all")}</div>
                  <div className="count">
                    <span>{selectedItems.length}</span> / {items.length}
                  </div>
                </div>
                <div className="delete-container">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      onClickDeleteSelected();
                    }}
                  >
                    {t("select-delete")}
                  </button>
                </div>
              </div>
              <div className="contents-container">
                {items.map((i) => (
                  <CartItem
                    key={i.id}
                    {...i}
                    selectedItems={selectedItems}
                    onToggleSelect={onToggleSelect}
                    onClickDelete={onClickDelete}
                  />
                ))}
                <div className="total-price">
                  <div>
                    = {t("total-price")}
                    <span>
                      {isLangKo() ? "₩" : "$"}{" "}
                      {isLangKo() &&
                        selectedItems
                          ?.map((i) => i?.purunetPriceKRW)
                          ?.reduce((prev, next) => prev + next, 0)}
                      {!isLangKo() &&
                        selectedItems
                          ?.map((i) => i?.purunetPriceUSD)
                          ?.reduce((prev, next) => prev + next, 0)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="order-container">
                <div className="order-container">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      onClickBuy();
                    }}
                  >
                    {t("payment")}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </StyledShoppingPageContainer>
    </>
  );
}
