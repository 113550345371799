import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Accordion, Button, Form, InputGroup } from "react-bootstrap";
import { StyledOrderPageContainer } from "./OrderPage.styles";
import Payment from "../components/common/Payment";

import { useRecoilState } from "recoil";
import { orderItemState } from "../states/atoms";
import { useInput } from "../utils/useInput";
import { useTranslation } from "react-i18next";
import isLangKo from "../utils/isLangKo";
import NavBar from "../components/common/NavBar";

import CheckCircle from "../icon/check-circle.svg";

export default function OrderPage({ subplan }) {
  const { t } = useTranslation();
  const location = useLocation();
  const plan = location.state?.subplan || "monthly";

  const [orderItem, setOrderItem] = useRecoilState(orderItemState);
  const [name, setName] = useInput("");
  const [phone, setPhone] = useInput("");
  const [email, setEmail] = useInput("");
  const [msg, setMsg] = useInput("");
  const [modalVisible, setModalVisible] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState(plan);
  const fourMonthPrice = 1088000;
  const oneMonthPrice = 320000;
  const [amount, setAmount] = useState(plan === "fourMonth" ? fourMonthPrice : oneMonthPrice);
  const [bill, setBill] = useState(`${parseInt(amount).toLocaleString()}원`);

  useEffect(() => {
    const initialAmount = plan === "fourMonth" ? fourMonthPrice : oneMonthPrice;
    console.log(plan);
    setSubscriptionPlan(plan);
    setAmount(initialAmount);
    setBill(`${parseInt(initialAmount).toLocaleString()}원`);
  }, [plan]);

  const handlePlanChange = (selectedPlan) => {
    const newAmount = selectedPlan === "fourMonth" ? fourMonthPrice : oneMonthPrice;
    setSubscriptionPlan(selectedPlan);
    setAmount(newAmount);
    setBill(`${parseInt(newAmount).toLocaleString()}원`);
  };

  return (
    <>
      <NavBar navColor={"black"} />
      <StyledOrderPageContainer>
        {modalVisible && (
          <div
            className="modal"
            onClick={(e) => {
              e.preventDefault();
              if (
                e.target.className === "contents-container" ||
                e.target.className === "contents" ||
                e.target.className === "title" ||
                e.target.className === "title-text" ||
                e.target.className === "close-container"
              ) {
                return;
              }

              setModalVisible(false);
              document.body.style.overflow = "unset";
            }}
          >
            <div className="contents-container">
              <div className="title">
                <div className="close-container">
                  <img src="/img/close-black.svg" alt="close" />
                </div>
              </div>
              <div className="contents">
                결제가 완료되었습니다. <br />
                잠시만 기다려 주시면 결제하신 강의를 <br />
                <span
                  onClick={() => {
                    window.open("https://www.udacity.com");
                  }}
                >
                  UDACITY 홈페이지
                </span>
                에서 수강 할 수 있습니다.
              </div>
            </div>
          </div>
        )}
        <div className="left-container">
          <div className="form-container">
            <div className="title-container">
              <h3>{t("order-pay")}</h3>
            </div>

            <div className="orderer-info-container">
              <div className="orderer-info-title">선택하신 대표과정</div>
              {orderItem.map((item) => {
                return <p className="fs-6">{item.name}</p>;
              })}
              <div className="text-info">* 선택이외의 과정이 필요하시면 메일을 보내주세요</div>
              <div className="orderer-info-title">{t("orderer-info")}</div>
              <div className="orderer-form">
                <div className="orderer-form-row">
                  <div className="orderer-form-input">
                    <label htmlFor="name">
                      이름 Full Name <span>*</span>
                    </label>
                    <input
                      name="name"
                      id="name"
                      placeholder={t("order-placeholder", {
                        input: isLangKo() ? "이름" : "your name",
                      })}
                      type={"text"}
                      onChange={setName}
                    />
                  </div>
                  <div className="orderer-form-input">
                    <label htmlFor="phone">
                      연락처 Phone <span>*</span>
                    </label>
                    <input
                      name="phone"
                      id="phone"
                      placeholder={t("order-placeholder", {
                        input: isLangKo() ? "연락처" : "your phone number",
                      })}
                      type={"phone"}
                      onChange={setPhone}
                    />
                  </div>
                </div>
                <div className="orderer-form-row">
                  <div className="orderer-form-input">
                    <label htmlFor="email">
                      이메일 Email <span>*</span>
                    </label>
                    <input
                      name="email"
                      id="email"
                      placeholder={t("order-placeholder", {
                        input: isLangKo() ? "이메일" : "your email address",
                      })}
                      type={"email"}
                      onChange={setEmail}
                    />
                  </div>
                </div>
                <div className="email-desc-container">
                  <p className="red-text text">* {t("write-udacity-email")}</p>
                  <p className="text">{t("udacity-email-desc")}</p>
                </div>
              </div>
            </div>
            <div className="additional-info-container">
              <div className="additional-info-title">{t("add-info")}</div>
              <div className="additional-info-form">
                <label htmlFor="additional">
                  주문 관련 메시지 Additional Comments <span>(선택)</span>
                </label>
                <textarea
                  name="additional"
                  placeholder={t("order-placeholder", {
                    input: isLangKo() ? "주문 관련 메시지" : "additional comments",
                  })}
                  onChange={setMsg}
                />
              </div>
            </div>
            <div className="button-container">
              <Payment
                orderMessage={msg}
                amount={amount}
                udacityEmail={email}
                name={name}
                phone={phone}
                btnText={t("payment")}
                orderDetail={orderItem.map((item) => {
                  return {
                    lecture: item.id,
                    lecture_name: item.name,
                    lectureCount: 1,
                  };
                })}
                onComplete={() => {
                  setModalVisible(true);
                  setTimeout(() => {
                    setModalVisible(false);
                  }, 5000);
                }}
              />
            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="lecture-container">
            <div className="lecture-title">Individual Plan</div>
            <div className="plan-details">
              <p>{t("order-pay-will")}</p>
              <p>{bill}</p>
            </div>
            <div className="subscription-options">
              <div className="radio-group">
                <label className="radio-option">
                  <input
                    type="radio"
                    id="fourMonth"
                    name="subscriptionPlan"
                    value="fourMonth"
                    checked={subscriptionPlan === "fourMonth"}
                    onChange={() => handlePlanChange("fourMonth")}
                  />
                  {t("subscription-4month")} 
                  <span style={{ color: "#198754" }}>Save 15%</span>
                </label>
                <label className="radio-option">
                  <input
                    type="radio"
                    id="monthly"
                    name="subscriptionPlan"
                    value="monthly"
                    checked={subscriptionPlan === "monthly"}
                    onChange={() => handlePlanChange("monthly")}
                  />
                  {t("subscription-month")} 
                  {/* <span style={{ color: "#198754" }}>Save 10%</span> */}
                </label>
              </div>
            </div>
            <div className="discount-code-section">
              <Accordion defaultActiveKey="0">
                <Accordion.Item>
                  <Accordion.Header>할인쿠폰입력</Accordion.Header>
                  <Accordion.Body className="d-flex align-items-stretch">
                    <div className="flex-grow-1 me-2">
                      <InputGroup>
                        <Form.Control
                          placeholder="Gift card or discount coupon"
                          aria-label="Discount code"
                        />
                      </InputGroup>
                    </div>
                    <div style={{ width: "40%" }}>
                      <Button variant="outline-primary" className="w-100">
                        Apply
                      </Button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            {/* Total section */}
            <div className="total-section">
              <p>Total</p>
              <div>{bill}</div>
            </div>
          </div>
          <aside className="udacity-business-container">
            <div className="title">{t("plan-include")}</div>
            <ul className="plan-include">
              <li style={{ fontWeight: "bold" }}>
                <img src={CheckCircle} alt="Check Circle" />
                {t("plan-include1")}
              </li>
              <li>
                <img src={CheckCircle} alt="Check Circle" />
                {t("plan-include2")}
              </li>
              <li>
                <img src={CheckCircle} alt="Check Circle" />
                {t("plan-include3")}
              </li>
              <li>
                <img src={CheckCircle} alt="Check Circle" />
                {t("plan-include4")}
              </li>
            </ul>
          </aside>
        </div>
      </StyledOrderPageContainer>
    </>
  );
}
