import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Offcanvas,
  Row,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";

import {
  StyledProgramDropDown,
  StyledProgramDropDownItem,
  StyledLectureItem,
  StyledLanguageDropDown,
  StyledLanguageDropDownItem,
  StyledLectureDropDown,
  StyledLectureDropDownItem,
} from "./Menus.styles";

import * as accountAPI from "../../apis/accounts";
import * as lectureAPI from "../../apis/lecture";

import styled from "styled-components";
import colors from "../../constants/colors";

import { useTranslation } from "react-i18next";
import isLangKo from "../../utils/isLangKo";

export default function NavBar() {
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };

  const navImg = "/img/udacitypartner_logo_black.png";

  const [menuVisible, setMenuVisible] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [showProgram, setShowProgram] = useState(false);
  const [hoverProgram, setHoverProgram] = useState(0);
  const [lectures, setLectures] = useState(categories[0]?.lecture);

  const toggleShowProgram = () => {
    setShowProgram(!showProgram);
  };

  const onToggleMenu = () => {
    if (window.innerWidth <= 768) {
      setMenuVisible((menuVisible) => !menuVisible);

      if (!menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    }
  };

  const sortLecturesFunc = lectures?.sort((a, b) => {
    let x = a.name.toLowerCase();
    let y = b.name.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    async function fetchCategories() {
      const record_time = new Date(localStorage.getItem("record_time"));
      const current_time = new Date();
      const diffTime = Math.abs(current_time - record_time);
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
      const one_hours = 1;

      const storedCategories = localStorage.getItem("categories");
      if (storedCategories && diffHours <= one_hours) {
        setCategories(JSON.parse(storedCategories));
      } else {
        lectureAPI.getCategories().then((res) => {
          if (res.data.linusCode === 1) {
            localStorage.setItem("categories", JSON.stringify(res.data.data));
            setCategories(res.data.data);
            // Reset refresh flag
            localStorage.setItem("record_time", new Date().toString());
          }
        });
      }
    }
    fetchCategories();
  }, []);

  const [navCollapse, setNavCollapse] = useState(false);

  const handleClose = () => setNavCollapse(false);
  const handleShow = () => setNavCollapse(true);

  useEffect(() => {
    async function verifyLogin() {
      const accessToken = localStorage.getItem("accessToken");
      try {
        const res = await accountAPI.verifyToken({ accessToken });
        setIsLogin(res?.data?.linusCode === 1);
      } catch (err) {
        setIsLogin(false);
      }
    }

    verifyLogin();
  }, [location]);

  const params = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  useEffect(() => {
    const fetchSearchResults = async () => {
      if (!searchTerm.trim()) {
        setSearchResults([]);
        return;
      }

      params.name = searchTerm;
      try {
        const res = await lectureAPI.getLectureByName({ name: params.name });
        if (res.data.linusCode === 1) {
          setSearchResults(res.data.data);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
        setSearchResults([]);
      }
    };

    const timeoutId = setTimeout(fetchSearchResults, 500); // Delay search to avoid sending a request for every key press
    return () => clearTimeout(timeoutId);
  }, [searchTerm, params]);

  return (
    <NavBarStyle fixed="top" expand="xl">
      <Container fluid>
        <Col style={{ paddingRight: "20px", paddingLeft: "20px", width: "auto" }}>
          <Navbar.Brand href="/" style={{ marginRight: 0 }}>
            <LogoImg src={navImg} width="180" style={{ objectFit: "cover" }} />
          </Navbar.Brand>
        </Col>
        <Col lg={6} xl={10}>
          <div className="d-none d-xl-block">
            <Nav style={{ gap: "10px", height: "50px" }}>
              {/* Programs */}
              <MyNavLink
                href="/programs"
                onMouseEnter={(e) => {
                  e.preventDefault();
                  toggleShowProgram();
                }}
                onMouseLeave={(e) => {
                  e.preventDefault();
                  toggleShowProgram();
                }}
              >
                Learn
                <div className="arrowIcon" />
                {showProgram && (
                  <StyledProgramDropDown className="program-dropdown">
                    <div className="programs-container">
                      {Object.keys(categories).map((key) => (
                        <StyledProgramDropDownItem
                          key={key}
                          isHoverProgram={hoverProgram === categories[key].id}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/programs/${categories[key].id}/lectures`);
                            onToggleMenu();
                          }}
                          onMouseEnter={(e) => {
                            setHoverProgram(categories[key].id);
                            setLectures(categories[key].lecture);
                          }}
                        >
                          {categories[key].name}
                          <div className="arrow-container">
                            <img src="/img/arrow-right-on.svg" alt="arrow" />
                          </div>
                        </StyledProgramDropDownItem>
                      ))}
                    </div>

                    <div className="lectures-container">
                      {sortLecturesFunc?.map((i) => (
                        <StyledLectureItem
                          key={i.id}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/programs/lecture/${i.id}`);
                            onToggleMenu();
                          }}
                        >
                          {i.name}
                        </StyledLectureItem>
                      ))}
                    </div>
                  </StyledProgramDropDown>
                )}
              </MyNavLink>
              <MyNavLink href="/about">About Us</MyNavLink>
              <MyNavLink href="/Why">Why Udacity</MyNavLink>
              {/* <MyNavLink href="/udacity">Goverment</MyNavLink> */}
              <MyNavLink href="/information/news">News</MyNavLink>
              {/* <MyNavLink href="/contact"> 
                      CONTACT
                    </MyNavLink> */}
              <InputGroup style={{ width: "auto", marginLeft: "auto" }}>
                <SearchInput
                  type="search"
                  placeholder=" What do you want to learn?"
                  aria-label="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
              {searchResults.length > 0 && (
                <StyledLectureDropDown className="lecture-dropdown">
                  <div className="lectures-container">
                    {searchResults.map((result) => (
                      <StyledLectureDropDownItem
                        key={result.id}
                        onClick={() => navigate(`/programs/lecture/${result.id}`)}
                      >
                        {result.name}
                      </StyledLectureDropDownItem>
                    ))}
                  </div>
                </StyledLectureDropDown>
              )}
              {/*  Login or Sign In*/}
              {isLogin ? (
                <MyNavLink
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/my/order");
                    onToggleMenu();
                  }}
                >
                  MY PAGE
                </MyNavLink>
              ) : (
                <>
                  <SignInButton
                    className="mx-2"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/auth/sign-in");
                      onToggleMenu();
                    }}
                  >
                    Log In
                  </SignInButton>
                  <JoinButton
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/auth/sign-up");
                      onToggleMenu();
                    }}
                  >
                    Join For Free
                  </JoinButton>
                </>
              )}
              <MyNavLink>
                {isLangKo() ? "KO" : "EN"}
                <div className="arrowIcon" />
                <StyledLanguageDropDown className="languageDropDown">
                  <StyledLanguageDropDownItem
                    onClick={(e) => {
                      e.preventDefault();
                      changeLanguageHandler("ko-KR");
                      onToggleMenu();
                    }}
                  >
                    KO
                  </StyledLanguageDropDownItem>
                  <StyledLanguageDropDownItem
                    onClick={(e) => {
                      e.preventDefault();
                      changeLanguageHandler("en-US");
                      onToggleMenu();
                    }}
                  >
                    EN
                  </StyledLanguageDropDownItem>
                </StyledLanguageDropDown>
              </MyNavLink>
            </Nav>
          </div>
        </Col>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={handleShow}
          style={{ border: "none" }}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Offcanvas show={navCollapse} onHide={handleClose} placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <a href="/">
                  <Image src="/img/udacitypartner_logo_black.png" width="150" />
                </a>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="text-start">
                <Nav.Link href="/programs">Learn</Nav.Link>
                <Nav.Link href="/about">About Us</Nav.Link>
                {/* <Nav.Link href="/udacity">UDACITY</Nav.Link> */}

                <Nav.Link href="/Why">Why Udacity</Nav.Link>
                <Nav.Link href="/information/news">News</Nav.Link>
                <Nav.Link href="/contact">Contact</Nav.Link>
                {/*  Login or Sign In*/}
                {isLogin ? (
                  <MyNavLink
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/my/order");
                      onToggleMenu();
                    }}
                  >
                    MY PAGE
                  </MyNavLink>
                ) : (
                  <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <OffcanvasSignInButton
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/auth/sign-in");
                          onToggleMenu();
                        }}
                      >
                        Log In
                      </OffcanvasSignInButton>
                    </Col>
                    <Col>
                      <OffcanvasJoinButton
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/auth/sign-up");
                          onToggleMenu();
                        }}
                      >
                        Join For Free
                      </OffcanvasJoinButton>
                    </Col>
                  </Row>
                )}
                <MyNavLink
                  style={{ order: "-1", justifyContent: "end", fontSize: "0.8rem", padding: "0" }}
                >
                  {isLangKo() ? "KO" : "EN"}
                  <div className="arrowIcon" />
                  <OffcanvasStyledLanguageDropDown className="languageDropDown">
                    <OffcanvasStyledLanguageDropDownItem
                      onClick={(e) => {
                        e.preventDefault();
                        changeLanguageHandler("ko-KR");
                        onToggleMenu();
                      }}
                    >
                      KO
                    </OffcanvasStyledLanguageDropDownItem>
                    <OffcanvasStyledLanguageDropDownItem
                      onClick={(e) => {
                        e.preventDefault();
                        changeLanguageHandler("en-US");
                        onToggleMenu();
                      }}
                    >
                      EN
                    </OffcanvasStyledLanguageDropDownItem>
                  </OffcanvasStyledLanguageDropDown>
                </MyNavLink>
              </Nav>
            </Offcanvas.Body>
          </Offcanvas>
        </Navbar.Collapse>
      </Container>
    </NavBarStyle>
  );
}

const NavBarStyle = styled(Navbar)`
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1030;
  height: 66px;
`;

const LogoImg = styled(Image)`
  @media (max-width: 768px) {
    width: 150px;
  }
`;

const MyNavLink = styled(Nav.Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.gray[0]};
  cursor: pointer;
  position: relative;
  z-index: 20;
  font-weight: 450;
  font-family: inherit;
  letter-spacing: 1px;

  &:hover {
    color: #2015ff;
    transition: 0.25s ease-in;
  }

  & > .arrowIcon {
    display: block;
    width: 15px;
    height: 15px;
    background: url(/img/arrow-down.svg);
    color: white;
    font-weight: 500;
  }

  &:hover .arrowIcon {
    background: url(/img/arrow-up.svg);
    transition: 0.25s ease-in;
  }

  & > .program-dropdown {
    transition: 0.25s ease-in;
  }

  &:hover > .program-dropdown {
    display: flex;
    color: #222;
    transition: 0.25s ease-in;

    @media (max-width: 768px) {
      display: none;
    }
  }

  & > .languageDropDown {
    top: 70%;
    display: none;
    transition: 0.25s ease-in;
  }

  &:hover > .languageDropDown {
    display: block;
    color: #222;
    transition: 0.25s ease-in;
  }
`;

const SearchInput = styled(FormControl)`
  border-radius: 25px;
  border: none;
  padding-left: 40px;
  height: 50px;
  background-image: url(/img/search_icon.svg);
  background-repeat: no-repeat;
  background-position: 17px center;
  background-size: 16px;
  background-color: #f6f6f6;
  margin: 0 auto;
  min-width: 280px !important;
`;

const SignInButton = styled(Button)`
  border: 2px solid #2015ff;
  background-color: #fff;
  color: #2015ff;
  font-weight: 500;
  &:hover {
    background-color: #171a53;
    border: 2px solid #0c0d34;
    color: #fff;
  }
`;

const OffcanvasSignInButton = styled(Button)`
  width: 100%;
  border: 2px solid #2015ff;
  background-color: #fff;
  color: #2015ff;
  font-weight: 500;
  &:hover {
    background-color: #0c0d34;
    border: 2px solid #0c0d34;
    color: #fff;
  }
`;

const JoinButton = styled(Button)`
  border: 2px solid #2015ff;
  background-color: #2015ff;
  color: white;
  font-weight: 500;
  width: auto;
  &:hover {
    background-color: #171a53;
    border: 2px solid #0c0d34;
    color: #fff;
  }
`;

const OffcanvasJoinButton = styled(Button)`
  width: 100%;
  border: 2px solid #2015ff;
  background-color: #2015ff;
  color: white;
  font-weight: 500;
  &:hover {
    background-color: #0c0d34;
    border: 2px solid #0c0d34;
    color: #fff;
  }
`;

const OffcanvasStyledLanguageDropDown = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  position: absolute;
  top: 80px;
  z-index: 10;
  color: #222;

  & > p {
    width: 58px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.25s ease-in;
    margin: 0 10px;
  }
`;

const OffcanvasStyledLanguageDropDownItem = styled.p`
  &:hover {
    background-color: #f6f5fd;
    color: #2015ff;
    transition: 0.25s ease-in;
  }
`;
