import NavBar from "../components/common/NavBar";
import Terms from "../components/common/Terms";
import { Container, Image, Row, Button } from "react-bootstrap";
import * as contactAPI from "../apis/serviceCenter";
import styled from "styled-components";
import { useState } from "react";
import isLangKo from "../utils/isLangKo";
import Footer from "../components/common/Footer";
import UdacityConnectSection from "../components/others/ContactBlock";

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^\d{2,3}-{0,1}\d{3,4}-{0,1}\d{4}$/;

const FormContainer = styled(Container)`
  max-width: 800px;
  .form-container {
    background-color: #fff;
    height: 750px;
  }

  .form-container form {
    display: flex;
    padding: 40px 0 0 0;
    margin: 40px;
    flex-direction: column;
    @media (max-width: 550px) {
      margin: 20px;
    }
  }

  .form-container form input,
  .form-container form textarea {
    height: 54px;
    margin: 0 0 20px;
    padding: 15px 0 0;
    background-color: #fff;
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -0.64px;
    text-align: left;
    color: #222;
    padding: 0;
    border: none;
    border-bottom: solid 1px #222;
    outline: 0;
  }

  .form-container form input {
    height: 54px;
  }

  .form-container form textarea {
    height: 200px;
  }

  .form-container form input::placeholder,
  .form-container form textarea::placeholder {
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -0.64px;
    text-align: left;
    color: #aaa;
  }

  .form-container form .form-bottom {
    display: flex;
    align-items: center;
    @media (max-width: 550px) {
      display: block;
    }
  }

  .form-container form .form-agree {
    display: flex;
    align-items: center;
  }

  .form-container form .form-agree img {
    cursor: pointer;
  }

  .form-container form .form-agree .agree {
    margin: 0 0 0 10px;
    padding: 0;
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: -0.56px;
    text-align: left;
    color: #777;
  }

  .form-container form .form-agree .agree span {
    color: #222;
    cursor: pointer;
  }

  .form-container form .form-submit {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 550px) {
      justify-content: center;
      padding-top: 40px;
    }
  }

  .form-container form .form-submit button {
    width: 160px;
    height: 54px;
    padding: 14px 34px;
    border-radius: 2px;
    background-color: #2015ff;
    border: none;
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.72px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    transition: 0.25s ease-in;
  }

  .form-container form .form-submit button:disabled {
    background-color: #ccc;
    transition: 0.25s ease-in;
  }
`;

const validateEmail = (email) => EMAIL_REGEX.test(email.toLowerCase());
const validatePhone = (phone) => PHONE_REGEX.test(phone);

const Contact = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    wishProgram: "",
    affiliation: "",
    message: "",
  });
  const [isValid, setIsValid] = useState({
    name: false,
    email: false,
    phone: false,
  });
  const [isAgree, setIsAgree] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setForm((prevForm) => ({ ...prevForm, [name]: value }));

    if (name === "name")
      setIsValid((prevIsValid) => ({ ...prevIsValid, name: value.trim().length > 0 }));
    if (name === "email")
      setIsValid((prevIsValid) => ({ ...prevIsValid, email: validateEmail(value) }));
    if (name === "phone")
      setIsValid((prevIsValid) => ({ ...prevIsValid, phone: validatePhone(value) }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, phone } = isValid;

    if (!(name && email && phone)) {
      alert("이름, 이메일, 연락처 형식을 올바르게 입력해주세요.");
      return;
    }

    contactAPI.createMessage(form).then((res) => {
      if (res.data.linusCode === 1) {
        alert("문의를 전송하였습니다. 답변을 기다려주세요.");
        setForm({
          name: "",
          email: "",
          phone: "",
          wishProgram: "",
          affiliation: "",
          message: "",
        });
        setIsAgree(false);
      }
    });
  };

  // Your component JSX remains the same
  return (
    <>
      {/* hero block */}
      <HeroBlock />
      {/* contact form block */}
      <Container>
        {modalVisible && (
          <div
            className="modal"
            onClick={(e) => {
              e.preventDefault();
              if (
                e.target.className === "contents-container" ||
                e.target.className === "contents" ||
                e.target.className === "title" ||
                e.target.className === "title-text" ||
                e.target.className === "close-container"
              ) {
                return;
              }

              setModalVisible(false);
              document.body.style.overflow = "unset";
            }}
          >
            <div className="contents-container">
              <div className="title">
                <p className="title-text">개인정보 취급 방침</p>
                <div className="close-container">
                  <Image src="/img/close-black.svg" alt="close" />
                </div>
              </div>
              <div className="contents">
                <Terms />
              </div>
            </div>
          </div>
        )}
      </Container>
      <FormContainer className="form-info-container" style={{ marginBottom: "100px" }}>
        <Row>
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <input
                name="name"
                type="text"
                placeholder="이름 (필수)"
                value={form.name}
                onChange={handleChange}
              />
              <input
                name="email"
                type="email"
                placeholder="이메일 (필수)"
                value={form.email}
                onChange={handleChange}
              />
              <input
                name="phone"
                type="phone"
                placeholder="연락처 (필수)"
                value={form.phone}
                onChange={handleChange}
              />
              <input
                name="wishProgram"
                type="text"
                placeholder="수강 희망 프로그램"
                value={form.wishProgram}
                onChange={handleChange}
              />
              <input
                name="affiliation"
                type="text"
                placeholder="소속 (직장 혹은 학교)"
                value={form.affiliation}
                onChange={handleChange}
              />
              <textarea
                name="message"
                placeholder="문의 내용"
                value={form.message}
                onChange={handleChange}
              />
              <div className="form-bottom">
                <div className="form-agree">
                  <Image
                    src={!isAgree ? "/img/icon-checkbox.svg" : "/img/icon-checked.svg"}
                    alt="checkbox"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsAgree(!isAgree);
                    }}
                  />
                  <div className="agree">
                    <span
                      onClick={() => {
                        setModalVisible(true);
                      }}
                    >
                      개인정보 취급 방침에 동의합니다.
                    </span>
                  </div>
                </div>
                <div className="form-submit">
                  <Button
                    type="submit"
                    disabled={
                      !isAgree ||
                      !(
                        form.name.trim().length > 0 &&
                        form.email.trim().length > 0 &&
                        form.phone.trim().length > 0
                      )
                    }
                  >
                    문의 보내기
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Row>
      </FormContainer>
      <UdacityConnectSection />
      <Footer />
    </>
  );
};

export default Contact;

const HeroBlock = () => {
  const HeroStyled = styled.div`
    & > .banner {
      height: 400px;
      background: url(https://www.udacity.com/_next/image?url=%2Fimages%2Fbackgrounds%2Fblue-lines-wide.png&w=3840&q=100)
        no-repeat;
      background-size: cover;
      background-position: center bottom;
      background-color: #171a53;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 768px) {
        height: 200px;
      }
    }

    & > .banner > h3 {
      margin: 0 0 20px;
      font-family: Montserrat;
      font-size: 34px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: -1.36px;
      text-align: center;
      color: #fff;

      @media (max-width: 768px) {
        margin: 0 15px 10px;
        font-family: Montserrat;
        font-size: 28px;
      }
    }

    & > .banner > p {
      margin: 10px 0 0;
      opacity: 0.8;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: -0.64px;
      text-align: center;
      color: #fff;

      @media (max-width: 768px) {
        margin: 10px 0 0;
        opacity: 0.8;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 12px;
      }
    }
  `;

  return (
    <HeroStyled>
      <NavBar />
      <div className="banner">
        <h3 style={{ marginTop: "60px" }}>CONTACT</h3>
        <p>
          {isLangKo() ? "궁금한 점이 있으시다면 문의를 남겨주세요." : " "}
        </p>
      </div>
    </HeroStyled>
  );
};
