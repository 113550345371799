import React from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";

import Sign from "../components/auth/Sign";
import PasswordReset from "../components/auth/PasswordReset";

const StyledAuthPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  min-height: 50vh;
`;

export default function AuthPage() {
  return (
    <StyledAuthPageContainer>
      <Routes>
        <Route path="/sign-in" element={<Sign type="signIn" />} />
        <Route path="/sign-up" element={<Sign type="signUp" />} />
        <Route path="/reset-password" element={<PasswordReset />} />
      </Routes>
    </StyledAuthPageContainer>
  );
}
