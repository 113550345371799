import client from "./client";

export const createMessage = ({
  name,
  email,
  phone,
  wishProgram,
  affiliation,
  message,
}) =>
  client.post(`/service-center/leave-messages/`, {
    name,
    email,
    phone,
    wishProgram,
    affiliation,
    message,
  });
