import styled from "styled-components";

export const StyledLectureListContainer = styled.div`
  .banner {
    display: flex;
    width: 100%;
    min-height: 510px;
    background: url(/img/programs-bg@3x.png) no-repeat;
    background-size: cover;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    & > .text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;

      @media (max-width: 768px) {
        flex: 1.5;
      }

      & > .container {
        margin-top: 0px;
        margin-bottom: 40px;
        margin-left: 30%;
        margin-right: 20px;
        word-break: keep-all;

        @media (max-width: 768px) {
          margin-top: 40px;
          margin-left: 20px;
          margin-right: 20px;
        }

        & > .upper-desc {
          opacity: 0.8;
          font-family: Montserrat;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 4.38;
          letter-spacing: normal;
          color: #2015ff;
          margin: 0;
          padding: 0;
        }

        & > .title {
          font-family: Montserrat;
          font-size: 40px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: -1.6px;
          color: #222;
          margin: 0 0 28px;
          padding: 0;
        }

        & > .desc {
          opacity: 0.8;
          font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.63;
          letter-spacing: -0.64px;
          max-width: 500px;
          word-break: normal;
          color: #555;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .contents-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 80px 20px 140px 20px;

    @media (max-width: 768px) {
      margin: 40px 20px 80px 20px;
    }
  }
`;

export const BannerImage = styled.div`
  flex: 1;
  min-height: 510px;
  background-image: url(${(props) => props.background});
  object-fit: contain;
  background-size: cover;

  @media (max-width: 768px) {
    min-height: 300px;
  }
`;

export const StyledLectureItemContainer = styled.div`
  display: flex;
  max-width: 1200px;
  height: auto;
  width: 100%;
  margin: 12px 20px;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  cursor: pointer;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .left-top {
    display: flex;
    flex-direction: column;
    flex: 3.5;
    margin: 28px;

    @media (max-width: 768px) {
      margin: 28px 28px 0px;
    }

    & > .name {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: -0.96px;
      text-align: left;
      color: #142580;
      margin-bottom: 14px;
      display: flex;
      align-items: center;

      .difficulty {
        background-color: ${(props) => {
          if (props.difficulty === "Beginner") {
            return "paleturquoise";
          }
          if (props.difficulty === "Intermediate") {
            return "#E9CEFF";
          }
          if (props.difficulty === "Advanced") {
            return "#CEE5CD";
          }
        }};
        font-size: 18px;
        margin-left: 10px;
        padding: 3px 7px;
        border-radius: 20px;

        @media (max-width: 768px) {
          margin-top: 15px;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    & > .desc {
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: -0.64px;
      text-align: left;
      color: #777;
      margin-bottom: 14px;

      @media (max-width: 768px) {
        flex: 2;
      }
    }

    & > .price-container {
      display: flex;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      & > .price {
        margin: 0 28px 0 0;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.64px;
        text-align: left;
        color: #aaa;

        & > span {
          margin: 3px 0 2px 10px;
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.63;
          letter-spacing: -0.64px;
          text-align: left;
          color: #2015ff;
        }

        @media (max-width: 768px) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .right-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    margin: 42px 28px 28px 0;

    @media (max-width: 768px) {
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      margin: 0px 0 28px 0;
    }

    .button-container {
      @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }

    button {
      width: 189px;
      height: 68px;
      border-radius: 5px;
      background-color: #2015ff;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.72px;
      text-align: center;
      color: #fff;
      border: none;
      margin-bottom: 26px;
      cursor: pointer;

      @media (max-width: 768px) {
        flex: 1;
        margin: 20px 20px 10px;
      }
    }

    .period {
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.64px;
      text-align: left;
      font-weight: 500;
      color: #555;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 189px;

      & > span {
        font-weight: normal;
        color: #aaa;
        margin-left: 2px;
        margin-top: 7px;
      }

      @media (max-width: 768px) {
        flex: 1;
        margin: 10px 20px 0px;
        display: block;
        width: auto;
      }
    }
  }
`;
