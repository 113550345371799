import styled from "styled-components";

export const StyledInformationListUpperContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }

  .total-count {
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.64px;
    text-align: left;
    color: #222;

    & > span {
      font-family: Montserrat;
      color: #2015ff;
    }

    @media (max-width: 768px) {
      margin-left: 20px;
    }
  }

  .search-sort-container,
  .search-keyword-type-container,
  .search-sort-container-mobile {
    position: relative;
  }

  .search-sort-container {
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .bottom-container {
    @media (max-width: 768px) {
      display: flex;
      width: 100vw;
      align-items: center;
    }
  }

  .search-sort-container-mobile {
    display: none;

    @media (max-width: 768px) {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      padding-right: 10px;
    }
  }

  .search-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  .search-sort,
  .search-keyword-type {
    width: 100px;
    height: 38px;
    padding: 10px;
    padding-right: 0px;
    display: flex;
    align-items: center;
    margin: 0 10px;
    border-radius: 2px;
    border: solid 1px #ddd;
    background-color: #fff;
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.56px;
    text-align: left;
    color: #777;
    cursor: pointer;

    .search-sort-button {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      margin-right: 10px;
      align-items: center;
    }

    p {
      padding: 0;
      margin: 0 10px 0 0;
    }
  }

  .search-sort {
    height: 40px;
  }

  .search-keyword-type {
    width: 100px;
    border: none;
  }

  .search-sort-dropdown {
    width: 110px;
    position: absolute;
    z-index: 100;
    display: ${(props) => {
      if (props.showSearchSortDropdown) {
        return "flex";
      }
      return "none";
    }};
    flex-direction: column;
    border-radius: 2px;
    border: solid 1px #ddd;
    border-top: none;
    background-color: white;
    margin: 0 0 0 10px;
    top: 40px;

    @media (max-width: 768px) {
      top: 40px;
      margin-right: 10px;
    }

    & > p {
      cursor: pointer;
      padding: 10px 10px 10px 13px;
      margin: 0;
      display: flex;
      align-items: center;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.56px;
      color: #777;
      transition: 0.2s ease-in;
    }
    & > p:hover {
      color: #555;
      background-color: #eee;
      font-weight: 500;
      transition: 0.2s ease-in;
    }
  }

  .search-keyword-type-dropdown {
    width: 80px;
    position: absolute;
    z-index: 100;
    display: ${(props) => {
      if (props.showSearchKeywordDropdown) {
        return "flex";
      }
      return "none";
    }};
    flex-direction: column;
    border-radius: 2px;
    border: solid 1px #ddd;
    border-top: none;
    background-color: white;
    margin: 0 0 0 0px;
    top: 40px;
    left: -1px;

    & > p {
      cursor: pointer;
      padding: 10px 10px 10px 13px;
      margin: 0;
      display: flex;
      align-items: center;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.56px;
      color: #777;
      transition: 0.2s ease-in;
    }
    & > p:hover {
      color: #555;
      background-color: #eee;
      font-weight: 500;
      transition: 0.2s ease-in;
    }
  }

  .search-keyword-type {
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 0;
    border-radius: 2px;
    background-color: #fff;
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.56px;
    text-align: left;
    color: #777;

    p {
      padding: 0;
      margin: 0 10px 0 0;
    }

    img {
      cursor: pointer;
    }
  }

  .search-form {
    display: flex;
    align-items: center;
    border-radius: 2px;
    border: solid 1px #ddd;
    background-color: #fff;
    height: 40px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
      width: calc(100vw - 40px);
    }

    & > form {
      display: flex;
      align-items: center;
      height: 40px;

      @media (max-width: 768px) {
        flex: 1;
      }

      & > button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: none;
        border: none;
        cursor: pointer;

        & > img {
          width: 30px;
        }
      }
    }

    input {
      height: 28px;
      margin: 0;
      border: none;
      background-color: rgba(1, 1, 1, 0);

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    input:focus {
      outline: 0;
    }
  }
`;
