import React, { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";

import { Button, Col, Container, Row } from "react-bootstrap";
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";

import * as popUpAPI from "../apis/popup";
import * as lectureAPI from "../apis/lecture";
import { useParams } from "react-router-dom";

import { Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

import ArrowLeft from "../icon/arrow-left-short.svg";
import ArrowRight from "../icon/arrow-right-short.svg";

import {
  CoverMainStyled,
  TextStyled,
  StyledRow,
  StyledCol,
  SchoolCard,
  SchoolImage,
  SchoolText,
  StyledButton,
  ContainerStyled,
  RecommendedSectionContainer,
  Heading,
  ButtonGroup,
  ScrollbarStyled,
  ScrollbarDragStyled,
  StyledRowCarousel,
  StyledColCarousel,
  CardStyled,
  CardBodyStyled,
  CardTitleStyled,
  Stars,
  CardFootStyled,
  ContainerStyledDiff,
  AnimatedBackgroundContainer,
  Section,
  CustomButton,
  StyledPopUpContainer,
} from "./IndexPage.style";

export default function IndexPage() {
  const [popUpData, setPopUpData] = useState(null);
  const [showTopModal, setShowTopModal] = useState(false);
  const [showBottomModal, setShowBottomModal] = useState(false);
  const [showNavTopModal, setShowNavTopModal] = useState(false);
  const [showWelcomeBoxModal, setShowWelcomeBoxModal] = useState(false);

  //parameter 사용
  const paramsKor = useParams();
  const paramsRecomend = useParams();
  const paramsPeriod = useParams();

  //Top AI & Data
  const [recommendedData, setRecommendedData] = useState([]);
  //한글화과정
  const [translateKorData, setTranslateKorData] = useState([]);
  //최신과정
  const [newReleasesData, setNewReleasesData] = useState([]);
  //Learn New Skills in 30 Days
  const [oneMonthData, setOneMonthData] = useState([]);

  const HAS_VISITED_BEFORE_TOP_MODAL = localStorage.getItem("hasVisitedBeforeTopModal");
  const HAS_VISITED_BEFORE_BOTTOM_MODAL = localStorage.getItem("hasVisitedBeforeBottomModal");
  const HAS_VISITED_BEFORE_NAV_TOP_MODAL = localStorage.getItem("hasVisitedBeforeNavTopModal");

  const { t } = useTranslation();

  useEffect(() => {
    popUpAPI.getPopUp().then((res) => {
      if (res.data.linusCode === 1) {
        setPopUpData(res.data.data);
        setShowTopModal(res.data.data.isEnableLeftTop);
        setShowBottomModal(res.data.data.isEnableLeftBottom);
        setShowNavTopModal(res.data.data.isEnableNavTop);
        setShowWelcomeBoxModal(res.data.data.isEnableWelcomeBox);
      }
    });
  }, []);

  // 팝업창 오늘 하루 안보기 -> 시간 비교
  useEffect(() => {
    const handleShowModal = () => {
      if (HAS_VISITED_BEFORE_TOP_MODAL && HAS_VISITED_BEFORE_TOP_MODAL > new Date()) {
        setShowTopModal(false);
        return;
      } else {
        if (showTopModal) {
          setShowTopModal(true);
        }
      }
    };

    window.setTimeout(handleShowModal, 0);
  }, [HAS_VISITED_BEFORE_TOP_MODAL, showTopModal]);

  useEffect(() => {
    const handleShowModal = () => {
      if (HAS_VISITED_BEFORE_BOTTOM_MODAL && HAS_VISITED_BEFORE_BOTTOM_MODAL > new Date()) {
        setShowBottomModal(false);
        return;
      } else {
        if (showBottomModal) {
          setShowBottomModal(true);
        }
      }
    };

    window.setTimeout(handleShowModal, 0);
  }, [HAS_VISITED_BEFORE_BOTTOM_MODAL, showBottomModal]);

  useEffect(() => {
    const handleShowModal = () => {
      if (HAS_VISITED_BEFORE_NAV_TOP_MODAL && HAS_VISITED_BEFORE_NAV_TOP_MODAL > new Date()) {
        setShowNavTopModal(false);
        return;
      } else {
        if (showNavTopModal) {
          setShowNavTopModal(true);
        }
      }
    };

    window.setTimeout(handleShowModal, 0);
  }, [HAS_VISITED_BEFORE_NAV_TOP_MODAL, showNavTopModal]);

  useEffect(() => {
    paramsKor.name = "한국화";
    lectureAPI.getLectureByName({ name: paramsKor.name }).then((res) => {
      if (res.data.linusCode === 1) {
        setTranslateKorData(res.data.data);
      }
    });

    //ai 카테고리
    paramsRecomend.categoryId = "1";
    lectureAPI.getLecturesByCategory({ categoryId: paramsRecomend.categoryId }).then((res) => {
      if (res.data.linusCode === 1) {
        setRecommendedData(res.data.data);
      }
    });

    lectureAPI.getRecentLectures({}).then((res) => {
      if (res.data.linusCode === 1) {
        setNewReleasesData(res.data.data);
      }
    });

    paramsPeriod.period = "1개월";
    lectureAPI.getLecturesByEstimatedPeriod({ period: paramsPeriod.period }).then((res) => {
      if (res.data.linusCode === 1) {
        setOneMonthData(res.data.data);
      }
    });
  }, [paramsKor, paramsRecomend, paramsPeriod]);

  return (
    <>
      {(showTopModal || showBottomModal || showNavTopModal || showWelcomeBoxModal) && (
        <PopUp
          popUpData={popUpData}
          setPopUpData={setPopUpData}
          showTopModal={showTopModal}
          setShowTopModal={setShowTopModal}
          showBottomModal={showBottomModal}
          setShowBottomModal={setShowBottomModal}
          showNavTopModal={showNavTopModal}
          setShowNavTopModal={setShowNavTopModal}
          showWelcomeBoxModal={showWelcomeBoxModal}
          setShowWelcomeBoxModal={setShowWelcomeBoxModal}
        />
      )}

      {/* intro */}
      <Container fluid className="position-relative p-0" style={{ height: "1900px" }}>
        {/* intro top */}
        <IntroTop t={t} />
        <div
          style={{
            backgroundColor: "#F6F6F6",
            paddingBottom: "5rem",
            marginTop: "",
            paddingTop: "0",
            backgroundImage: "url(/img/main/main-background.webp)",
          }}
        >
          <IntroCarousel
            items={translateKorData}
            headingText={t("indexPage_intro_carousel_title")}
          />
          <IntroCarousel items={recommendedData} headingText="Top AI & Data Skills" />
          <IntroCarousel items={newReleasesData} headingText="New Releases" />
          <IntroCarousel items={oneMonthData} headingText="Learn New Skills in 30 Days" />
        </div>

        <UdacityDifferenceSection t={t} />
        <UdacityForBusinessComponent t={t} />
        {/* contact block */}
        <UdacityConnectSection t={t} />
        {/* footer block */}
        <Footer />
      </Container>
    </>
  );
}

// intro Top
const IntroTop = ({ setIsHideChar, isHideChar, t }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const schoolsData = [
    {
      href: "/programs/6/lectures",
      src: "/img/main/school-data-sci-one.svg",
      text: "Data Science",
    },
    {
      href: "/programs/7/lectures",
      src: "/img/main/school-programming-one.svg",
      text: "Programming & Development",
    },
    {
      href: "/programs/1/lectures",
      src: "/img/main/school-ai-one.svg",
      text: "Artificial Intelligence",
    },
    {
      href: "/programs/3/lectures",
      src: "/img/main/school-business-one.svg",
      text: "Business",
    },
    {
      href: "/programs/2/lectures",
      src: "/img/main/school-autonomous-sys-one.svg",
      text: "Autonomous Systems",
    },
    {
      href: "/programs/8/lectures",
      src: "/img/main/school-product-mgt-one.svg",
      text: "Product Management",
    },
    {
      href: "/programs/4/lectures",
      src: "/img/main/school-cloud-computing-one.svg",
      text: "Cloud Computing",
    },
  ];

  return (
    <CoverMainStyled>
      <NavBar />
      <div className="text-container">
        <TextStyled>Learn new skills. Prove your potential.</TextStyled>
      </div>
      <div className="row-container">
        {isMobile ? (
          <Swiper
            modules={[Scrollbar]}
            spaceBetween={10}
            slidesPerView={3}
            scrollbar={{ draggable: true }}
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              430: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
            }}
          >
            {schoolsData.map((school, index) => (
              <SwiperSlide key={index}>
                <StyledCol>
                  <SchoolCard>
                    <a href={school.href} style={{ textDecoration: "none", color: "inherit" }}>
                      <SchoolImage variant="top" src={school.src} />
                      <SchoolText>{school.text}</SchoolText>
                    </a>
                  </SchoolCard>
                </StyledCol>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <StyledRow className="flex-nowrap">
            {schoolsData.map((school, index) => (
              <StyledCol key={index}>
                <SchoolCard>
                  <a href={school.href} style={{ textDecoration: "none", color: "inherit" }}>
                    <SchoolImage variant="top" src={school.src} />
                    <SchoolText>{school.text}</SchoolText>
                  </a>
                </SchoolCard>
              </StyledCol>
            ))}
          </StyledRow>
        )}
      </div>
    </CoverMainStyled>
  );
};

const IntroCarousel = ({ items, headingText = "Recommended For You" }) => {
  const swiperRef = useRef(null); // Swiper 인스턴스를 저장할 ref
  const [scrollPosition, setScrollPosition] = useState(0);

  const updateScrollbarPosition = () => {
    if (!swiperRef.current) return;
    const progress = swiperRef.current.progress;
    const scrollbarContainerWidth = 256;
    const scrollbarDragWidth = 100;
    const maxScroll = scrollbarContainerWidth - scrollbarDragWidth;
    const position = progress * maxScroll;
    setScrollPosition(position);
  };

  useEffect(() => {
    return () => {
      if (swiperRef.current) {
        swiperRef.current.off("slideChange", updateScrollbarPosition);
        swiperRef.current.off("progress", updateScrollbarPosition);
      }
    };
  }, [swiperRef]);

  const handlePrevClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
      setTimeout(updateScrollbarPosition, 100); // Swiper의 상태 업데이트가 완료될 시간을 고려하여 지연시간을 설정
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
      setTimeout(updateScrollbarPosition, 100);
    }
  };
  // 화살표 버튼 컴포넌트에 SVG 이미지 사용
  const ArrowLeftButton = ({ onClick }) => (
    <StyledButton
      onClick={onClick}
      className="swiper-button-prev-custom"
      style={{ marginRight: "1rem", marginLeft: "1rem" }}
    >
      <img src={ArrowLeft} alt="Left arrow" />
    </StyledButton>
  );

  const ArrowRightButton = ({ onClick }) => (
    <StyledButton onClick={onClick} className="swiper-button-next-custom">
      <img src={ArrowRight} alt="Right arrow" />
    </StyledButton>
  );
  return (
    <ContainerStyled>
      <RecommendedSectionContainer>
        <Heading>{headingText}</Heading>
        <ButtonGroup>
          <ScrollbarStyled>
            <ScrollbarDragStyled scrollPosition={scrollPosition} />
          </ScrollbarStyled>
          <ArrowLeftButton onClick={handlePrevClick} />
          <ArrowRightButton onClick={handleNextClick} />
        </ButtonGroup>
      </RecommendedSectionContainer>
      <StyledRowCarousel>
        <Swiper
          modules={[Navigation, Scrollbar]}
          spaceBetween={20}
          slidesPerView={6}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            swiper.on("slideChange", updateScrollbarPosition);
            swiper.on("progress", updateScrollbarPosition);
          }}
          navigation={{
            nextEl: ".swiper-button-next-custom",
            prevEl: ".swiper-button-prev-custom",
          }}
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 30,
            },
            1440: {
              slidesPerView: 6,
              spaceBetween: 30,
            },
          }}
          scrollbar={{
            draggable: true,
          }}
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <StyledColCarousel>
                <CardStyled bgImage={item.thumbnail}>
                  <a
                    href={`/programs/lecture/${item.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <CardBodyStyled>
                      <CardTitleStyled>{item.name}</CardTitleStyled>
                      <div
                        style={{
                          marginTop: "0.5rem",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "baseline",
                        }}
                      >
                        {" "}
                        <Stars rating={parseFloat(item.rates ?? 4.4)} />
                        <div style={{ fontSize: "0.7rem" }}>({item.reviews ?? 700})</div>
                      </div>
                      <CardFootStyled>
                        {item.estimatedPeriod}, {item.difficulty}
                      </CardFootStyled>
                    </CardBodyStyled>
                  </a>
                </CardStyled>
              </StyledColCarousel>
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledRowCarousel>
    </ContainerStyled>
  );
};

const UdacityDifferenceSection = ({ t }) => {
  return (
    <ContainerStyledDiff>
      <Row className="align-items-center">
        <Col lg={6} className="video-col">
          <video
            poster="/videos/workspaces-poster.webp"
            autoPlay
            loop
            muted
            playsInline
            className="video"
            style={{ height: "100%", width: "100%" }}
          >
            <source src="/videos/workspaces.webm" type="video/webm" />
            <source src="/videos/workspaces-h265.mp4" type="video/mp4" />
            <source src="/videos/workspaces-h264.mp4" type="video/mp4" />
          </video>
        </Col>
        <Col lg={6} className="text-col" style={{ paddingLeft: "3rem" }}>
          <h2>The Udacity Difference</h2>
          <div>
            <p>
              <strong>{t("indexPage_UdacityDifferenceSection_subtitle1")}</strong>
            </p>
            <p>{t("indexPage_UdacityDifferenceSection_desc1")}</p>
            <p>
              <strong>{t("indexPage_UdacityDifferenceSection_subtitle2")}</strong>
            </p>
            <p>{t("indexPage_UdacityDifferenceSection_desc2")}</p>
          </div>
        </Col>
      </Row>
    </ContainerStyledDiff>
  );
};

const UdacityConnectSection = ({ t }) => {
  return (
    <AnimatedBackgroundContainer fluid className="my-auto">
      <Row className="justify-content-center">
        <Col md={12} className="text-center">
          {" "}
          <p>Get Started Today</p>
          <h2>Learn. Grow. Succeed.</h2>
          <Button
            variant="success"
            href="https://udacitypartner.com/auth/sign-in"
            className="start-free-button"
            style={{
              color: "black",
              backgroundColor: "#02C5A1",
              marginTop: "1rem",
              fontSize: "1rem",
            }}
            size="lg"
          >
            {t("start-free")}
            <span className="button-icon">
              <img src={ArrowRight} alt="Right arrow" />
            </span>
          </Button>
        </Col>
      </Row>
    </AnimatedBackgroundContainer>
  );
};

const UdacityForBusinessComponent = ({ t }) => {
  return (
    <Container>
      <Section>
        <Row className="justify-content-between align-items-center">
          <Col>
            <h2 style={{ fontSize: "2.5rem", fontWeight: "600" }}>Udacity for Business</h2>
            <p>{t("indexPage_UdacityForBusiness_subtitle")}</p>
            <img
              alt=""
              loading="lazy"
              width="542"
              height="344"
              src="/img/main/main-contact-us.webp"
              style={{ width: "100%", height: "auto" }}
              className="d-block d-lg-none"
            />
            <ul>
              <li>{t("indexPage_UdacityForBusiness_list1")}</li>
              <li>{t("indexPage_UdacityForBusiness_list2")}</li>
              <li>{t("indexPage_UdacityForBusiness_list3")}</li>
            </ul>
            <div style={{ marginBottom: "5px" }}>
              <Row>
                <Col sm={12} md={5}>
                  <CustomButton href="/about" size="lg" style={{ backgroundColor: "#2016ff" }}>
                    Learn More{" "}
                    <img
                      src={ArrowRight}
                      alt="Right arrow"
                      style={{ filter: "brightness(0) invert(1)" }}
                    />
                  </CustomButton>
                </Col>
                <Col sm={12} md={5}>
                  <CustomButton
                    href="/contact"
                    size="lg"
                    style={{
                      color: "#2016ff",
                      border: "2px solid #2016ff",
                      backgroundColor: "transparent",
                    }}
                  >
                    Contact Us{" "}
                    <img
                      src={ArrowRight}
                      alt="Right arrow"
                      style={{
                        filter:
                          "invert(9%) sepia(100%) saturate(7463%) hue-rotate(251deg) brightness(99%) contrast(119%)",
                      }}
                    />
                  </CustomButton>
                </Col>
              </Row>{" "}
            </div>
          </Col>
          <Col md={12} lg={6} className="d-none d-lg-block">
            <img
              alt=""
              loading="lazy"
              width="542"
              height="344"
              src="/img/main/main-contact-us.webp"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Col>
        </Row>
      </Section>
    </Container>
  );
};

//intro Popup
const PopUp = ({
  popUpData,
  setPopUpData,
  showTopModal,
  setShowTopModal,
  showBottomModal,
  setShowBottomModal,
  showNavTopModal,
  setShowNavTopModal,
  showWelcomeBoxModal,
  setShowWelcomeBoxModal,
}) => {
  return (
    (showTopModal || showBottomModal || showNavTopModal || showWelcomeBoxModal) && (
      <StyledPopUpContainer
        onClick={(e) => {
          setShowTopModal(false);
          setShowBottomModal(false);
          setShowNavTopModal(false);
          setShowWelcomeBoxModal(false);
        }}
      >
        {popUpData.isEnableLeftTop && showTopModal && (
          <div
            className="main-html leftTopHtml"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <button
              className="close"
              onClick={(e) => {
                e.preventDefault();
                setShowTopModal(false);
              }}
            >
              <img src="/img/close-black.svg" alt="close" />
            </button>
            <div
              className="pop-up-contents"
              dangerouslySetInnerHTML={{ __html: popUpData?.leftTopHtml }}
            />
            <div
              className="close-today"
              onClick={(e) => {
                e.preventDefault();
                let expires = new Date();
                expires = expires.setHours(expires.getHours() + 24);
                localStorage.setItem("hasVisitedBeforeTopModal", expires);
                setShowTopModal(false);
              }}
            >
              <div>오늘 하루 보지 않기</div>
              <img src="/img/close.svg" alt="close" />
            </div>
          </div>
        )}
        {popUpData.isEnableLeftBottom && showBottomModal && (
          <div
            className="main-html leftBottomHtml"
            style={{ zIndex: 999991 }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <button
              className="close"
              onClick={(e) => {
                e.preventDefault();
                setShowBottomModal(false);
              }}
            >
              <img src="/img/close-black.svg" alt="close" />
            </button>
            <div
              className="pop-up-contents"
              dangerouslySetInnerHTML={{ __html: popUpData?.leftBottomHtml }}
            />
            <div
              className="close-today"
              onClick={(e) => {
                e.preventDefault();
                let expires = new Date();
                expires = expires.setHours(expires.getHours() + 24);
                localStorage.setItem("hasVisitedBeforeBottomModal", expires);
                setShowBottomModal(false);
              }}
            >
              <div>오늘 하루 보지 않기</div>
              <img src="/img/close.svg" alt="close" />
            </div>
          </div>
        )}
        {popUpData.isEnableNavTop && showNavTopModal && (
          <a href="https://udacitypartner.com/about">
            <div className="main-html navTopHtml">
              <div className="container position-relative">
                <button
                  className="close"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowTopModal(false);
                  }}
                >
                  <img src="/img/close-black.svg" alt="close" />
                </button>
                <div
                  className="pop-up-contents"
                  dangerouslySetInnerHTML={{ __html: popUpData?.navTopHtml }}
                />
              </div>
            </div>
          </a>
        )}
        {popUpData.isEnableWelcomeBox && showWelcomeBoxModal && (
          <a href="https://udacitypartner.com/about">
            <div className="main-html welcomeBoxHtml">
              <div className="welcome-box-logo">
                <img src="/img/udacitypartner_logo_white.png" alt="logo" width={120} />
              </div>
              <div
                className="pop-up-contents"
                dangerouslySetInnerHTML={{ __html: popUpData?.welcomeBoxHtml }}
              />
              <div className="welcome-box-arrow">
                <img src="/img/next.svg" alt="arrow" />
              </div>
            </div>
          </a>
        )}
      </StyledPopUpContainer>
    )
  );
};
