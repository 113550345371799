import client from "./client";

const handleError = (error) => {
  console.log("API call failed:", error);
  throw error;
}

export const getOrders = () => client.get(`/order/orders/`);

export const getOrder = ({ id }) => client.get(`/order/orders/${id}/`);

export const createOrder = ({
  status,
  name,
  phone,
  udacityEmail,
  orderMessage,
  paymentMethod,
  orderDetail,
}) =>
  client.post(`/order/orders/`, {
    status,
    name,
    phone,
    udacityEmail,
    orderMessage,
    paymentMethod,
    orderDetail,
  });

export const editOrder = ({
  id,
  status,
  name,
  phone,
  udacityEmail,
  orderMessage,
  paymentMethod,
  orderDetail,
  impUid,
  merchantUid,
}) =>
  client.put(`/order/orders/${id}/`, {
    status,
    name,
    phone,
    udacityEmail,
    orderMessage,
    paymentMethod,
    orderDetail,
    impUid,
    merchantUid,
  });

export const deleteOrder = ({ id }) => client.delete(`/order/orders/${id}/`);

export const getShoppingCarts = () => client.get(`/order/shopping-baskets/`).catch(handleError);

export const getShoppingCart = ({ id }) =>
  client.get(`/order/shopping-baskets/${id}/`);

export const createShoppingCart = ({ lectureCount, lecture }) =>
  client.post(`/order/shopping-baskets/`, {
    lectureCount,
    lecture,
  });

export const editShoppingCart = ({ id, lectureCount, lecture }) =>
  client.put(`/order/shopping-baskets/${id}/`, {
    lectureCount,
    lecture,
  });

export const deleteShoppingCart = ({ id }) =>
  client.delete(`/order/shopping-baskets/${id}/`);
