import React, { useEffect, useState } from "react";
import * as informationAPI from "../../apis/information";
import { StyledNewsItem, StyledNewsListContainer } from "./News.styles";
import { toStringByFormatting } from "../../utils/dateFormatter";
import { useNavigate } from "react-router-dom";
import { extractContent } from "../../utils/textExtractor";
import { FlexRowCenterContainer } from "../common/Container";
import Pagination from "react-js-pagination";
import { useInput } from "../../utils/useInput";
import { StyledInformationListUpperContainer } from "./Information.styles";
import { useTranslation } from "react-i18next";

const SuccessCaseItem = ({
  authorName,
  content,
  createdDate,
  id,
  recommendsCount,
  thumbnail,
  title,
  viewsCount,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <StyledNewsItem
      onClick={(e) => {
        e.preventDefault();
        navigate(`/information/success-case/${id}`);
      }}
    >
      <div className="news-info">
        <div className="new-info-text">
          <p className="title">{title}</p>
          <p className="date">{toStringByFormatting(new Date(createdDate))}</p>
          <p className="success-case">{extractContent(content)}</p>
        </div>
        <div className="tirivials">
          <p className="author">{authorName}</p>
          <div className="divider" />
          <div className="divider" />
          <p className="recommend">
            {t("recommend")} <span> {recommendsCount}</span>
          </p>
          <div className="divider" />
          <p className="view">
            {t("views")} <span> {viewsCount}</span>
          </p>
        </div>
      </div>
    </StyledNewsItem>
  );
};

export default function SuccessCaseList() {
  const { t, i18n } = useTranslation();

  const orderings = [
    { label: t("newest"), name: "-createdDate" },
    { label: t("popular"), name: "recommendCount" },
    { label: t("look-up"), name: "-viewsCount" },
    { label: t("update-time"), name: "-updatedDate" },
  ];

  const keywords = [
    { label: t("all"), name: "searchAll" },
    { label: t("title"), name: "searchTitle" },
    { label: t("contents"), name: "searchContent" },
    { label: t("writer"), name: "searchAuthorName" },
  ];

  const [successCases, setSuccessCases] = useState([]);
  const [current, setCurrent] = useState(
    Number(localStorage.getItem("success-case-page")) &&
      Number(localStorage.getItem("success-case-page")) > 0
      ? Number(localStorage.getItem("success-case-page"))
      : 1,
  );
  const [total, setTotal] = useState(0);
  const [showSearchSortDropdown, setShowSearchSortDropdown] = useState(false);
  const [searchSort, setSearchSort] = useState(orderings[0]);
  const [showSearchKeywordDropdown, setShowSearchKeywordDropdown] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState(keywords[0]);
  const [keyword, setKeyword] = useInput("");

  const onChangePage = (page) => {
    localStorage.setItem("success-case-page", page);
    setCurrent(page);
  };

  useEffect(() => {
    setSearchSort(orderings[0]);
    setSearchKeyword(keywords[0]);
  }, [i18n.language]);

  useEffect(() => {
    localStorage.setItem("success-case-page", 1);
  }, []);

  useEffect(() => {
    informationAPI
      .getSuccessCases({
        page: current,
        pageSize: 10,
        ordering: searchSort.name,
      })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setSuccessCases(res.data.data);
          setTotal(res.data.page.count);
        }
      });
  }, []);

  useEffect(() => {
    informationAPI
      .getSuccessCases({
        page: current,
        pageSize: 10,
        ordering: searchSort.name,
      })
      .then((res) => {
        if (res.data.linusCode === 1) {
          setSuccessCases(res.data.data);
          setTotal(res.data.page.count);
        }
      });
  }, [current, searchSort.name]);

  const onSearch = (e) => {
    e.preventDefault();

    if (!keyword || keyword.trim().length === 0) {
      alert("검색어를 입력해주세요.");
      return;
    }

    const request = { page: current, pageSize: 10, ordering: searchSort.name };
    request[searchKeyword.name] = keyword;
    informationAPI.getSuccessCases(request).then((res) => {
      if (res.data.linusCode === 1) {
        setTotal(res.data.page.count);
        setSuccessCases(res.data.data);
      }
    });
  };

  return (
    <StyledNewsListContainer>
      <StyledInformationListUpperContainer
        showSearchSortDropdown={showSearchSortDropdown}
        showSearchKeywordDropdown={showSearchKeywordDropdown}
      >
        <div className="bottom-container">
          <div className="total-count">
            {t("all")} <span>{total}</span>
          </div>
          <div className="search-sort-container-mobile">
            <div
              className="search-sort"
              onClick={() => {
                setShowSearchSortDropdown(!showSearchSortDropdown);
              }}
            >
              <p>{searchSort.label}</p>
              <div className="search-sort-button">
                <img src="/img/arrow-down.svg" alt="arrow-down" />
              </div>
            </div>
            <div className="search-sort-dropdown">
              {orderings.map((i) => (
                <p
                  key={i.name}
                  onClick={() => {
                    setSearchSort(i);
                    setShowSearchSortDropdown(false);
                  }}
                >
                  {i.label}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="search-container">
          <div className="search-sort-container">
            <div
              className="search-sort"
              onClick={() => {
                setShowSearchSortDropdown(!showSearchSortDropdown);
              }}
            >
              <p>{searchSort.label}</p>
              <div className="search-sort-button">
                <img src="/img/arrow-down.svg" alt="arrow-down" />
              </div>
            </div>
            <div className="search-sort-dropdown">
              {orderings.map((i) => (
                <p
                  key={i.name}
                  onClick={() => {
                    setSearchSort(i);
                    setShowSearchSortDropdown(false);
                  }}
                >
                  {i.label}
                </p>
              ))}
            </div>
          </div>
          <div className="search-form">
            <div className="search-keyword-type-container">
              <div
                className="search-keyword-type"
                onClick={() => {
                  setShowSearchKeywordDropdown(!showSearchKeywordDropdown);
                }}
              >
                <p>{searchKeyword.label}</p>
                <div className="search-sort-button">
                  <img src="/img/arrow-down.svg" alt="arrow-down" />
                </div>
              </div>
              <div className="search-keyword-type-dropdown">
                {keywords.map((i) => (
                  <p
                    key={i.name}
                    onClick={() => {
                      setSearchKeyword(i);
                      setShowSearchKeywordDropdown(false);
                    }}
                  >
                    {i.label}
                  </p>
                ))}
              </div>
            </div>
            <form className="form" onSubmit={onSearch}>
              <input className="search-input" type={"text"} onChange={setKeyword} />
              <button type="submit">
                <img src="/img/search@3x.png" alt="search" />
              </button>
            </form>
          </div>
        </div>
      </StyledInformationListUpperContainer>
      <div className="blocks">
        {successCases.map((i) => (
          <SuccessCaseItem key={i.id} {...i} />
        ))}
      </div>
      <FlexRowCenterContainer style={{ width: "100%" }}>
        <Pagination
          activePage={current}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={window.innerWidth <= 768 ? 5 : 10}
          onChange={onChangePage}
          firstPageText={<img src="/img/first.svg" alt="first" />}
          lastPageText={<img src="/img/icon-page-last.svg" alt="last" />}
          prevPageText={<img src="/img/prev.svg" alt="prev" />}
          nextPageText={<img src="/img/next.svg" alt="next" />}
          itemClass="pagination-item"
          itemClassFirst="pagination-first"
          itemClassPrev="pagination-prev"
          itemClassNext="pagination-next"
          itemClassLast="pagination-last"
          activeClass="pagination-active"
          activeLinkClass="pagination-active-link"
          linkClass="pagination-link"
          linkClassFirst="pagination-link-first"
          linkClassPrev="pagination-link-prev"
          linkClassNext="pagination-link-next"
          linkClassLast="pagination-link-last"
        />
      </FlexRowCenterContainer>
    </StyledNewsListContainer>
  );
}
