import React from "react";
import { StyledSignContainer } from "./Sign.styles";
import { useInput } from "../../utils/useInput";
import * as accountAPI from "../../apis/accounts";

export default function PasswordReset() {
  const [email, setEmail] = useInput("");

  const onSubmit = (e) => {
    e.preventDefault();
    accountAPI.resetPassword({ email }).then((res) => {
      alert(res?.data?.data?.msg);
    });
  };

  return (
    <StyledSignContainer style={{ marginTop: "80px" }}>
      <div
        className="contents"
        style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}
      >
        <h3>Reset your password</h3>
        <p className="desc">
          Please provide the email address you used
          <br />
          when you signed up for your Udacity account.
        </p>
        <form onSubmit={onSubmit}>
          <input type={"email"} placeholder="이메일 주소" onChange={setEmail} />
          <button type={"submit"} style={{ padding: "22px 160px 22px 162px" }}>
            Send email
          </button>
        </form>
      </div>
    </StyledSignContainer>
  );
}
