import React, { useState, useEffect } from "react";
import {
  StyledContentsContainer,
  StyledMenuItem,
  StyledMyMenuContainer,
  StyledMyPageContainer,
} from "./MyPage.styles";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Order from "../components/my/Order";
import Info from "../components/my/Info";
import Password from "../components/my/Password";

import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { shoppingItemState } from "../states/atoms";
import NavBar from "../components/common/NavBar";

export default function MyPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [shoppingItem, setShoppingItem] = useRecoilState(shoppingItemState);

  const { t } = useTranslation();

  const items = [
    { title: t("my-orders"), path: "/my/order" },
    { title: t("edit-my-info"), path: "/my/info" },
    { title: t("chagne-pw"), path: "/my/password" },
  ];

  const [selected, setSelected] = useState(
    items.filter((i) => location.pathname.includes(i.path))[0]
  );

  useEffect(() => {
    setSelected(items.filter((i) => location.pathname.includes(i.path))[0]);
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("id");
  };

  return (
    <>
    <NavBar/>
    <StyledMyPageContainer hasBG={selected.title !== t("my-orders")}>
      <StyledMyMenuContainer>
        {items.map((i) => (
          <StyledMenuItem
            key={i.title}
            className="item"
            onClick={(e) => {
              e.preventDefault();
              navigate(i.path);
            }}
            selected={selected.title === i.title}
          >
            {i.title}
          </StyledMenuItem>
        ))}
        <StyledMenuItem
          key={t("logout")}
          className="item"
          onClick={(e) => {
            if (window.confirm(t("log-out-confirm"))) {
              handleLogout();
              setShoppingItem([]);
              navigate("/");
            }
          }}
          selected={selected.title === t("logout")}
        >
          {t("logout")}
        </StyledMenuItem>
      </StyledMyMenuContainer>
      <StyledContentsContainer>
        <Routes>
          <Route path="/order" element={<Order />} />
          <Route path="/info" element={<Info />} />
          <Route path="/password" element={<Password />} />
        </Routes>
      </StyledContentsContainer>
    </StyledMyPageContainer>
    </>
  );
}
