import { Col, Container, Dropdown, Image, Row } from "react-bootstrap";
import styled from "styled-components";

export default function Footer() {
  return (
    <>
      <FooterMenuWrapper fluid>
        <Container>
          <FooterMenuRow>
            <Col>
              <a href="/">
                <Image
                  src="/img/udacitypartner_logo_black.png"
                  className="logo-image"
                  alt="udacity_partner"
                  style={{ height: "27px" }}
                />
              </a>
            </Col>
            <Col className="footer-items">
              <a href="/programs" style={{ textDecoration: "none", color: "black" }}>
                Learn
              </a>
            </Col>
            <Col className="footer-items">
              <a href="/about" style={{ textDecoration: "none", color: "black" }}>
                About Us
              </a>
            </Col>
            <Col className="footer-items">
              <a href="/Why" style={{ textDecoration: "none", color: "black" }}>
                Why Udacity
              </a>
            </Col>
            <Col className="footer-items">
              <a href="/information/news" style={{ textDecoration: "none", color: "black" }}>
                News
              </a>
            </Col>
            <Col className="d-none d-lg-block"></Col>
            <Col className="d-none d-lg-block">
              <StyledDropdown drop="up" className="text-end">
                <StyledDropdown.Toggle id="dropdown-basic" className="familysite-btn">
                  Family Site
                </StyledDropdown.Toggle>
                <Dropdown.Menu
                  style={{ backgroundColor: "#D9D9D9", borderRadius: 0, minWidth: "142px" }}
                >
                  <Dropdown.Item
                    href="https://vavv.net"
                    style={{ color: "#81898F", paddingLeft: "10px" }}
                  >
                    VAVV
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="https://hrdpuroom-index.vavv.net"
                    style={{ color: "#81898F", paddingLeft: "10px" }}
                  >
                    푸름인재개발원
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="https://safety.vavv.net"
                    style={{ color: "#81898F", paddingLeft: "10px" }}
                  >
                    안전보건교육센터
                  </Dropdown.Item>
                </Dropdown.Menu>
              </StyledDropdown>
            </Col>
          </FooterMenuRow>
        </Container>
      </FooterMenuWrapper>
      <Container fluid className="bg-white d-flex align-items-end">
        <Container className="pt-3">
          <Row className="Footer-content pb-3 g-4" style={{ fontSize: "0.9rem" }}>
            <Col lg={7}>
              <FooterContent>
                <p className="my-1">(주)푸름인재개발원</p>
                <p className="my-1">대표자: 김미숙 | 개인정보보호책임자: 장두환</p>
                <p className="my-1">
                  (13646) 경기도 성남시 수정구 위례광장로 19, 12층 1201(창곡동, 아이페리온)
                </p>
                <span className="me-2 ">사업자등록번호: 638-86-01526</span>
                <span className="me-2 ">통신판매 : 제 2023-성남수정-0363호</span>
                <p className="my-1">
                  사이트 내 민원, 환불 등에 관한 책임을 ‘푸름인재개발원’에서 지고있음을
                  알려드립니다.
                </p>
              </FooterContent>
            </Col>
            <FooterRightCol>
              <Row className="row-cols-lg-1">
                <CScenterCol className="d-none d-lg-block">
                  <p>고객센터</p>
                  <p className="call-number">02-1899-6629</p>
                  <p>평일 09:00 ~ 17:00</p>
                  <p>점심 13:00 ~ 14:00</p>
                  <p>주말 및 공휴일 휴무</p>
                </CScenterCol>
                <CScenterCol className="d-lg-none">
                  <Row>
                    <Col>
                      <p>고객센터</p>
                      <p className="call-number">02-1899-6629</p>
                      <p>평일 09:00 ~ 17:00</p>
                      <p>점심 13:00 ~ 14:00</p>
                      <p>주말 및 공휴일 휴무</p>
                    </Col>
                    <Col style={{ textAlign: "end", alignSelf: "end" }}>
                      <StyledDropdown drop="up">
                        <StyledDropdown.Toggle id="dropdown-basic" className="familysite-btn">
                          Family Site
                        </StyledDropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            backgroundColor: "#D9D9D9",
                            borderRadius: 0,
                            minWidth: "142px",
                          }}
                        >
                          <Dropdown.Item
                            href="https://vavv.net"
                            style={{ color: "#81898F", paddingLeft: "10px" }}
                          >
                            VAVV
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="https://hrdpuroom-index.vavv.net"
                            style={{ color: "#81898F", paddingLeft: "10px" }}
                          >
                            푸름인재개발원
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="https://safety.vavv.net"
                            style={{ color: "#81898F", paddingLeft: "10px" }}
                          >
                            안전보건교육센터
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </StyledDropdown>
                      <SnsIconWrapper>
                        <a href="https://youtube.com/@udacitykoreapartner4116" className="px-3">
                          <Image
                            src="https://www.udacity.com/images/social/facebook.svg"
                            alt="go to youtube"
                            style={{ height: "25px" }}
                          />
                        </a>
                        <a href="https://www.instagram.com/udacity_partner/" className="pe-3">
                          <Image
                            src="https://www.udacity.com/images/social/instagram.svg"
                            alt="go to instagram"
                            style={{ height: "25px" }}
                          />
                        </a>
                        <a href="https://www.linkedin.com/in/udacitykoreapartner/">
                          <Image
                            src="https://www.udacity.com/images/social/linkedin.svg"
                            alt="go to linkedin"
                            style={{ height: "25px" }}
                          />
                        </a>
                      </SnsIconWrapper>
                    </Col>
                  </Row>
                </CScenterCol>
              </Row>
            </FooterRightCol>
          </Row>
          <hr style={{ border: "0.5px solid" }} />
          <Row style={{ marginBottom: "10px" }}>
            <CopyrightCol className="col-12 col-lg-8">
              <p style={{ paddingTop: "7px", color: "rgb(141 141 141)" }}>
                Copyright 2020 Udacity 한국 공식 파트너 푸름인재개발원 All Rights Reserved
              </p>
            </CopyrightCol>
            <Col className="d-none d-lg-block" style={{ textAlign: "end" }}>
              <a href="https://youtube.com/@udacitykoreapartner4116" className="px-3">
                <Image
                  src="https://www.udacity.com/images/social/facebook.svg"
                  alt="go to youtube"
                  style={{ height: "25px" }}
                />
              </a>
              <a href="https://www.instagram.com/udacity_partner/" className="pe-3">
                <Image
                  src="https://www.udacity.com/images/social/instagram.svg"
                  alt="go to instagram"
                  style={{ height: "25px" }}
                />
              </a>
              <a href="https://www.linkedin.com/in/udacitykoreapartner/">
                <Image
                  src="https://www.udacity.com/images/social/linkedin.svg"
                  alt="go to linkedin"
                  style={{ height: "25px" }}
                />
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

const FooterMenuWrapper = styled(Container)`
  margin-top: 2rem;
  @media (min-width: 789px) {
    margin-top: 3rem;
  }
`;

const FooterMenuRow = styled(Row)`
  white-space: nowrap;
  align-items: center;
  justify-content: space-between;

  .footer-items {
    margin: 0 25px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: fit-content;
  }

  @media (max-width: 991px) {
    .footer-items {
      margin: 0;
      font-size: 0.8rem;
    }
    .col:nth-child(6) {
      margin: 0 !important;
    }
  }

  .col:last-child {
    margin-left: 50px;
  }

  .familysite-btn {
    color: #81898f;
    background-color: white;
    border-radius: 0;
    border-color: #81898f;
    width: 142px;
    text-align: start;
    font-size: 0.8rem;
    &::after {
      margin-left: 25px;
    }
  }

  @media (max-width: 768px) {
    .col:first-child {
      width: 100%;
      flex: 0 0 auto;
      padding-bottom: 20px;
    }
  }
`;

const FooterRightCol = styled(Col)`
  text-align: right;
  @media (max-width: 991px) {
    text-align: start;
  }
`;

const CScenterCol = styled(Col)`
  p {
    margin: 0;
  }
  p:first-child {
    margin-bottom: 10px;
  }
  .call-number {
    color: #2015ff;
    font-size: 1.25rem;
    font-weight: bold;
  }
  .familysite-btn {
    color: #81898f;
    background-color: white;
    border-radius: 0;
    border-color: #81898f;
    width: 142px;
    text-align: start;
    font-size: 0.8rem;
    &::after {
      margin-left: 25px;
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  font-size: 0.9rem; /* Adjust font size for the dropdown toggle */
`;

const FooterContent = styled.div`
  @media (max-width: 1200px) {
    font-size: 0.8rem;
  }
`;

const CopyrightCol = styled(Col)`
  font-size: 0.9rem;
  @media (max-width: 1200px) {
    p {
      font-size: 0.8rem;
    }
  }
`;

const SnsIconWrapper = styled.div`
  padding-top: 20px;
  padding-right: 1px;
`;
