import React, { useState, useEffect } from "react";
import {
  StyledMyOrderPageContainer,
  StyledOrderDetailContainer,
  StyledOrderItemContainer,
} from "./Order.styles";
import { toStringByFormatting } from "../../utils/dateFormatter";
import * as orderAPI from "../../apis/order";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import isLangKo from "../../utils/isLangKo";
import { UsButton } from "../../pages/AboutPage";

const OrderDetail = ({
  id,
  thumbnail,
  name,
  descriptionSummary,
  descriptionSummaryEnglish,
  purunetPriceKRW,
  purunetPriceUSD,
}) => {
  const navigate = useNavigate();

  return (
    <StyledOrderDetailContainer
      onClick={() => {
        navigate(`/programs/lecture/${id}`);
      }}
    >
      <div className="thumbnail">
        <img src={thumbnail} alt="thumbnail" />
      </div>
      <div className="text">
        <div className="title">{name}</div>
        <div className="desc">{isLangKo() ? descriptionSummary : descriptionSummaryEnglish}</div>
      </div>
      {/** 
      <div className="price-quantity">
        <div className="price">
          <div>
            {isLangKo() ? "₩" : "$"} {isLangKo() ? purunetPriceKRW : purunetPriceUSD}
          </div>
        </div>
      </div>
    */}
    </StyledOrderDetailContainer>
  );
};

export const OrderItem = ({
  id,
  status,
  name,
  phone,
  udacityEmail,
  orderMessage,
  paymentMethod,
  orderDetail,
  orderDate,
  isMyPage = false,
}) => {
  const { t } = useTranslation();

  return (
    <StyledOrderItemContainer>
      <div className="date">{toStringByFormatting(new Date(orderDate))}</div>
      {orderDetail?.map((i) => (
        <React.Fragment>
          {isMyPage && <OrderDetail key={i?.lecture?.id} {...i?.lecture} />}
          {!isMyPage && <OrderDetail key={i.id} {...i} />}
        </React.Fragment>
      ))}
      {/** 
      <div className="total-price">
        <div>
          = {t("total-price")}
          <span>
            {isLangKo() ? "₩" : "$"}
            {isMyPage &&
              isLangKo() &&
              orderDetail
                ?.map((i) => i?.lecture?.purunetPriceKRW * 1)
                ?.reduce((prev, next) => prev + next)}
            {isMyPage &&
              !isLangKo() &&
              orderDetail
                ?.map((i) => i?.lecture?.purunetPriceUSD * 1)
                ?.reduce((prev, next) => prev + next)}
            {!isMyPage &&
              isLangKo() &&
              orderDetail
                ?.map((i) => i?.purunetPriceKRW * 1)
                ?.reduce((prev, next) => prev + next)}
            {!isMyPage &&
              !isLangKo() &&
              orderDetail
                ?.map((i) => i?.purunetPriceUSD * 1)
                ?.reduce((prev, next) => prev + next)}
          </span>
        </div>
      </div>
*/}
    </StyledOrderItemContainer>
  );
};

export default function Order() {
  const [orders, setOrders] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    orderAPI.getOrders().then((res) => {
      if (res.data.linusCode === 1) {
        setOrders(res.data.data);
      }
    });
  }, []);

  return (
    <StyledMyOrderPageContainer>
      <div className="page-title-container">
        <h3>{t("my-orders")}</h3>
        <hr />
      </div>
      {orders.length > 0 && orders.map((i) => <OrderItem key={i.id} {...i} isMyPage />)}
      {orders.length === 0 && <div className="empty">{t("no-orders")}</div>}
      <UsButton
        href="https://learn.udacity.com/my-programs"
        style={{ backgroundColor: "#6491FC" }}
      >
        {t("aboutPage_button_connect")}
        <span style={{ marginLeft: "8px" }}>
          <svg
            viewBox="0 0 32 32"
            focusable="false"
            aria-hidden="true"
            style={{ width: "24px", height: "24px" }}
          >
            <path
              d="M16.293 7.293a1 1 0 011.414 0l8 8a1 1 0 010 1.414l-8 8a1 1 0 01-1.414-1.414L22.585 17H7a1 1 0 01-.993-.883L6 16a1 1 0 011-1h15.585l-6.292-6.293a1 1 0 01-.083-1.32z"
              fill="currentColor"
            ></path>
          </svg>
        </span>
      </UsButton>
    </StyledMyOrderPageContainer>
  );
}
