import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate, Route, Routes } from "react-router-dom";
import NoticeList from "../components/information/NoticeList";
import NewsList from "../components/information/NewsList";
import VideoList from "../components/information/VideoList";
import SuccessCaseList from "../components/information/SuccessCaseList";
import PostDetail from "../components/information/PostDetail";
import isLangKo from "../utils/isLangKo";
import { useTranslation } from "react-i18next";
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";

export const StyledInformationPageContainer = styled.div`
  & > .banner {
    width: 100%;
    height: 400px;

    background: url(https://www.udacity.com/_next/image?url=%2Fimages%2Fbackgrounds%2Fblue-lines-wide.png&w=3840&q=100)
      no-repeat;
    background-size: cover;
    background-position: center bottom;
    background-color: #171a53;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      height: 200px;
    }
  }

  & > .banner > h3 {
    margin: 0 0 20px;
    font-family: Montserrat;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: -1.36px;
    text-align: center;
    color: #fff;

    @media (max-width: 768px) {
      margin: 0 15px 10px;
      font-family: Montserrat;
      font-size: 28px;
    }
  }

  & > .banner > p {
    margin: 10px 0 0;
    opacity: 0.8;
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -0.64px;
    text-align: center;
    color: #fff;

    @media (max-width: 768px) {
      margin: 10px 0 0;
      opacity: 0.8;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 12px;
    }
  }
`;

const StyledInformationMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

const StyledMenuItem = styled.button`
  width: 110px;
  height: 60px;
  padding: 20px 0 0;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: -0.64px;
  text-align: center;
  color: ${(props) => {
    if (props.selected) {
      return "#2015ff";
    }
    return "#333";
  }};
  border: none;
  border-bottom: ${(props) => {
    if (props.selected) {
      return "2px solid #2015ff";
    }
    return "none";
  }};
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-bottom: 18px;

  @media (max-width: 768px) {
    width: auto;
    font-family: Montserrat;
    font-size: 14px;
    padding: 14px;
  }
`;

export const StyledContentsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
`;

export default function InformationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const items = [
    { title: "Notice", path: "/information/notice" },
    { title: "News", path: "/information/news" },
    { title: "Video", path: "/information/video" },
    { title: "Success Case", path: "/information/success-case" },
  ];

  const [selected, setSelected] = useState(
    items.filter((i) => location.pathname.includes(i.path))[0],
  );

  useEffect(() => {
    setSelected(items.filter((i) => location.pathname.includes(i.path))[0]);
  }, [location.pathname]);

  return (
    <>
      <NavBar />
      <StyledInformationPageContainer>
        <div className="banner">
          <h3 style={{ marginTop: "60px" }}>Information</h3>
          <p>
            {isLangKo() ? "UDACITY KOREA PARTNER의 다양한 소식을 빠르게 만나볼 수 있습니다." : ""}
          </p>
        </div>
        <StyledInformationMenuContainer>
          {items.map((i) => (
            <StyledMenuItem
              key={i.title}
              className="item"
              onClick={(e) => {
                e.preventDefault();
                navigate(i.path);
              }}
              selected={selected.title === i.title}
            >
              {i.title}
            </StyledMenuItem>
          ))}
        </StyledInformationMenuContainer>
        <StyledContentsContainer>
          <Routes>
            <Route path="/notice/*" element={<NoticeList />} />
            <Route path="/notice/:id" element={<PostDetail type="notice" />} />
            <Route path="/news/*" element={<NewsList />} />
            <Route path="/news/:id" element={<PostDetail type="news" />} />
            <Route path="/video/*" element={<VideoList />} />
            <Route path="/video/:id" element={<VideoList />} />
            <Route path="/success-case/*" element={<SuccessCaseList />} />
            <Route path="/success-case/:id" element={<PostDetail type="successCase" />} />
          </Routes>
        </StyledContentsContainer>
        <Footer />
      </StyledInformationPageContainer>
    </>
  );
}
