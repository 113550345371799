import { Container, Row, Col, Image, Button, Card } from "react-bootstrap";
import styled from "styled-components";
import {
  StyledSection,
  Heading,
  Subheading,
  Paragraph,
  ButtonGroup,
  PartnerSection,
  PartnerHeading,
  PartnerLogo,
  PartnerCol,
  PartnerDiv,
  PartnerRow,
  WFStyledSection,
  WFStyledHeading,
  WFStyledHeading1,
  WFStyledHeading2,
  WFStyledSubHeading1,
  WFStyledParagraph,
  WFStyledParagraph1,
  WFStyledImage,
  WFStyledArticleDiv,
  WFStyledArticle,
  UDStyledSection,
  UDStyledHeading,
  UDStyledArticle,
  UDStyledList,
  UDStyledListItem,
  UDStyledImageContainer,
  UDStyledImage,
  UDStyledSubHeading1,
  UDStyledSubParagraph1,
  UDStyledLargeImage,
  UDStyledArticleTitle,
  UDStyledListContainer,
  BackgroundContainer,
  PlanHeading1,
  PlanHeading2,
  BUContainer,
  BUHeader,
  BUHeader1,
  BUHeaderContainer,
  SchoolCard,
  SchoolCardTitle,
  SchoolCardLink,
  SchoolCardSub,
  SchoolCardStyleImage,
  SchoolSectionHeader,
  SchoolSectionContainer,
  MensionSectionImage,
  JobReadyTalentSectionContainer,
  UdacityMensionSectionContainer,
  CatalogContainer,
} from "./BusinessPage.styles";
import { AnimatedBackgroundContainer } from "./IndexPage.style";
import NavBar from "../components/common/NavBar";
import Footer from "../components/common/Footer";
import { useNavigate } from "react-router-dom";
import ArrowRight from "../icon/arrow-right-short.svg";
import { useTranslation } from "react-i18next";

export default function Business() {
  const { t } = useTranslation();
  return (
    <Container fluid style={{ padding: 0 }}>
      <NavBar />
      <BusinessTop t={t} />
      <BusinessPartner t={t} />
      <WorkforceCompetitiveSection t={t} />
      <UdacityDifferenceSection t={t} />
      <UdacityPlanSection t={t} />
      <BenefitUdacity t={t} />
      <SchoolSections t={t} />
      <JobReadyTalentSection t={t} />
      <UdacityMensionSection t={t} />
      <UdacityConnectSection t={t} />
      <Footer />
    </Container>
  );
}

const BusinessTop = ({ t }) => {
  const navigate = useNavigate();
  return (
    <StyledSection>
      <BannerBox>
        <Container
          fluid
          className="flex business-bg-img"
          style={{
            color: "#fff",
            backgroundColor: "#171a53",
            height: "560px",
            paddingTop: "150px",
          }}
        >
          <Container className="text-center" style={{ marginBottom: "50px" }}>
            <div className="display-6 mb-4" style={{ lineHeight: "1", textAlign: "left", fontWeight: "normal" }}>
              <Subheading>{t("BusinessTop_Subheading")}</Subheading>
              <Heading>{t("BusinessTop_Heading1")}</Heading>
              <Heading>{t("BusinessTop_Heading2")}</Heading>
              <Paragraph>{t("BusinessTop_Paragraph")}</Paragraph>
            </div>
            <div style={{ textAlign: "left" }}>
              <UsButton href="/contact">
                문의하기
                <span style={{ marginLeft: "8px" }}>
                  <svg
                    viewBox="0 0 32 32"
                    focusable="false"
                    aria-hidden="true"
                    style={{ width: "24px", height: "24px" }}
                  >
                    <path
                      d="M16.293 7.293a1 1 0 011.414 0l8 8a1 1 0 010 1.414l-8 8a1 1 0 01-1.414-1.414L22.585 17H7a1 1 0 01-.993-.883L6 16a1 1 0 011-1h15.585l-6.292-6.293a1 1 0 01-.083-1.32z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </UsButton>
            </div>
          </Container>
        </Container>
      </BannerBox>
    </StyledSection>
  );
};

const BannerBox = styled.div`
  .multi-bg-box {
    background-image: url("/img/about-back.svg");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

const UsButton = styled(Button)`
  background-color: #00c5a1;
  margin-top: 20px;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  ustify-content: flex-start;
  color: black;
  align-items: center;
  &:hover {
    background-color: #047a65;
    border-color: #047a65;
    color: #ffffff;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  }
`;
const MainImage = styled.div`
    @media (max-width: 991px) {
      display: none;
    }
  }
`;
const BusinessPartner = ({ t }) => {
  const partners = [
    {
      name: "Vodafone",
      src: "/img/business/logo_vodafone.svg",
      width: 131,
      height: 33,
    },
    {
      name: "Leidos",
      src: "/img/business/logo_leidos.svg",
      width: 104,
      height: 25,
    },
    {
      name: "Accenture",
      src: "/img/business/logo_accenture.svg",
      width: 115,
      height: 31,
    },
    {
      name: "shell",
      src: "/img/business/logo_shell.svg",
      width: 112,
      height: 40,
    },
    {
      name: "toyota",
      src: "/img/business/logo_toyota.svg",
      width: 108,
      height: 19,
    },
    {
      name: "bnp",
      src: "/img/business/logo_bnp.svg",
      width: 180,
      height: 40,
    },
    {
      name: "stc",
      src: "/img/business/logo_stc.svg",
      width: 54,
      height: 28,
    },
    {
      name: "cognizant",
      src: "/img/business/logo_cognizant.svg",
      width: 112,
      height: 24,
    },
  ];
  return (
    <PartnerSection>
      <Container>
        <Row className="align-items-center">
          <PartnerCol xs={12} md={4} className="text-center mb-md-0 mb-4">
            <PartnerHeading>{t("BusinessPartner_PartnerHeading")}</PartnerHeading>
          </PartnerCol>
          <Col xs={12} md={8}>
            <PartnerRow className="justify-content-center">
              {partners.map((partner) => (
                <PartnerDiv key={partner.name}>
                  <PartnerLogo
                    src={partner.src}
                    alt={partner.name}
                    width={partner.width}
                    height={partner.height}
                  />
                </PartnerDiv>
              ))}
            </PartnerRow>
          </Col>
        </Row>
      </Container>
    </PartnerSection>
  );
};

const contentStats = [
  {
    heading: "15,000+",
    paragraphs: [
      "WorkforceCompetitiveSection_ContentStats1",
      "WorkforceCompetitiveSection_ContentStats1_1",
    ],
  },
  {
    heading: "700+",
    paragraphs: [
      "WorkforceCompetitiveSection_ContentStats2",
      "WorkforceCompetitiveSection_ContentStats2_1",
    ],
  },
  {
    heading: "$2.9M",
    paragraphs: [
      "WorkforceCompetitiveSection_ContentStats3",
      "WorkforceCompetitiveSection_ContentStats3_1",
    ],
  },
  {
    heading: "<3 months",
    paragraphs: [
      "WorkforceCompetitiveSection_ContentStats4",
      "WorkforceCompetitiveSection_ContentStats4_1",
    ],
  },
];

const WorkforceCompetitiveSection = ({ t }) => {
  return (
    <WFStyledSection>
      <Container>
        <Row className="align-items-center">
          <Col md={6} className="d-md-none" style={{ marginBottom: "20px" }}>
            <WFStyledHeading1>
              {t("WorkforceCompetitiveSection_Heading1")}
              <br />
              {t("WorkforceCompetitiveSection_Heading1_1")}
              <br />
              <WFStyledSubHeading1>
                {t("WorkforceCompetitiveSection_Heading1_2")}
              </WFStyledSubHeading1>
            </WFStyledHeading1>
            <WFStyledParagraph>{t("WorkforceCompetitiveSection_Paragraph")}</WFStyledParagraph>
          </Col>
          <Col md={6}>
            <WFStyledImage />
          </Col>
          <Col md={6} className="d-none d-md-block">
            <WFStyledHeading1>
              {t("WorkforceCompetitiveSection_Heading1")}
              <br />
              {t("WorkforceCompetitiveSection_Heading1_1")}
              <br />
              <WFStyledSubHeading1>
                {t("WorkforceCompetitiveSection_Heading1_2")}
              </WFStyledSubHeading1>
            </WFStyledHeading1>
            <WFStyledParagraph>{t("WorkforceCompetitiveSection_Paragraph")}</WFStyledParagraph>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="wfs-head-row">
          <Col>
            <WFStyledHeading style={{ marginBottom: "16px" }}>
              {t("WorkforceCompetitiveSection_Heading2")}
            </WFStyledHeading>
            <WFStyledParagraph1>{t("WorkforceCompetitiveSection_Paragraph1")}</WFStyledParagraph1>
            <WFStyledParagraph1>
              {t("WorkforceCompetitiveSection_Paragraph1_1")}
            </WFStyledParagraph1>
          </Col>
        </Row>
        <WFStyledArticle>
          {contentStats.map(({ heading, paragraphs }) => (
            <WFStyledArticleDiv key={heading}>
              <WFStyledHeading2>{heading}</WFStyledHeading2>
              {paragraphs.map((paragraphKey) => (
                <WFStyledParagraph1 key={paragraphKey}>{t(paragraphKey)}</WFStyledParagraph1>
              ))}
            </WFStyledArticleDiv>
          ))}
        </WFStyledArticle>
      </Container>
    </WFStyledSection>
  );
};

const ListItemWithIcon = ({ children }) => (
  <UDStyledListItem>
    <UDStyledListContainer>
      <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 6c5.523 0 10 4.477 10 10s-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6zm0 2a8 8 0 100 16 8 8 0 000-16zm3.143 3.486a1 1 0 011.714 1.028l-4.8 8a1 1 0 01-1.564.193l-3.2-3.2a1 1 0 011.414-1.414l2.294 2.294z" />
      </svg>
    </UDStyledListContainer>
    <UDStyledListContainer className="list-item-text">{children}</UDStyledListContainer>
  </UDStyledListItem>
);

const UdacityDifferenceSection = ({ t }) => {
  return (
    <UDStyledSection>
      <Container>
        <UDStyledHeading>{t("UdacityDifferenceSection_Heading")}</UDStyledHeading>
        <Row className="align-items-center article-first-block">
          <Col md={6}>
            <UDStyledArticle>
              <UDStyledArticleTitle>
                {t("UdacityDifferenceSection_ArticleTitles1")}
              </UDStyledArticleTitle>
              <UDStyledList>
                <ListItemWithIcon>{t("UdacityDifferenceSection_ListItems1")}</ListItemWithIcon>
                <ListItemWithIcon>{t("UdacityDifferenceSection_ListItems2")}</ListItemWithIcon>
                <ListItemWithIcon>{t("UdacityDifferenceSection_ListItems3")}</ListItemWithIcon>
              </UDStyledList>
              <UDStyledImageContainer>
                <div>
                  <UDStyledImage src="/img/business/logo_bnp_color.svg" />
                  <Row style={{ marginTop: "10px" }}>
                    <Col>
                      <UDStyledSubHeading1>#1M</UDStyledSubHeading1>
                      <UDStyledSubParagraph1>
                        {t("UdacityDifferenceSection_Paragraph1_1")}
                      </UDStyledSubParagraph1>
                    </Col>
                    <Col>
                      <UDStyledSubHeading1>#1M</UDStyledSubHeading1>
                      <UDStyledSubParagraph1>
                        {t("UdacityDifferenceSection_Paragraph1_2")}
                      </UDStyledSubParagraph1>
                    </Col>
                  </Row>
                </div>
              </UDStyledImageContainer>
            </UDStyledArticle>
          </Col>
          <Col md={6}>
            <UDStyledLargeImage src="/img/business/ud_large1.webp" />
          </Col>
        </Row>
        <Row className="align-items-center article-block">
          <Col md={6}>
            <UDStyledLargeImage src="/img/business/ud_large2.webp" />
          </Col>
          <Col md={6}>
            <UDStyledArticle>
              <UDStyledArticleTitle>
                {t("UdacityDifferenceSection_ArticleTitles2")}
              </UDStyledArticleTitle>
              <UDStyledList>
                <ListItemWithIcon>{t("UdacityDifferenceSection_ListItems4")}</ListItemWithIcon>
                <ListItemWithIcon>{t("UdacityDifferenceSection_ListItems5")}</ListItemWithIcon>
                <ListItemWithIcon>{t("UdacityDifferenceSection_ListItems6")}</ListItemWithIcon>
              </UDStyledList>
              <UDStyledImageContainer>
                <div>
                  <UDStyledImage src="/img/business/logo_shell_color.svg" />
                  <Row style={{ marginTop: "10px" }}>
                    <Col>
                      <UDStyledSubHeading1>#2M</UDStyledSubHeading1>
                      <UDStyledSubParagraph1>
                        {t("UdacityDifferenceSection_Paragraph2_1")}
                      </UDStyledSubParagraph1>
                    </Col>
                    <Col>
                      <UDStyledSubHeading1>86%</UDStyledSubHeading1>
                      <UDStyledSubParagraph1>
                        {t("UdacityDifferenceSection_Paragraph2_2")}
                      </UDStyledSubParagraph1>
                    </Col>
                  </Row>
                </div>
              </UDStyledImageContainer>
            </UDStyledArticle>
          </Col>
        </Row>
        <Row className="align-items-center article-block">
          <Col md={6}>
            <UDStyledArticle>
              <UDStyledArticleTitle>
                {t("UdacityDifferenceSection_ArticleTitles3")}
              </UDStyledArticleTitle>
              <UDStyledList>
                <ListItemWithIcon>{t("UdacityDifferenceSection_ListItems7")}</ListItemWithIcon>
                <ListItemWithIcon>{t("UdacityDifferenceSection_ListItems8")}</ListItemWithIcon>
                <ListItemWithIcon>{t("UdacityDifferenceSection_ListItems9")}</ListItemWithIcon>
              </UDStyledList>
              <UDStyledImageContainer>
                <div>
                  <UDStyledImage src="/img/business/logo_cognizant_color.svg" />
                  <Row style={{ marginTop: "10px" }}>
                    <Col>
                      <UDStyledSubHeading1>$603K</UDStyledSubHeading1>
                      <UDStyledSubParagraph1>
                        {t("UdacityDifferenceSection_Paragraph3_1")}
                      </UDStyledSubParagraph1>
                    </Col>
                    <Col>
                      <UDStyledSubHeading1>715%</UDStyledSubHeading1>
                      <UDStyledSubParagraph1>
                        {t("UdacityDifferenceSection_Paragraph3_2")}
                      </UDStyledSubParagraph1>
                    </Col>
                  </Row>
                </div>
              </UDStyledImageContainer>
            </UDStyledArticle>
          </Col>
          <Col md={6}>
            <UDStyledLargeImage src="/img/business/ud_large3.webp" />
          </Col>
        </Row>
        <Row className="align-items-center article-block">
          <Col md={6}>
            <UDStyledLargeImage src="/img/business/ud_large4.webp" />
          </Col>
          <Col md={6}>
            <UDStyledArticle>
              <UDStyledArticleTitle>
                {t("UdacityDifferenceSection_ArticleTitles4")}
              </UDStyledArticleTitle>
              <UDStyledList>
                <ListItemWithIcon>{t("UdacityDifferenceSection_ListItems10")}</ListItemWithIcon>
                <ListItemWithIcon>{t("UdacityDifferenceSection_ListItems11")}</ListItemWithIcon>
                <ListItemWithIcon>{t("UdacityDifferenceSection_ListItems12")}</ListItemWithIcon>
              </UDStyledList>
              <UDStyledImageContainer>
                <div>
                  <UDStyledImage src="/img/business/logo_eon_color.svg" />
                  <Row style={{ marginTop: "10px" }}>
                    <Col>
                      <UDStyledSubHeading1>140%</UDStyledSubHeading1>
                      <UDStyledSubParagraph1>
                        {t("UdacityDifferenceSection_Paragraph4_1")}
                      </UDStyledSubParagraph1>
                    </Col>
                    <Col>
                      <UDStyledSubHeading1>$463M</UDStyledSubHeading1>
                      <UDStyledSubParagraph1>
                        {t("UdacityDifferenceSection_Paragraph4_2")}
                      </UDStyledSubParagraph1>
                    </Col>
                  </Row>
                </div>
              </UDStyledImageContainer>
            </UDStyledArticle>
          </Col>
        </Row>
      </Container>
    </UDStyledSection>
  );
};

const UdacityPlanSection = ({ t }) => {
  return (
    <BackgroundContainer
      fluid
      className="my-auto"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Container className="wrapper">
        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <Col className="text-col">
            <Row className="row-cols-1 row-cols-lg-2">
              <Col style={{ maxWidth: "100px", paddingRight: 0 }}>
                <img
                  alt=""
                  loading="lazy"
                  width="64"
                  height="64"
                  src="/img/business/email-email-one.svg"
                />
              </Col>
              <Col>
                <PlanHeading1>{t("UdacityPlanSection_PlanHeading1")}</PlanHeading1>
                <PlanHeading2>{t("UdacityPlanSection_PlanHeading2")}</PlanHeading2>
              </Col>
            </Row>
          </Col>
          <Col className="btn-col" style={{ alignSelf: "center", textAlign: "end" }}>
            <Button variant="success" href="/contact" className="start-free-button">
              {t("UdacityPlanSection_Button")}
              <span className="button-icon">
                <svg viewBox="0 0 32 32" focusable="false" aria-hidden="true">
                  <path
                    d="M16.293 7.293a1 1 0 011.414 0l8 8a1 1 0 010 1.414l-8 8a1 1 0 01-1.414-1.414L22.585 17H7a1 1 0 01-.993-.883L6 16a1 1 0 011-1h15.585l-6.292-6.293a1 1 0 01-.083-1.32z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </Button>
          </Col>
        </div>
      </Container>
    </BackgroundContainer>
  );
};

const BenefitUdacity = ({ t }) => {
  return (
    <BUContainer fluid>
      <Container>
        <Row>
          <Col md={8}>
            <BUHeaderContainer>
              <BUHeader1>{t("BenefitUdacity_Heading1")}</BUHeader1>
            </BUHeaderContainer>
            <BUHeader>{t("BenefitUdacity_Heading2")}</BUHeader>
            <div>
              <BenefitsList t={t} />
            </div>
          </Col>
          <Col md={4} style={{ textAlign: "center" }}>
            <Image
              src="/img/business/benefit_udacity.webp"
              style={{ overflow: "hidden", width: "90%", height: "90%", objectFit: "contain" }}
            />
          </Col>
        </Row>
      </Container>
    </BUContainer>
  );
};

const benefits = [
  {
    imgSrc: "/img/business/money-dollar-one.svg",
    textKey: "BenefitUdacity_Benefits1",
  },
  {
    imgSrc: "/img/business/chart-two-two.svg",
    textKey: "BenefitUdacity_Benefits2",
  },
  {
    imgSrc: "/img/business/chart-bar-up-arrow-one.svg",
    textKey: "BenefitUdacity_Benefits3",
  },
  {
    imgSrc: "/img/business/hand-thumbs-up-one.svg",
    textKey: "BenefitUdacity_Benefits4",
  },
];

const BenefitsList = ({ t }) => (
  <Row style={{ marginTop: "50px" }}>
    {benefits.map(({ imgSrc, textKey }) => (
      <Col md={6} key={textKey}>
        <div className="d-flex flex-column">
          <Image src={imgSrc} width={64} height={64} style={{ marginBottom: "7px" }} />
          <p>{t(textKey)}</p>
        </div>
      </Col>
    ))}
  </Row>
);

const SchoolSections = ({ t }) => {
  const schools = [
    {
      href: "/programs/1/lectures",
      src: "/img/business/school_ai.webp",
      title: "Artificial Intelligence",
    },
    {
      href: "/programs/2/lectures",
      src: "/img/business/school_as.webp",
      title: "Autonomous Systems",
    },
    {
      href: "/programs/3/lectures",
      src: "/img/business/school_bs.webp",
      title: "Business",
    },
    {
      href: "/programs/4/lectures",
      src: "/img/business/school_cc.webp",
      title: "Cloud Computing",
    },
    {
      href: "/programs/5/lectures",
      src: "/img/business/school_cb.webp",
      title: "Cybersecurity",
    },
    {
      href: "/programs/6/lectures",
      src: "/img/business/school_ds.webp",
      title: "Data Science",
    },
    {
      href: "/programs/7/lectures",
      src: "/img/business/school_pd.webp",
      title: "Programming",
    },
    {
      href: "/programs/8/lectures",
      src: "/img/business/school_pm.webp",
      title: "Product Management",
    },
    {
      href: "/programs/25/lectures",
      src: "/img/business/school_el.webp",
      title: "한국화",
    },
  ];

  return (
    <CatalogContainer>
      <div style={{ marginBottom: "50px" }}>
        <SchoolSectionContainer>
          <SchoolSectionHeader>Explore our</SchoolSectionHeader>
          <SchoolSectionHeader style={{ color: "#2015FF" }}>catalog</SchoolSectionHeader>
        </SchoolSectionContainer>
        <SchoolSectionContainer>
          <p>{t("SchoolSections_SubText")}</p>
        </SchoolSectionContainer>
      </div>
      <Row className="g-4" style={{ textAlign: "-webkit-center" }}>
        {schools.map((school, idx) => (
          <Col md={4} key={idx}>
            <SchoolCard>
              <SchoolCardStyleImage src={school.src} alt={school.title} />
              <SchoolCardSub style={{ borderRadius: "0 0 10px 20px", textAlign: "left" }}>
                <SchoolCardTitle>{school.title}</SchoolCardTitle>
                <SchoolCardLink variant="primary" href={school.href}>
                  Learn more
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    fill="currentColor"
                    class="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                    />
                  </svg>
                </SchoolCardLink>
              </SchoolCardSub>
            </SchoolCard>
          </Col>
        ))}
      </Row>
    </CatalogContainer>
  );
};

const JobReadyTalentSection = ({ t }) => {
  const features = [
    {
      imgSrc: "/img/business/paper-checkmark-two.svg",
      textKey: "JobReadyTalentSection_Features1",
    },
    {
      imgSrc: "/img/business/checkmark-medal-two.svg",
      textKey: "JobReadyTalentSection_Features2",
    },
    {
      imgSrc: "/img/business/tools-tools-two.svg",
      textKey: "JobReadyTalentSection_Features3",
    },
    {
      imgSrc: "/img/business/location-network-location-one.svg",
      textKey: "JobReadyTalentSection_Features4",
    },
  ];

  return (
    <JobReadyTalentSectionContainer style={{ marginTop: "100px" }}>
      <div className="my-4 d-flex justify-content-center">
        <div className="job-ready-title">Job-ready talent. Superior outcomes.</div>
      </div>
      <Row>
        {features.map((feature, index) => (
          <Col md={6} lg={3} className="mb-3" key={index}>
            <Row>
              <div className="img-col">
                <Image src={feature.imgSrc} alt="" width={64} height={64} className="mb-2" />
              </div>
              <div className="text-col">
                <p>{t(feature.textKey)}</p>
              </div>
            </Row>
          </Col>
        ))}
      </Row>
    </JobReadyTalentSectionContainer>
  );
};

const UdacityMensionSection = ({ t }) => {
  return (
    <UdacityMensionSectionContainer fluid style={{ backgroundColor: "#080C41" }}>
      <Container style={{ backgroundColor: "#FFFFFF", borderRadius: "5px", padding: "46px 16px" }}>
        <Row className="text-center">
          <Col md={12} className="my-2">
            <Image src="/img/business/double_quotation.svg" style={{ marginBottom: "24px" }} />
          </Col>
          <Col md={12}>
            <div>
              <div className="quote-main text-center">{t("UdacityMensionSection_Quote1")}</div>
              <div className="quote-sub px-4">{t("UdacityMensionSection_Quote2")}</div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container style={{ padding: "0", marginTop: "30px" }}>
        <div style={{ backgroundColor: "#080C41" }}>
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center justify-content-start">
              <Image
                src="/img/business/udacity_mension_woman.webp"
                alt=""
                width={90}
                height={90}
              />
              <div className="name-wrapper" style={{ color: "#FFFFFF" }}>
                <div style={{ fontWeight: 400, fontSize: "1.75rem" }}>Cristina Rynning</div>
                <p className="mb-0">VP Global Expert Development, Telenor Group</p>
              </div>
            </div>
            <MensionSectionImage
              src="/img/business/telenor_group.svg"
              alt="Telenor"
              width={126}
              height={50}
            />
          </div>
        </div>
      </Container>
    </UdacityMensionSectionContainer>
  );
};

const UdacityConnectSection = ({ t }) => {
  const navigate = useNavigate();
  return (
    <AnimatedBackgroundContainer fluid className="my-auto">
      <Row className="justify-content-center">
        <Col md={12} className="text-center">
          {" "}
          <h2>{t("UdacityConnectSection_Heading")}</h2>
          <ButtonGroup className="mt-3 d-flex justify-content-center">
            <button
              className="contact"
              onClick={(e) => {
                e.preventDefault();
                navigate("/contact");
              }}
            >
              <div>Contact Us</div>
              <svg viewBox="0 0 32 32" focusable="false" aria-hidden="true">
                <path
                  d="M16.293 7.293a1 1 0 011.414 0l8 8a1 1 0 010 1.414l-8 8a1 1 0 01-1.414-1.414L22.585 17H7a1 1 0 01-.993-.883L6 16a1 1 0 011-1h15.585l-6.292-6.293a1 1 0 01-.083-1.32z"
                  fill="currentColor"
                ></path>
              </svg>
            </button>
            {/* <button
                className="explore"
                onClick={(e) => {
                  e.preventDefault();
                  alert("test");
                }}
              >
                <div>Explore Our Plans</div>
              </button> */}
          </ButtonGroup>
        </Col>
      </Row>
    </AnimatedBackgroundContainer>
  );
};
