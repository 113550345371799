import React, { useState } from "react";
import { StyledSignContainer, StyledSignTab } from "./Sign.styles";
import { Link, useNavigate } from "react-router-dom";
import { useInput } from "../../utils/useInput";

import * as accountAPI from "../../apis/accounts";

import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { apiWrapper, getKakaoToken } from "../../apis/login";
import KaKaoLogin from "react-kakao-login";
import { KAKAO_CLIENT_ID } from "../../constants/login";
import Terms from "../common/Terms";

import { useTranslation } from "react-i18next";
import isLangKo from "../../utils/isLangKo";

export default function Sign({ type }) {
  const { t } = useTranslation();

  const routes = [
    ["FN", t("purumnet")],
    ["HR", t("herald")],
    ["SN", t("internet-search")],
    ["OO", "Outside of Korea"],
  ];
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [route, setRoute] = useState(routes[0]);
  const [name, setName] = useInput("");
  const [email, setEmail] = useInput("");
  const [password, setPassword] = useInput("");
  const [passwordCheck, setPasswordCheck] = useInput("");
  const [phone, setPhone] = useInput("");

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const { access_token } = tokenResponse;

      accountAPI.loginWithGoogle({ accessToken: access_token }).then((res) => {
        if (res?.data?.linusCode === 1) {
          localStorage.setItem(
            "accessToken",
            res?.data?.data?.tokens?.accessToken
          );
          localStorage.setItem("id", res?.data?.data?.user?.id);
          if (
            !res?.data?.data?.user?.username &&
            !res?.data?.data?.user?.phoneNumber
          ) {
            navigate("/my/info");

            return;
          }

          if (!res?.data?.data?.user?.username) {
            navigate("/my/info");

            return;
          }

          if (!res?.data?.data?.user?.phoneNumber) {
            navigate("/my/info");

            return;
          }

          navigate("/");
        } else if (res?.data?.linusCode === -2) {
          alert("회원정보가 존재하지 않습니다.");
        } else {
          alert(res?.data?.data?.msg);
        }
      });
    },
  });

  const onSuccessKakaoLogin = function (e) {
    const { access_token } = e.response;
    apiWrapper(async () => {
      const { status } = await getKakaoToken(access_token);
      if (status === true) {
        accountAPI.loginWithKakao({ accessToken: access_token }).then((res) => {
          if (res?.data?.linusCode === 1) {
            localStorage.setItem(
              "accessToken",
              res?.data?.data?.tokens?.accessToken
            );
            localStorage.setItem("id", res?.data?.data?.user?.id);
            if (
              !res?.data?.data?.user?.username &&
              !res?.data?.data?.user?.phoneNumber
            ) {
              navigate("/my/info");

              return;
            }

            if (!res?.data?.data?.user?.username) {
              navigate("/my/info");

              return;
            }

            if (!res?.data?.data?.user?.phoneNumber) {
              navigate("/my/info");

              return;
            }
            navigate("/");
          } else if (res?.data?.linusCode === -2) {
            alert("회원정보가 존재하지 않습니다.");
          } else {
            alert(res?.data?.data?.msg);
          }
        });
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (type === "signIn") {
      accountAPI
        .loginWithEmail({
          email,
          password,
        })
        .then((res) => {
          if (res?.data?.linusCode === 1) {
            localStorage.setItem(
              "accessToken",
              res?.data?.data?.tokens?.accessToken
            );
            localStorage.setItem("id", res?.data?.data?.user?.id);
            navigate("/");
          } else if (res?.data?.linusCode === -2) {
            alert("회원정보가 존재하지 않습니다.");
          } else {
            alert(res?.data?.data?.msg);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.linusCode === -2) {
            alert("회원정보가 존재하지 않습니다.");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("id");
          }
        });
    } else if (type === "signUp") {
      if (!isAgree) {
        alert("개인정보 취급 방침에 동의하셔야 회원가입을 할 수 있습니다.");
        return;
      }
      accountAPI
        .registByEmail({
          email,
          username: name,
          password1: password,
          password2: passwordCheck,
          phoneNumber: phone,
          joinPath: route[0],
        })
        .then((res) => {
          if (res?.data?.linusCode === 1) {
            alert("회원가입이 완료되었습니다. 로그인 페이지로 이동합니다.");
            navigate("/auth/sign-in");
          } else {
            alert(res?.data?.data?.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <StyledSignContainer>
      {modalVisible && (
        <div
          className="modal"
          onClick={(e) => {
            e.preventDefault();
            if (
              e.target.className === "contents-container" ||
              e.target.className === "contents" ||
              e.target.className === "title" ||
              e.target.className === "title-text" ||
              e.target.className === "close-container"
            ) {
              return;
            }

            setModalVisible(false);
            document.body.style.overflow = "unset";
          }}
        >
          <div className="modal-contents-container">
            <div className="title">
              <p className="title-text">{t("privacy-policy")}</p>
              <div className="close-container">
                <img src="/img/close-black.svg" alt="close" />
              </div>
            </div>
            <div className="contents">
              <Terms />
            </div>
          </div>
        </div>
      )}
      <div className="upper-container">
        <StyledSignTab
          selected={type === "signUp"}
          className="sign-tab"
          onClick={() => {
            navigate("/auth/sign-up");
          }}
        >
          SIGN UP
        </StyledSignTab>
        <StyledSignTab
          selected={type === "signIn"}
          className="sign-tab"
          onClick={() => {
            navigate("/auth/sign-in");
          }}
        >
          SIGN IN
        </StyledSignTab>
      </div>
      <div className="contents">
        {type === "signIn" && (
          <>
            <h3>Sign In to your account.</h3>
            <p className="desc">
              Build skills for today, tomorrow, and beyond.
              <br />
              Education to future-proof your career.
            </p>
            <form onSubmit={onSubmit}>
              <input
                type={"email"}
                placeholder={t("email")}
                onChange={setEmail}
              />
              <input
                type={"password"}
                placeholder={t("password")}
                onChange={setPassword}
              />
              <button type={"submit"}>Sign in</button>
            </form>
            <Link className="reset" to="/auth/reset-password">
              {t("reset-password")}
            </Link>
            <div className="sns-title">
              <div className="decorator" />
              <div className="text">{t("login-sns")}</div>
              <div className="decorator" />
            </div>
            <div className="kakao">
              <img src="/img/icon-sign-kakao.svg" alt="kakao" />
              <div>
                <KaKaoLogin
                  token={KAKAO_CLIENT_ID}
                  onSuccess={onSuccessKakaoLogin}
                  onFail={console.error}
                  onLogout={console.info}
                >
                  {t("kakao-login")}
                </KaKaoLogin>
              </div>
            </div>
            <button
              className="google"
              onClick={(e) => {
                e.preventDefault();
                loginWithGoogle();
              }}
            >
              <img src="/img/icon-sign-google.svg" alt="google" />
              <div>{t("google-login")}</div>
            </button>
          </>
        )}
        {type === "signUp" && (
          <>
            <h3>Create your account.</h3>
            <p className="desc">
              Build skills for today, tomorrow, and beyond.
              <br />
              Education to future-proof your career.
            </p>
            <form onSubmit={onSubmit}>
              <input type={"text"} placeholder={t("name")} onChange={setName} />
              <input
                type={"email"}
                placeholder={t("email")}
                onChange={setEmail}
              />
              <input
                type={"password"}
                placeholder={t("password")}
                onChange={setPassword}
              />
              <div className="password-condition">
                <span>*</span>
                {t("password-condition")}
              </div>
              <input
                type={"password"}
                placeholder={t("password-confirm")}
                onChange={setPasswordCheck}
              />
              <input
                type={"phone"}
                placeholder={t("phone")}
                onChange={setPhone}
              />
              <div className="route-container">
                <div className="title">
                  {t("reg-path")} <span>*</span>
                </div>
                {routes.map((i) => {
                  return (
                    <div key={i[0]} className="radio-item">
                      <input
                        id={i[0]}
                        type={"radio"}
                        value={i[0]}
                        checked={route[0] === i[0]}
                        onChange={(e) => {
                          setRoute(
                            routes.filter((i) => i[0] === e.target.value)[0]
                          );
                        }}
                      />
                      <label htmlFor={i[0]}>{i[1]}</label>
                    </div>
                  );
                })}
              </div>
              <div className="form-agree">
                <img
                  src={
                    !isAgree
                      ? "/img/icon-checkbox.svg"
                      : "/img/icon-checked.svg"
                  }
                  alt="checkbox"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsAgree(!isAgree);
                  }}
                />
                <div className="agree">
                  {!isLangKo() && "I agree to the "}

                  <span
                    onClick={() => {
                      setModalVisible(true);
                    }}
                  >
                    {t("privacy-policy")}
                  </span>
                  {isLangKo() && "에 동의합니다."}
                </div>
              </div>
              <button type={"submit"}>Sign up</button>
            </form>

            <div className="sns-title">
              <div className="decorator" />
              <div className="text">{t("sign-sns")}</div>
              <div className="decorator" />
            </div>
            <div className="kakao">
              <img src="/img/icon-sign-kakao.svg" alt="kakao" />
              <div>
                <KaKaoLogin
                  token={KAKAO_CLIENT_ID}
                  onSuccess={onSuccessKakaoLogin}
                  onFail={console.error}
                  onLogout={console.info}
                >
                  {t("kakao-login")}
                </KaKaoLogin>
              </div>
            </div>
            <button
              className="google"
              onClick={(e) => {
                e.preventDefault();
                loginWithGoogle();
              }}
            >
              <img src="/img/icon-sign-google.svg" alt="google" />
              <div>{t("google-login")}</div>
            </button>
          </>
        )}
      </div>
    </StyledSignContainer>
  );
}
