import styled from "styled-components";

export const StyledMyOrderPageContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0px 20px 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .page-title-container {
    width: 100%;
    margin-bottom: 60px;

    h3 {
      margin: 0 0px 28px 0;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 34px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: -1.36px;
      text-align: left;
      color: #222;

      @media (max-width: 768px) {
        font-size: 28px;
        margin-top: 40px;
        margin-bottom: 14px;
      }
    }
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 200px 0 100px 0px;
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: -0.8px;
    color: #777;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

export const StyledOrderItemContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin-bottom: 60px;

  .date {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.72px;
    text-align: right;
    color: #555;
    margin-bottom: 28px;
  }

  .total-price {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 28px 0;
    padding: 0px;
    border-radius: 5px;
    width: 100%;
    height: 85px;
    background-color: #f4f4f4;

    & > div {
      margin-right: 28px;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: -0.8px;
      text-align: right;
      color: #555;

      & > span {
        font-family: Montserrat;
        font-weight: bold;
        color: #222;
        margin-left: 5px;
      }
    }
  }
`;

export const StyledOrderDetailContainer = styled.div`
  display: flex;
  cursor: pointer;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  margin: 0 0 28px 0;
  padding: 0px;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100%;

  .thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 135px;
      height: 135px;
      margin: 40px 28px 40px 40px;
      border-radius: 5px;

      @media (max-width: 768px) {
        width: 80px;
        height: 80px;
        margin: 40px 0px 20px 20px;
      }
    }

    @media (max-width: 768px) {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    margin: 40px 100px 40px 0;
    max-width: 446px;

    @media (max-width: 768px) {
      margin: 20px;
      margin-top: 0;
    }

    & > .title {
      margin: 0 0px 14px 0;
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: -0.96px;
      text-align: left;
      color: #142580;
    }

    & > .desc {
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: -0.64px;
      text-align: left;
      color: #777;
    }
  }

  .price-quantity {
    display: flex;
    flex: 1;

    @media (max-width: 768px) {
      margin: 0 20px 40px 20px;
      margin-top: 0;
    }
  }

  .quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.8px;
    text-align: left;
    color: #222;
  }

  .price {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    & > div {
      margin-right: 40px;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: -0.8px;
      text-align: right;
      color: #222;

      @media (max-width: 768px) {
        margin: 0;
      }
    }
  }
`;
