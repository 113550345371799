import Axios from "axios";
import { API_HOST } from "../constants/login";

export const axiosInstance = Axios.create({
  baseURL: API_HOST,
});

export const apiWrapper = async (fn) => {
  try {
    await fn();
    return true;
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
    } else {
      console.log(error);
    }
  }
  return false;
};

const parseResult = (response) => {
  const { linusCode, data } = response.data;
  if (linusCode < 0) {
    const { msg } = data;
    return { status: false, data: msg };
  }
  return { status: true, data: data };
};

export const getGoogleToken = async (accessToken) => {
  const response = await axiosInstance.post("/accounts/google/token/", {
    accessToken,
  });
  return parseResult(response);
};

export const getNaverToken = async (accessToken) => {
  const response = await axiosInstance.post("/accounts/naver/token/", {
    accessToken,
  });
  return parseResult(response);
};

export const getKakaoToken = async (accessToken) => {
  const response = await axiosInstance.post("/accounts/kakao/token/", {
    accessToken,
  });
  return parseResult(response);
};
