import styled from "styled-components";

export const StyledMyPageContainer = styled.div`
  background-color: ${(props) => {
    if (props.hasBG) {
      return "#f4f4f4";
    }

    return "white";
  }};
  margin-bottom: 0;
  margin-top: 66px;
`;

export const StyledMyMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100vw;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 80px;
  background-color: white;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const StyledMenuItem = styled.button`
  width: auto;

  height: 60px;
  padding: 20px 10px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: -0.64px;
  text-align: center;
  color: ${(props) => {
    if (props.selected) {
      return "#2015ff";
    }
    return "#333";
  }};
  border: none;
  border-bottom: ${(props) => {
    if (props.selected) {
      return "2px solid #2015ff";
    }
    return "none";
  }};
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-bottom: 18px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const StyledContentsContainer = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
`;
