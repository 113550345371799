import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/common/ScrollToTop";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "../src/constants/login";
import {
  RecoilRoot,
} from "recoil";
import 'bootstrap/dist/css/bootstrap.min.css'
import "swiper/css";
import "swiper/css/scrollbar";

import "./lang/i18n";

ReactDOM.render(
  <RecoilRoot>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <React.StrictMode>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </GoogleOAuthProvider>
  </RecoilRoot>,
  document.getElementById("root")
);
