import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledNewsListContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  padding: 10px;
  & > div {
    width: 100%;
  }
  & > .blocks {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    padding: 10px;

    @media (max-width: 1200px) {
      marging: 20px;
      gap: 30px;
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export const StyledNewsItem = styled.div`
  height: 460px;
  cursor: pointer;
  box-shadow: 0px 4px 6px -2px #0b0b0b0d, 0px 10px 15px -3px #0b0b0b1a;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow: hidden;

  &:hover > .thumbnail,
  &:hover > .news-info,
  &:hover > .row-mobile {
    opacity: 0.5;
    transition: 0.25s ease-in;
  }

  .divider {
    width: 3px;
    height: 3px;
    background-color: #ddd;
  }

  & > .thumbnail {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 2;
  }

  & > .news-info {
    max-height: 136px;
    padding: 20px;

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }

    .title {
      margin: 6px 0;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.64px;
      text-align: left;
      color: #222;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: 768px) {
        margin: 0 0 2px 0px;
        line-height: 1.63;
        -webkit-line-clamp: 1; /* number of lines to show */
      }
    }

    .date {
      display: flex;
      align-items: center;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: normal;
      text-align: left;
      color: #777;
    }

    .contents {
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: -0.56px;
      text-align: left;
      color: #aaa;
      height: 100px;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .success-case {
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: -0.56px;
      text-align: left;
      color: #aaa;
      display: -webkit-box;
      -webkit-line-clamp: 13; /* number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 311px;
    }
  }

  & > .news-info > .tirivials {
    display: flex;
    align-content: flex-end;
    align-items: center;

    .author {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px 0 0;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: normal;
      text-align: left;
      color: #777;
    }

    .recommend,
    .view {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: normal;
      text-align: left;
      color: #777;

      span {
        margin-left: 2px;
        font-weight: 500;
        color: #2015ff;
      }
    }
  }
`;
