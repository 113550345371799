import client from "./client";

export const getCategories = () => client.get(`/lecture/categories/`);

export const getCategory = ({ id }) => client.get(`/lecture/categories/${id}/`);

export const getLecture = ({ id }) => client.get(`/lecture/lectures/${id}/`);

//lecture의 name으로 조회해오는 api 추가
export const getLectureByName = ({name}) => client.get(`/lecture/lectures/?name=${name}`);

// 최신 과정 10개를 조회해오는 api 추가
export const getRecentLectures = () => client.get(`/lecture/lectures/?limit=10`);

// 카테고리가 programs id: 1에 해당하는 lecture 조회
export const getLecturesByCategory = ({ categoryId }) => client.get(`/lecture/lectures/?category=${categoryId}&limit=15`);

// estimatedPeriod가 1개월 또는 4주인 과목을 조회
export const getLecturesByEstimatedPeriod = ({period}) => client.get(`/lecture/lectures/?estimatedPeriod=${period}`);