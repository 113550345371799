import React from "react";
import styled, { keyframes } from "styled-components";
import { Button, Card, Col, Container, Row as BootstrapRow, Row } from "react-bootstrap";

//intro
export const sizes = {
  mobile: "768px",
};

export const device = {
  mobile: `(max-width: ${sizes.mobile})`,
};

export const Intro = styled.div`
  @keyframes to-left {
    0% {
      transform: translateX(-100vw);
    }
    100% {
      transform: translateX(-200vw);
    }
  }

  @keyframes to-right {
    0% {
      transform: translateX(-200vw);
    }
    100% {
      transform: translateX(-100vw);
    }
  }

  .slide {
    overflow: hidden;
    margin-top: 5rem !important;
    * {
      height: 120px;
      width: 300vw;
      background-size: contain;
      background-repeat: repeat-x;
      margin-top: 1rem;
    }

    @media (max-width: 576px) {
      * {
        height: 60px;
        width: 300vw;
      }
    }
  }

  .first-slide {
    background-image: url(/img/course-slide1.png);
    animation: to-left 120s infinite alternate;
    animation-timing-function: linear;
  }

  .second-slide {
    background-image: url(/img/course-slide2.png);
    animation: to-right 120s infinite alternate;
    animation-timing-function: linear;
  }

  .third-slide {
    background-image: url(/img/course-slide3.png);
    animation: to-left 120s infinite alternate;
    animation-timing-function: linear;
  }
`;

//For introTop
export const CoverMainStyled = styled.div`
  background-image: url(/img/main/waves-poster.webp);
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  height: 496px;
  background-position: center;
  text-align: left;
  margin-top: 64px;

  & > .text-white {
    padding-top: 16rem;
    padding-left: 8rem;
  }

  @media ${device.mobile} {
    height: 550px;
    & > .text-white {
      padding-top: 8rem; // adjust this size as needed
      padding-left: 2rem; // adjust this size as needed
    }

    .swiper-scrollbar {
      background-color: #ccc;
    }
  }

  & > .text-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  & > .row-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;

export const TextStyled = styled.div`
  white-space: normal;
  font-size: 4.5rem;
  text-align: center;
  padding-top: 5rem;
  margin: auto;
  line-height: 1em;
  letter-spacing: 3px;
  max-width: 1080px;
  color: white;

  @media ${device.mobile} {
    font-size: 3rem;
  }
`;

export const SchoolCard = styled(Card)`
  width: 146.29px;
  height: 168px;
  background-color: white;
  text-align: center;
  margin-bottom: 1rem;
  margin-left: 16px;
`;

export const SchoolImage = styled(Card.Img)`
  width: 64px;
  height: 64px;
  margin-top: 30px;
  margin-bottom: -10px;
`;

export const SchoolText = styled(Card.Text)`
  white-space: normal;
  margin-top: 10px;
  font-weight: 300;
  padding: 5px;
`;

export const StyledRow = styled(BootstrapRow)``;

export const StyledCol = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
`;

//intro Carousel
export const StarContainer = styled.div`
  display: inline-block;
  margin-right: 8px;
`;

export const FullStar = () => (
  <StarContainer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="#BDEA09"
      className="bi bi-star-fill"
      viewBox="0 0 16 16"
    >
      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
    </svg>
  </StarContainer>
);

export const HalfStar = () => (
  <StarContainer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="#BDEA09"
      className="bi bi-star-half"
      viewBox="0 0 16 16"
    >
      <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z" />
    </svg>
  </StarContainer>
);

export const EmptyStar = () => (
  <StarContainer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="#BDEA09"
      className="bi bi-star"
      viewBox="0 0 16 16"
    >
      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
    </svg>
  </StarContainer>
);

export const Stars = ({ rating }) => {
  const fullStarCount = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  const emptyStarCount = 5 - fullStarCount - (hasHalfStar ? 1 : 0);

  const fullStars = [...Array(fullStarCount)].map((_, index) => <FullStar key={"full" + index} />);
  const halfStar = hasHalfStar ? <HalfStar key="half" /> : null;
  const emptyStars = [...Array(emptyStarCount)].map((_, index) => (
    <EmptyStar key={"empty" + index} />
  ));

  return (
    <span>
      {fullStars}
      {halfStar}
      {emptyStars}
    </span>
  );
};

export const ContainerStyled = styled(Container)`
  max-width: 1120px;
  padding-top: 3rem;
  padding-bottom: 1rem;
  flex-wrap: nowrap;
`;

export const CardStyled = styled.div`
  width: 173.3px;
  height: 292px;
  position: relative;
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  overflow: hidden;
  border-radius: 4px 4px 4px 4px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 40%, #161a53 70%);
    z-index: 1;
  }
`;

export const StyledColCarousel = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 15px;
`;

export const CardBodyStyled = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  margin: 0;
  color: white;
  z-index: 2;
`;

export const CardTitleStyled = styled.div`
  margin-bottom: 5px;
  font-size: 1.1rem;
`;

export const CardFootStyled = styled.div`
  font-size: 0.7rem;
  font-weight: 200;
  margin-top: 0.3rem;
`;

export const StyledRowCarousel = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  @media (max-width: 768px) {
    margin-right: -15px;
  }

  @media (max-width: 480px) {
    margin-right: -10px;
  }

  @media (max-width: 768px) {
    .swiper-scrollbar {
      background-color: #ccc;
      height: 4px;
      opacity: 1;
    }
  }

  .swiper-scrollbar {
    display: none;
    @media (max-width: 768px) {
      display: inline;
    }
  }
`;

// 스타일 컴포넌트 정의
export const RecommendedSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  flex-wrap: nowrap;
`;

export const Heading = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

// 화살표 버튼의 스타일을 정의하는 스타일 컴포넌트
export const StyledButton = styled.button`
  width: 50px; // 버튼의 너비
  height: 50px; // 버튼의 높이
  border-radius: 50%; // 버튼을 동그랗게 만듦
  border: 2px solid #2015ff;
  background-color: #fff; // 배경색 설정
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; // 마우스 오버 시 커서를 포인터로 변경

  img {
    transition: filter 0.3s;
  }

  &:hover {
    background-color: rgb(23, 26, 83); // 호버 시 배경색 변경
    border-color: rgb(23, 26, 83);
    img {
      filter: brightness(0) invert(1);
    }
  }
`;

export const ScrollbarStyled = styled.div`
  flex-grow: 1;
  margin: 0 10px;
  background: #e0e0e0;
  height: 9px;
  border-radius: 4px;
  position: relative;
  width: 256px;
`;

export const ScrollbarDragStyled = styled.div`
  width: 100px;
  background: #2015ff;
  height: 100%;
  border-radius: 4px;
  transform: translateX(${(props) => props.scrollPosition}px);
  transition: transform 0.3s ease; // Optional: for smooth scrolling effect
`;

export const ContainerStyledDiff = styled(Container)`
  margin-top: 8rem;
  margin-bottom: 3rem;
`;

export const moveBackground = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 100%;
  }
`;

export const AnimatedBackgroundContainer = styled(Container)`
  background-image: url("/img/main/contact.webp");
  background-size: cover;
  background-repeat: no-repeat;
  animation: ${moveBackground} 10s linear infinite;
  height: 340px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
`;

export const Section = styled.section`
  background-color: #f0f5ff;
  padding: 69px;
  border: 1px solid #C7C7C7;
  margin-bottom: 30px;
  border-radius: 4px;
  word-break: keep-all;
  ul {
    padding: 10px 0 10px 32px;
  }
  @media(max-width: 991px) {
    padding: 20px;
  }
`;

export const CustomButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 1rem;
  padding: 10px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

//intro Popup
export const StyledPopUpContainer = styled.div`
  .main-html {
    position: absolute;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    max-height: 80vh;
    word-break: break-all;
    overflow: hidden;
    z-index: 3;

    .pop-up-contents {
      width: 100%;
      max-width: calc(100vw - 40px);
      height: calc(100% - 44px);
      overflow: hidden;
      border-radius: 15px;
      padding: 25px;

      @media (max-width: 768px) {
        height: calc(100%);
      }
    }

    .close {
      width: 40px;
      height: 40px;
      background-color: white;
      border-radius: 20px;
      position: absolute;
      top: 14px;
      right: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
    }

    .close-today {
      height: 44px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
      background-color: #142580;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: -0.64px;
      text-align: center;
      color: #fff;
      cursor: pointer;

      @media (max-width: 768px) {
        height: 30px;
        font-size: 10px;
      }

      & > img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
  }

  .leftTopHtml {
    position: fixed;
    top: 80px;
    left: 80px;

    @media (max-width: 768px) {
      top: 30px;
      left: 20px;
      width: calc(100vw - 40px);
    }
  }

  .leftBottomHtml {
    position: fixed;
    bottom: 80px;
    left: 80px;

    @media (max-width: 768px) {
      bottom: 30px;
      left: 20px;
      width: calc(100vw - 40px);
    }
  }

  .navTopHtml {
    position: fixed;
    top: 66px;
    min-height: 48px;
    width: 100%;
    max-width: 100vw;
    background: linear-gradient(0.25turn, #2015ff, #805ad5);
    color: #fff;
    text-align: center;
    border-radius: 0;
    display: flex;
    align-items: center;
    .pop-up-contents {
      padding: 0 30px !important;
    }

    .close {
      background-color: transparent;
      color: #fff;
      top: -7px;
      & > img {
        filter: invert(100%) sepia(10%) saturate(7445%) hue-rotate(221deg) brightness(111%)
          contrast(110%);
        width: 30px;
      }
    }
    @media(max-width: 450px) {
      font-size: 0.8rem;
      .close {
        top: -10px;
      }
    }
  }
  .welcomeBoxHtml {
    position: absolute;
    top: 100px;
    right: 300px;
    overflow: visible;
    min-height: auto;
    color: black;

    @media (max-width: 768px) {
      display: none;
    }
    .welcome-box-logo {
      background-color: blue;
      padding: 15px 25px;
      border-radius: 10px 10px 0 0;
    }
    .welcome-box-arrow {
      position: absolute;
      right: 20px;
      bottom: -8px;
      background-color: blue;
      border-radius: 20px;
      border: none;
      width: 24px;
      height: 24px;
      & > img {
        filter: invert(100%) sepia(0%) saturate(7445%) hue-rotate(221deg) brightness(111%)
          contrast(210%);
        width: 20px;
        margin-left: 2px;
        margin-bottom: 2px;
      }
    }
    &:hover {
      transition: 0.3s ease-in-out;
      transform: scale(1.05);
      .welcome-box-arrow {
        transition: 0.25s ease-in-out;
        background: linear-gradient(0.25turn, #2015ff, #805ad5);
      }
    }
  }
`;
