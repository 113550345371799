import { Route, Routes } from "react-router-dom";
import React from "react";

import AboutPage from "./pages/AboutPage";
import InformationPage from "./pages/InformationPage";
import MainContactPage from "./pages/MainContactPage";
import AuthPage from "./pages/AuthPage";
import MyPage from "./pages/MyPage";
import UdacityPage from "./pages/UdacityPage";
import WhyPage from "./pages/WhyPage";
import ProgramPage from "./pages/ProgramPage";
import KoreanPage from "./pages/KoreanPage";
import ShoppingPage from "./pages/ShoppingPage";
import OrderPage from "./pages/OrderPage";
// import PasswordReset from "./components/auth/PasswordReset";
import ResetPassword from "./components/my/ResetPassword";
import IndexPage from "./pages/IndexPage";
// details
import KoreanDataSciencePython from "./pages/details/KoreanDataSiencePython";
import BusinessPage from "./pages/BusinessPage";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/why" element={<WhyPage />} />
        <Route path="/udacity" element={<UdacityPage />} />
        <Route path="/programs/*" element={<ProgramPage />} />
        <Route path="/korean" element={<KoreanPage />} />
        <Route path="/information/*" element={<InformationPage />} />
        <Route path="/contact" element={<MainContactPage />} />
        <Route path="/business" element={<BusinessPage/>} />
        <Route path="/cart/*" element={<ShoppingPage />} />
        <Route path="/order/*" element={<OrderPage />} />
        <Route path="/auth/*" element={<AuthPage />} />
        <Route path="/my/*" element={<MyPage />} />
        <Route path="/reset-password/*" element={<ResetPassword />} />
        <Route path="/KoreanDataSciencePython" element={<KoreanDataSciencePython />} />
      </Routes>
    </>
  );
}

export default App;
