import React, { useState, useEffect } from "react";
import { StyledSignContainer, StyledSignTab } from "../auth/Sign.styles";
import { Link, useNavigate } from "react-router-dom";

import * as accountAPI from "../../apis/accounts";
import { useInput } from "../../utils/useInput";

import { useTranslation } from "react-i18next";

export default function Password() {
  const navigate = useNavigate();

  const [isSocialUser, setIsSocialUser] = useState(false);
  const [password, setPassword] = useInput("");
  const [newPassword1, setNewPassword1] = useInput("");
  const [newPassword2, setNewPassword2] = useInput("");

  const { t } = useTranslation();

  useEffect(() => {
    accountAPI.getUserInfo({ id: localStorage.getItem("id") }).then((res) => {
      if (res.data.linusCode === 1) {
        setIsSocialUser(res.data.data.isSocialUser);
      }
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    const id = localStorage.getItem("id");

    accountAPI
      .resetPasswordByUser({
        id,
        password,
        newPassword1,
        newPassword2,
      })
      .then((res) => {
        alert(res.data.data.msg);
      });
  };

  return (
    <StyledSignContainer style={{ marginTop: "80px" }}>
      <div
        className="contents"
        style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}
      >
        <form onSubmit={onSubmit}>
          <input
            type={"password"}
            placeholder={t("cur-pw")}
            onChange={setPassword}
            disabled={isSocialUser}
          />
          <input
            type={"password"}
            placeholder={t("new-pw")}
            onChange={setNewPassword1}
            disabled={isSocialUser}
          />
          <input
            type={"password"}
            placeholder={t("new-pw-confirm")}
            onChange={setNewPassword2}
            disabled={isSocialUser}
          />
          {isSocialUser && <p style={{ fontSize: "13px" }}>{t("sns-no-pw")}</p>}
          {!isSocialUser && (
            <button
              type={"submit"}
              style={{ padding: "22px 0px 22px 0px" }}
              disabled={isSocialUser}
            >
              {t("fin-edit-pw")}
            </button>
          )}
        </form>
      </div>
    </StyledSignContainer>
  );
}
