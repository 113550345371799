import React, { useState } from "react";
import { StyledSignContainer, StyledSignTab } from "../auth/Sign.styles";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useInput } from "../../utils/useInput";

import * as accountAPI from "../../apis/accounts";

import queryString from "query-string";

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [newPassword1, setNewPassword1] = useInput("");
  const [newPassword2, setNewPassword2] = useInput("");

  const onSubmit = (e) => {
    const qs = queryString.parse(window.location.search);

    e.preventDefault();

    accountAPI
      .confirmPasswordReset({
        newPassword1,
        newPassword2,
        uid: qs.uid,
        key: qs["amp;key"],
      })
      .then((res) => {
        alert(res.data.data.msg);
      });
  };

  return (
    <StyledSignContainer style={{ marginTop: "80px" }}>
      <div
        className="contents"
        style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}
      >
        <form onSubmit={onSubmit}>
          <input
            type={"password"}
            placeholder="새로운 비밀번호"
            onChange={setNewPassword1}
          />
          <input
            type={"password"}
            placeholder="새로운 비밀번호 확인"
            onChange={setNewPassword2}
          />
          <button type={"submit"} style={{ padding: "22px 0px 22px 0px" }}>
            비밀번호 재설정
          </button>
        </form>
      </div>
    </StyledSignContainer>
  );
}
