import React, { useEffect, useState } from "react";

import * as lectureAPI from "../../apis/lecture";
import { useNavigate, useParams } from "react-router-dom";
import {
  StyledLectureListContainer,
  StyledLectureItemContainer,
  BannerImage,
} from "./LectureList.styles";

import { useTranslation } from "react-i18next";
import isLangKo from "../../utils/isLangKo";
import NavBar from "../common/NavBar";

const LectureItem = ({
  descriptionDetail,
  descriptionSummary,
  difficulty,
  estimatedPeriod,
  estimatedPeriodCondition,
  id,
  name,
  purunetPriceKRW,
  purunetPriceUSD,
  relatedLectures,
  thumbnail,
  usaPriceKRW,
  usaPriceUSD,
  descriptionSummaryEnglish,
  estimatedPeriodEnglish,
  estimatedPeriodConditionEnglish,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // beginner, intermediate, expert
  return (
    <>
      <StyledLectureItemContainer
        onClick={() => {
          navigate(`/programs/lecture/${id}`);
        }}
        difficulty={difficulty}
      >
        <div className="left-top">
          <div className="name">
            {name}
            <div className="difficulty">{difficulty}</div>
          </div>
          <div className="desc">{isLangKo() ? descriptionSummary : descriptionSummaryEnglish}</div>
          {/*
          <div className="price-container">
            <div className="price us">
              {t("us-price")}{" "}
              <span>
                {isLangKo() ? "₩" : "$"} {isLangKo() ? usaPriceKRW : usaPriceUSD}
              </span>
            </div>
            <div className="price purumnet">
              {t("our-price")}{" "}
              <span>
                {isLangKo() ? "₩" : "$"} {isLangKo() ? purunetPriceKRW : purunetPriceUSD}
              </span>
            </div>
          </div>
          **/}
        </div>
        <div className="right-bottom">
          <div className="button-container">
            <button>{t("enroll-now")}</button>
          </div>
          <div className="period">
            {isLangKo() ? estimatedPeriod : estimatedPeriodEnglish}
            <span>{isLangKo() ? estimatedPeriodCondition : estimatedPeriodConditionEnglish}</span>
          </div>
        </div>
      </StyledLectureItemContainer>
    </>
  );
};

export default function LectureList() {
  const params = useParams();
  const [category, setCategory] = useState([]);

  const sortLecturesFunc = category?.lecture?.sort((a, b) => {
    let x = a.name.toLowerCase();
    let y = b.name.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    lectureAPI.getCategory({ id: params.id }).then((res) => {
      if (res.data.linusCode === 1) {
        setCategory(res.data.data);
      }
    });
  }, [params.id]);

  const { t } = useTranslation();

  return (
    <>
      <NavBar navColor={"black"} />
      <StyledLectureListContainer>
        <div className="banner">
          <div className="text-container">
            <div className="container">
              <p className="upper-desc">The SCHOOL of</p>
              <h3 className="title">{category.name}</h3>
              <div className="desc">
                {isLangKo() ? category.descriptionSummary : category.descriptionSummaryEnglish}
              </div>
            </div>
          </div>
          <BannerImage background={category.thumbnail} />
        </div>
        <div className="contents-container">
          {sortLecturesFunc?.map((i) => (
            <LectureItem key={i.id} {...i} />
          ))}
        </div>
      </StyledLectureListContainer>
    </>
  );
}
