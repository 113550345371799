import React from "react";
import { Route, Routes } from "react-router-dom";

import ProgramList from "../components/program/ProgramList";
import LectureList from "../components/program/LectureList";
import LectureDetail from "../components/program/LectureDetail";

export default function ProgramPage() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<ProgramList />} />
        <Route path="/:id/lectures" element={<LectureList />} />
        <Route path="/lecture/:id" element={<LectureDetail />} />
      </Routes>
    </div>
  );
}
