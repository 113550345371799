import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";

// Styled components
export const StyledSection = styled.section`
  .business-bg-img {
    position: relative;
    margin-top: 50px;
    background-color: #171a53;
    background-image: url(/img/business/business.webp);
    color: #ffffff;
    background-position: 65%;
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: 768px) {
      background-position: center bottom;
    }
  }
  .container {
    @media (min-width: 1400px) {
      max-width: 1140px;
    }
  }
`;

export const ContentContainer = styled.div`
  transform: translate(200px);
`;

export const Heading = styled.div`
  font-size: 2.5rem;
  line-height: 1;
  margin: 20px 0;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

export const Subheading = styled.div`
  font-size: 15px;
  color: #6491fc;
`;

export const Paragraph = styled.p`
  font-size: 15px;
  text-align: left;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 50px;

  button {
    width: fit-content;
    padding: 0.75rem 1.5rem 0.75rem 2rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(32, 21, 255, 0.05);
    font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
    line-height: 1.44;
    letter-spacing: -0.3px;
    text-align: left;
    cursor: pointer;
    background-color: #00c5a1;
    border: none;
    color: black;

    &:hover {
      background-color: #047a65;
      border-color: #047a65;
      color: #ffffff;
      transition-duration: 0.3s;
    }
    svg {
      height: 24px;
      width: 24px;
      margin-left: 10px;
      margin-top: 1px;
    }
  }

  .explore {
    width: 188px;
    height: 48px;
    border: 2px solid #02c5a1;
    background-color: transparent;
    color: #02c5a1;
  }
`;

export const PartnerSection = styled.section`
  background-color: #f0f5ff;
  padding: 50px 0;
  .container {
    @media (min-width: 1400px) {
      max-width: 1140px;
    }
  }
`;

export const PartnerHeading = styled.div`
  text-align: left;
  color: #333;
  font-size: 1.6rem;
  margin-right: 20px;

  @media (max-width: 767px) {
    margin-right: 0;
  }
`;

export const PartnerLogo = styled.img`
  max-width: 100%;
  display: block;
  width: ${(props) => `${props.width}px` || "auto"};
  height: ${(props) => `${props.height}px` || "auto"};
`;

export const PartnerDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const PartnerRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  gap: 56px;
`;

export const PartnerCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WFStyledSection = styled.section`
  position: relative;
  margin-top: 80px;
  color: #0b0b0b;
  letter-spacing: 0.1px;
  .container {
    @media (min-width: 1400px) {
      max-width: 1140px;
    }
  }
  .wfs-head-row {
    margin-top: 80px;
    margin-bottom: 48px;
  }
`;

export const WFStyledHeading = styled.div`
  font-size: 2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WFStyledHeading1 = styled.div`
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.2;
  font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
  margin-bottom: 20px;
  order: 1;

  @media (max-width: 1400px) {
    font-size: 2rem;
    margin-top: 20px;
  }
`;

export const WFStyledSubHeading1 = styled.span`
  font-weight: 600;
  font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
  color: #2015ff;
`;

export const WFStyledHeading2 = styled.div`
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #2015ff;

  @media (min-width: 768px) and (max-width: 994px) {
    font-size: 1.5rem;
  }

  @media (max-width: 767px) {
    font-size: 1rem;
  }
`;

export const WFStyledParagraph = styled.div`
  font-size: 1rem;
`;

export const WFStyledParagraph1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WFStyledImage = styled.img`
  content: url(/img/business/workforce.webp);
  height: 460px;
  width: 100%;
  margin-right: 10px;
  border-radius: 5px;
  object-fit: cover;

  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export const MensionSectionImage = styled.img`
  @media (max-width: 1400px) {
    display: none;
  }
`;

export const MensionSectionContent = styled.div`
  @media (max-width: 1400px) {
    .UdacityMensionSection_Quote1 {
      font-size: 7px;
    }
  }
`;

export const WFStyledArticle = styled(Row)`
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const WFStyledArticleDiv = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-item: center;
  padding: 10px;
  background-color: #f0f5ff;
  height: 200px;
  border-radius: 10% 5% 10% 5%;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 768px) {
    flex: 0 0 auto;
    width: 45%;
  }
`;

export const UDStyledSection = styled.section`
  padding: 60px 0;
  background-color: #f8f9fa;
  margin-top: 100px;
  .container {
    @media (min-width: 1400px) {
      max-width: 1140px;
    }
  }
  .article-first-block {
    margin-top: 48px;
    @media (max-width: 769px) {
      margin-top: 10px;
    }
  }
  .article-block {
    margin-top: 150px;
    @media (max-width: 769px) {
      margin-top: 30px;
    }
  }
`;

export const UDStyledHeading = styled.h2`
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.8rem;
  @media (max-width: 769px) {
    font-size: 2rem;
    margin-bottom: 0;
  }
`;

export const UDStyledArticle = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const UDStyledArticleTitle = styled.div`
  font-size: 1.9rem;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 400;
  @media (max-width: 769px) {
    font-size: 1.5rem;
    padding-top: 20px;
  }
`;

export const UDStyledList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const UDStyledListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  margin-bottom: 5px;
  .list-item-text {
    align-self: center;
  }

  svg {
    height: 32px;
    width: 32px;
    margin-right: 10px;
    fill: #2015ff;
  }
`;

export const UDStyledImageContainer = styled.div`
  margin-top: 20px;
  background-color: #ffffff;
  width: 100%;
  height: 195px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
`;

export const UDStyledImage = styled.img`
  content: ${(props) => `${props.src}`};
`;

export const UDStyledSubHeading1 = styled.span`
  font-weight: 400;
  font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
  color: #02a083;
  font-size: 1.5rem;
`;

export const UDStyledSubParagraph1 = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  padding-right: 5px;
`;

export const UDStyledLargeImage = styled.img`
  content: ${(props) => `${props.src}`};
  width: 547px;
  object-fit: cover;

  @media (min-width: 769px) and (max-width: 1400px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const UDStyledListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const BackgroundContainer = styled(Container)`
  background-image: url("/img/business/business_background_img.webp");
  background-size: 150% 260px;
  background-color: #171a53;
  height: 260px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .container {
    @media (min-width: 1400px) {
      max-width: 1140px;
    }
  }

  .wrapper {
    .text-col {
      flex: 0 0 auto;
      width: 65%;
      & > .row > .col:last-child {
        @media (min-width: 992px) {
          flex-grow: 1;
        }
      }
      @media (max-width: 768px) {
        flex: 0 0 auto;
        width: 100%;
      }
    }
    .btn {
      padding: 0.75rem 1.5rem 0.75rem 2rem;
      color: black;
      background-color: #02c5a1;
      font-size: 1rem;
      width: fit-content;
      white-space: nowrap;
      &:hover {
        background-color: #047a65;
        color: white;
      }
      svg {
        height: 24px;
        width: 24px;
        margin-left: 10px;
        margin-bottom: 3px;
      }
      @media (max-width: 768px) {
        padding: 0.5rem 1rem;
        margin-top: 30px;
        width: 100%;
      }
    }
  }
`;

export const PlanHeading1 = styled.div`
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  font-weight: 500;
  margin-right: 10px;

  @media (max-width: 1200px) {
    font-size: 1.2rem;
  }
`;

export const PlanHeading2 = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-weight: 300;
  margin-right: 10px;
`;

export const BUContainer = styled(Container)`
  background-image: linear-gradient(to bottom, #ffffff, #f0f5ff);
  .container {
    padding-top: 80px;
    padding-bottom: 80px;
    @media (min-width: 1400px) {
      max-width: 1140px;
    }
    @media (max-width: 768px) {
      padding-top: 50px;
    }
  }
  @media (max-width: 1281px) {
    .row > .col-md-8 {
      width: 100%;
    }
    .row > .col-md-4 {
      display: none;
    }
  }
`;

export const BUHeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const BUHeader = styled.div`
  font-size: 2.5rem;
  font-weight: 400;
  color: #0b0b0b;
  letter-spacing: 0.2px;
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
  @media (max-width: 769px) {
    font-size: 1.5rem;
  }
`;

export const BUHeader1 = styled(BUHeader)`
  font-weight: 600;
  color: #2015ff;
  line-height: 1;
  @media (max-width: 769px) {
    font-size: 1.6rem;
  }
`;

export const SchoolCard = styled.div``;

export const SchoolCardSub = styled.div`
  background-color: #171a53;
  width: 350px;
  height: 107px;
  padding: 20px;

  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export const SchoolCardStyleImage = styled.img`
  height: 192px;
  width: 350px;
  border-radius: 10px 20px 0 0;

  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export const SchoolCardTitle = styled.div`
  color: #ffffff;
  font-size: 1.5rem;

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 1rem;
  }

  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
`;

export const SchoolCardLink = styled.a`
  font-size: 1.2rem;
  font-weight: 500;
  color: #02c5a1;
`;

export const SchoolSectionHeader = styled.div`
  font-size: 3rem;
  font-weight: 500;
  margin-left: 10px;
  @media (max-width: 769px) {
    font-size: 2rem;
  }
`;

export const SchoolSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 769px) {
    justify-content: flex-start;
    p {
      padding: 10px;
    }
  }
`;

export const CatalogContainer = styled(Container)`
  margin-top: 50px;
  & {
    @media (min-width: 1400px) {
      max-width: 1140px;
    }
  }
`;

export const JobReadyTalentSectionContainer = styled(Container)`
  margin-top: 100px;
  margin-bottom: 50px;
  & {
    @media (min-width: 1400px) {
      max-width: 1140px;
    }
  }
  .job-ready-title {
    font-size: 1.5rem;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 1.75rem;
    }
  }
  .img-col {
    flex: 0 0 auto;
    width: 20%;
    padding: 0;
  }
  .text-col {
    flex: 0 0 auto;
    width: 80%;
    text-align: start;
    align-self: center;
  }
  img {
    margin-bottom: 0 !important;
    margin-right: 10px;
  }
  p {
    display: inline-block;
    margin-bottom: 0;
  }
  .row {
    text-align: center;
    @media (max-width: 768px) {
      text-align: start;
      text-align-last: start;
      .img-col {
        padding-left: 12px;
        width: 18%;
      }
      .text-col {
        align-self: center;
      }
      img {
        margin-bottom: 0 !important;
        margin-right: 10px;
      }
      p {
        display: inline-block;
        margin-bottom: 0;
      }
    }
  }
`;

export const UdacityMensionSectionContainer = styled(Container)`
  padding: 80px;
  @media (max-width: 768px) {
    padding: 40px 16px;
  }
  .container {
    @media (min-width: 1400px) {
      max-width: 1140px;
    }
  }
  .quote-main {
    font-size: 2rem;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      font-size: 1.75rem;
    }
  }
  .quote-sub {
    font-size: 1.25rem;
  }
  .name-wrapper {
    margin-left: 3rem;
    @media (max-width: 768px) {
      margin-left: 1rem;
    }
  }
`;
