import styled from "styled-components";

export const StyledPostContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;

  .divider {
    width: 1px;
    height: 10px;
    margin: 0px 10px;
    background-color: #ddd;
  }

  & > div {
    width: 100%;
    min-height: 50px;
  }

  & > .contents {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ddd;

    img {
      max-width: 90vw;
    }

    @media (max-width: 768px) {
      width: 100vw;
    }

    .title {
      padding: 18px 0 18px 20px;
      background-color: #f8f8f8;
      border-top: 1px solid #222;
      border-bottom: 1px solid #ddd;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: -0.64px;
      text-align: left;
      color: #222;
    }

    .info {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ddd;
      padding: 20px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }

      & > div {
        display: flex;
        align-items: center;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: -0.56px;
        text-align: left;
        color: #777;
      }

      & > div > div {
        display: flex;
        align-items: center;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: -0.56px;
        text-align: left;
        color: #777;
      }

      & > .author > span {
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: -0.56px;
        text-align: left;
        color: #555;
      }

      & > .author {
        flex: 1;
      }

      & > .right-info {
        flex: 1;
        display: flex;
        justify-content: end;

        & > .date {
          margin-right: 30px;
        }
      }
    }

    & > .text {
      margin: 40px 20px 80px;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: -0.64px;
      text-align: left;
      color: #555;

      iframe {
        max-width: calc(100vw - 40px);
      }

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    & > .contents-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 80px;

      .like:hover button {
        background-color: #2015ff;
        transition: 0.25s ease-in;
      }

      .like:hover {
        color: #2015ff;
        transition: 0.25s ease-in;
      }

      .recommend {
        background-color: ${(props) => {
          if (props.isLiked) {
            return "#2015ff";
          }
        }};
      }

      .not-recommend {
        background-color: ${(props) => {
          if (props.isDisliked) {
            return "#2015ff";
          }
        }};
      }

      .recommend-text {
        color: ${(props) => {
          if (props.isLiked) {
            return "#2015ff";
          }
        }};
      }

      .not-recommend-text {
        color: ${(props) => {
          if (props.isDisliked) {
            return "#2015ff";
          }
        }};
      }

      .action:hover button {
        background-color: #333;
        transition: 0.25s ease-in;
      }

      .action:hover {
        color: #333;
        transition: 0.25s ease-in;
      }

      .recommend > .icon {
        width: 20px;
        height: 20px;
        background: url(/img/good.svg);
      }

      .not-recommend > .icon {
        width: 20px;
        height: 20px;
        background: url(/img/bad.svg);
      }

      .share > .icon {
        width: 20px;
        height: 20px;
        background: url(/img/share.svg);
      }

      .print > .icon {
        width: 20px;
        height: 20px;
        background: url(/img/print.svg);
      }

      .like:hover .recommend > .icon {
        background: url(/img/good-white.svg);
        transition: 0.25s ease-in;
      }

      .like:hover .not-recommend > .icon {
        background: url(/img/bad-white.svg);
        transition: 0.25s ease-in;
      }

      .action:hover .share > .icon {
        background: url(/img/share-white.svg);
        transition: 0.25s ease-in;
      }

      .action:hover .print > .icon {
        background: url(/img/print-white.svg);
        transition: 0.25s ease-in;
      }

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 20px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #aaa;
      }

      & button {
        width: 50px;
        height: 50px;
        padding: 15px;
        border: solid 1px #eee;
        background-color: #fff;
        border-radius: 25px;
        margin-bottom: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  & > .buttons {
    display: flex;
    align-items: center;
    margin-top: 40px;

    @media (max-width: 768px) {
      width: calc(100vw - 40px);
      margin-top: 20px;
    }

    & > .prev,
    & > .list,
    &.next {
      display: flex;
      flex: 1;
    }

    .list-button {
      width: 100px;
      height: 54px;
      border-radius: 2px;
      background-color: #777;
      border: none;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: -0.72px;
      text-align: center;
      color: #fff;
      cursor: pointer;

      @media (max-width: 768px) {
        width: 78px;
        height: 40px;
        font-size: 14px;
        padding: 0;
      }
    }

    .prev-button,
    .next-button {
      width: 116px;
      height: 54px;
      border-radius: 2px;
      border: solid 1px #ddd;
      background-color: #fff;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: -0.72px;
      text-align: center;
      color: #777;
      cursor: pointer;

      @media (max-width: 768px) {
        width: 78px;
        height: 40px;
        font-size: 14px;
        padding: 0;
      }
    }
  }
`;
