import { atom, RecoilRoot, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "recoil-persist", // this key is using to store data in local storage
  storage: localStorage, // configurate which stroage will be used to store the data
});

export const orderItemState = atom({
  key: "cart",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const shoppingItemState = atom({
  key: "shopping",
  default: [],
  effects_UNSTABLE: [persistAtom],
});
