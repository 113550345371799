import React, { useState, useEffect } from "react";
import { StyledSignContainer, StyledSignTab } from "../auth/Sign.styles";
import { Link, useNavigate } from "react-router-dom";

import * as accountAPI from "../../apis/accounts";

import { useTranslation } from "react-i18next";

export default function Info() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [nameDisabled, setNameDisabled] = useState(false);

  useEffect(() => {
    accountAPI.getUserInfo({ id: localStorage.getItem("id") }).then((res) => {
      if (res.data.linusCode === 1) {
        setUser({
          ...res.data.data,
          joinPath:
            res.data.data.joinPath.length > 0 ? res.data.data.joinPath : "SN",
        });
        setNameDisabled(
          res.data.data.username && res.data.data.username?.length > 0
        );
      }
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    accountAPI.editUserInfo({ ...user }).then((res) => {
      if (res.data.linusCode === 1) {
        alert("회원정보가 성공적으로 변경되었습니다.");
      } else {
        alert(res.data.data.msg);
      }
    });
  };

  const onWithdraw = (e) => {
    e.preventDefault();

    if (window.confirm("정말로 탈퇴하시겠습니까?")) {
      accountAPI.deleteUser({ id: localStorage.getItem("id") }).then((res) => {
        if (res.data.linusCode === 1) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("id");
          alert("탈퇴처리가 완료되었습니다.");
          navigate("/");
        }
      });
    }
  };

  return (
    <StyledSignContainer style={{ marginTop: "80px" }}>
      <div
        className="contents"
        style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}
      >
        <form onSubmit={onSubmit}>
          <input
            type={"text"}
            placeholder={t("name")}
            value={user?.username}
            disabled={nameDisabled}
            onChange={(e) => {
              setUser({ ...user, username: e.target.value });
            }}
          />
          <input
            type={"email"}
            placeholder={t("email")}
            value={user?.email}
            disabled
          />
          <input
            type={"phone"}
            placeholder={t("phone")}
            value={user?.phoneNumber}
            onChange={(e) => {
              setUser({ ...user, phoneNumber: e.target.value });
            }}
          />
          <button type={"submit"}>{t("fin-edit")}</button>
        </form>
        <div className="withdraw" onClick={onWithdraw}>
          {t("withdraw")}
        </div>
      </div>
    </StyledSignContainer>
  );
}
