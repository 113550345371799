import axios from "axios";

const client = axios.create();

export const BASE_URL = process.env.REACT_APP_BASE_URL;

client.defaults.baseURL = BASE_URL;

client.interceptors.request.use(
  async (config) => {
    console.log("Request config: ", config);
    if (localStorage.getItem("accessToken")) {
      const accessToken = localStorage.getItem("accessToken") ?? null;
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
      };
    } else {
      config.headers = {
        Authorization: "",
        Accept: "application/json",
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default client;
