import styled from "styled-components";

export const StyledSignContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .password-condition {
    font-size: 14px;
    width: 432px;
    margin-bottom: 10px;

    span {
      color: red;
      margin-right: 3px;
    }

    @media (max-width: 768px) {
      width: calc(100vw - 120px);
      font-size: 11px;
    }
  }

  .modal {
    height: 100vh;
    overflow-y: hidden;
    z-index: 1000;
    position: fixed;
    top: 0;
    bottom: -10000;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;

    & > .modal-contents-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 80%;
      max-width: 588px;
      min-height: 560px;
      border-radius: 5px;
      background-color: #fff;
      padding: 0;

      @media (max-width: 768px) {
        max-width: calc(100vw - 40px);
        width: calc(100vw - 40px);
      }

      & > .title {
        display: flex;
        justify-content: start;
        align-items: center;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: -0.88px;
        text-align: left;
        color: #333;
        width: 100%;
        border-bottom: 1px solid #ddd;
        height: 72px;
        margin-bottom: 30px;

        p {
          margin-left: 30px;
        }

        .close-container {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        img {
          width: 30px;
          margin-right: 30px;
          cursor: pointer;
        }
      }

      & > .contents {
        margin: 0 30px;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: -0.64px;
        text-align: left;
        color: #555;
        max-height: 700px;
        overflow: scroll;
        padding: 0;
      }
    }

    .close {
      cursor: pointer;
    }
  }

  .upper-container {
    display: flex;
    width: 100%;
    margin-top: 80px;
    z-index: 2;
    justify-content: center;
  }

  & > .contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    margin-bottom: 140px;
    padding: 60px 39px;
    width: 510px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      padding: 40px 20px;
      width: calc(100vw - 80px);
      margin: 0 20px 80px;
    }

    h3 {
      margin: 0 17px 20px;
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -1.44px;
      text-align: center;
      color: #222;
      padding: 0;

      @media (max-width: 768px) {
        font-size: 22px;
      }
    }

    .withdraw {
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: -0.56px;
      text-align: center;
      color: #777;
      cursor: pointer;
    }

    .desc {
      margin: 0px 54px 40px;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: -0.64px;
      text-align: center;
      color: #777;

      @media (max-width: 768px) {
        font-size: 14px;
        margin: 0 0 20px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      input {
        width: 432px;
        height: 54px;
        margin: 0px 0 14px;
        border-radius: 5px;
        border: solid 1px #ddd;
        background-color: #fff;
        outline: 0;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: -0.64px;
        text-align: left;
        color: #222;
        padding-left: 20px;

        @media (max-width: 768px) {
          width: calc(100vw - 120px);
        }
      }

      input:disabled {
        border-radius: 5px;
        border: solid 1px #ddd;
        background-color: #f8f8f8;
      }

      input::placeholder {
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: -0.64px;
        text-align: left;
        color: #aaa;
      }

      button {
        width: 458px;
        padding: 21px 0;
        margin: 14px 0 28px 0;
        border-radius: 5px;
        background-color: #2015ff;
        border: none;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.8px;
        text-align: center;
        color: #fff;
        cursor: pointer;

        @media (max-width: 768px) {
          width: calc(100vw - 100px);
          font-size: 18px;
          padding: 21px 0;
        }
      }

      & > .form-agree {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        & > img {
          cursor: pointer;
        }

        & > .agree {
          margin: 0 0 0 10px;
          padding: 0;
          font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.86;
          letter-spacing: -0.56px;
          text-align: left;
          color: #777;
        }

        & > .agree > span {
          color: #222;
          cursor: pointer;
        }
      }
    }

    .route-container {
      display: flex;
      flex-direction: column;
      margin: 28px 0;
      width: 458px;

      @media (max-width: 768px) {
        width: calc(100vw - 100px);
      }

      .title {
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: -0.56px;
        text-align: left;
        color: #333;
        margin-bottom: 14px;

        & > span {
          color: #ff5e5e;
        }
      }

      .radio-item {
        & > input[type="radio"] {
          width: 14px;
          height: 14px;
          margin: 5px 6px 5px 0;
          object-fit: contain;
          border: solid 1px #ddd;
          background-color: #fff;
          cursor: pointer;
        }

        & > label {
          font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.63;
          letter-spacing: -0.64px;
          text-align: left;
          color: #555;
          cursor: pointer;

          @media (max-width: 768px) {
            font-size: 13px;
          }
        }
      }
    }

    .reset {
      margin: 0px 42px 40px 43px;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: -0.56px;
      text-align: left;
      color: #777;
      text-decoration: none;
    }

    .sns-title {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-bottom: 28px;

      .decorator {
        height: 1px;
        background-color: #ddd;
        flex: 1.5;
      }

      .text {
        flex: 2;
        font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.64px;
        text-align: center;
        color: #555;
        margin: 0 20px;

        @media (max-width: 768px) {
          font-size: 13px;
        }
      }
    }

    .kakao {
      display: flex;
      align-items: center;
      width: 458px;
      height: 60px;
      margin: 0px 0 14px;
      border-radius: 5px;
      background-color: rgb(255, 235, 0);
      border: none;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.64px;
      text-align: center;
      color: #222;
      cursor: pointer;

      button {
        cursor: pointer;
      }

      & > div {
        display: flex;
        justify-content: center;
        width: 60%;
        cursor: pointer;
      }

      img {
        margin: 16px 20px 16px 40px;
        @media (max-width: 768px) {
        }
      }

      @media (max-width: 768px) {
        width: calc(100vw - 100px);
      }
    }

    .google {
      display: flex;
      align-items: center;
      width: 458px;
      height: 60px;
      margin: 0px 0 14px;
      border-radius: 5px;
      border: solid 1px #ddd;
      background-color: #fff;
      font-family: "Noto Sans KR", "Apple SD Gothic Neo", Sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.64px;
      text-align: center;
      color: #222;
      cursor: pointer;

      & > div {
        display: flex;
        justify-content: center;
        width: 60%;
      }

      img {
        margin: 16px 20px 16px 40px;
        @media (max-width: 768px) {
        }
      }

      @media (max-width: 768px) {
        width: calc(100vw - 100px);
      }
    }
  }
`;

export const StyledSignTab = styled.div`
  text-align: center;
  flex: 1;
  background-color: ${(props) => {
    if (props.selected) {
      return "white";
    }
    return "#f4f4f4";
  }};
  cursor: pointer;
  padding: 20.5px 0;
  border-top-left-radius: ${(props) => {
    if (props.selected) {
      return "5px";
    }
    return "0";
  }};
  border-top-right-radius: ${(props) => {
    if (props.selected) {
      return "5px";
    }
    return "0";
  }};
  z-index: 2;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.64px;
  text-align: center;
  color: ${(props) => {
    if (!props.selected) {
      return "#333";
    }
    return "#2015ff";
  }};

  @media (max-width: 768px) {
    font-size: 16px;
    max-width: calc(50vw - 20px);
  }
`;
